import { Component, OnInit } from '@angular/core';
import { CurrentUserFlowService } from '@core/services/current-user/current-user.service';

@Component({
  selector: 'layout',
  template: `
    <xpw-layout>
      <router-outlet></router-outlet>
    </xpw-layout>
  `,
})
export class LayoutComponent {}
