import {
  IFilterOptions,
  IPagingRequest,
  IPagingResponse,
} from '@shared/utility/global-enums';

export interface IImportHistoryState {
  importHistory: IImportHistoryResult;
  pagingRequest: IPagingRequest;
  filter: {
    StatusId: ImportFileStatus[];
    FileImportTypeId: FileImportType[];
    UserUID: string[];
  };
  filterOptions: IFilterOptions[];
  search: string;
}

export interface IImportHistoryResult {
  items: IImportHistory[];
  pagingInfo: IPagingResponse;
  isLoading: boolean;
}

export interface IImportHistory {
  fileUID: string;
  dataCategory: number;
  userUID: string;
  userFullName: string;
  date: Date;
  source: string | null;
  status: number;
  successRecords: number | null;
  failedRecords: number | null;
  filePath: string | null;
  errorFilePath: string | null;
  importedFileExists: boolean;
  errorFileExists: boolean;
}

export enum ImportFileStatus {
  Uploaded = 1,
  Pending = 2,
  Processing = 3,
  PartiallyCompleted = 4,
  Completed = 5,
  Failed = 6,
  Canceled = 7,
}

export enum FileImportType {
  InsertMeters = 1,
  UpdateMeters = 2,
  Consumption = 3,
}
