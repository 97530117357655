import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';

import { EditFill,  } from '@ant-design/icons-angular/icons';

const icons: IconDefinition[] = [EditFill];

@NgModule({
  imports: [NzIconModule.forRoot(icons)],
  exports: [NzIconModule],
  providers: [],
})
export class IconsProviderModule {}
