import { Component, forwardRef, signal, input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputNumberComponent } from '../abstract-input-number.component';

@Component({
  selector: 'xpw-form-input-number',
  template: `
    <nz-form-item>
      <nz-form-label
        >{{ label() }} {{ isOptional ? ' (Optional)' : '' }}</nz-form-label
      >
      <nz-form-control
        [nzHasFeedback]="nzHasFeedback()"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-input-group
          [nzPrefixIcon]="icon() ? 'xpw-outline:' + icon() : null"
        >
          <input
            type="number"
            [step]="step()"
            nz-input
            [value]="value"
            [placeholder]="placeholder"
            [(ngModel)]="value"
            [disabled]="readonly"
            (keydown)="validateKeyDown($event)"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      .ant-form-vertical nz-form-label.ant-form-item-label {
        padding: 0 0 4px 0;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputNumberComponent),
      multi: true,
    },
  ],
})
export class XpwInputNumberComponent extends AbstractInputNumberComponent {
  // Use `input()` to create reactive inputs for these properties
  label = input<string>();
  icon = input<string>();
  nzHasFeedback = input<boolean>(true);
  step = input<number>(1);

  validateKeyDown(event: KeyboardEvent): void {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
      '-',
      '.',
      'Enter',
    ];
    // Allow navigation keys and control keys
    if (allowedKeys.includes(event.key) || event.ctrlKey || event.metaKey) {
      return;
    }
    // Prevent any key that is not a number
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  }
}
