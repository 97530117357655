import { Injectable } from '@angular/core';
import * as fileImportActions from './file-import.actions';
import { ImportService } from '@core/api/import.service';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  debounceTime,
  withLatestFrom,
} from 'rxjs/operators';
import { ReplaySubject, of } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  selectFileData,
  selectSelectedFileImportType,
} from './file-import.selector';
import { Store } from '@ngrx/store';
import { ImportType } from './file-import.interface';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { NotificationService } from '@core/services/notifications/notifications.service';

@Injectable()
export class FileImportEffects {
  constructor(
    private actions$: Actions,
    private fileImportService: ImportService,
    private store: Store,
    private notification: NotificationService,
  ) {}

  getFileImportTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileImportActions.getFileImportTypes),
      switchMap(() =>
        this.fileImportService.getFileImportTypes().pipe(
          map((fileImportTypes) =>
            fileImportActions.getFileImportTypesSuccess({ fileImportTypes }),
          ),
          // catchError((error) => of(fileImportActions.getFileImportTypesFailure({ error })))
        ),
      ),
    ),
  );

  uploadSetFileData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileImportActions.uploadSetFileData),
      withLatestFrom(
        this.store.select(selectFileData),
        this.store.select(selectSelectedFileImportType),
      ),
      switchMap(([action, fileData, selectedFileImportType]) =>
        this.fileImportService
          .uploadFile(action.file, fileData.sheetName, selectedFileImportType)
          .pipe(
            map((response) =>
              fileImportActions.uploadSetFileDataSuccess({
                response: response,
              }),
            ),
            catchError((error) =>
              of(fileImportActions.uploadSetFileDataFailure({ error: error })),
            ),
          ),
      ),
    ),
  );

  uploadFileError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fileImportActions.uploadSetFileDataFailure),
        map((action) => {
          console.log('uploadFileError', action.error);
        }),
      ),
    { dispatch: false },
  );

  downloadTemplateFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileImportActions.downloadTemplateFile),
      tap(() =>
        this.notification.loading(undefined, 'Downloading template file...'),
      ),
      switchMap((action) =>
        this.fileImportService
          .downloadTemplateFile(action.fileImportTypeID, {
            observe: 'response',
            responseType: 'blob' as 'json',
          })
          .pipe(
            tap((response) => response.headers.get('Content-Disposition')),
            map((response) =>
              fileImportActions.downloadTemplateFileSuccess({
                response: response,
              }),
            ),
            catchError((error) =>
              of(fileImportActions.downloadTemplateFileFailure({ error })),
            ),
          ),
      ),
    ),
  );

  downloadTemplateFileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fileImportActions.downloadTemplateFileSuccess),
        map((action) => {
          GlobalFunctions.downloadFileFromReponse(action.response);
          this.notification.clearAllNotifications();
          this.notification.success(undefined, 'Template file downloaded');
        }),
      ),
    { dispatch: false },
  );

  downloadTemplateFileFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fileImportActions.downloadTemplateFileFailure),
        map((action) => {
          console.log('downloadTemplateFileFailure', action.error);
          this.notification.clearAllNotifications();
          this.notification.error(undefined, 'Error exporting template file');
        }),
      ),
    { dispatch: false },
  );
}
