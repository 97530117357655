import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'xpwEnumValueToLabel',
})
export class XpwEnumValueToLabelPipe implements PipeTransform {
  transform(value: any, type: Object): any {
    return type[value];
  }
}
