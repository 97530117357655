import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ICountry,
  ICountryState,
  IDepratmentCountries,
} from '@shared/utility/global-enums';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LocationService {
  apiBaseUrl = '';
  endpoint = 'location';
  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getDepartmentCountries(
    departmentUID: string,
  ): Observable<IDepratmentCountries> {
    return this.http.get<any>(
      `${this.apiBaseUrl}departments/${departmentUID}/countries`,
    );
  }
  getAllCountries(): Observable<ICountry[]> {
    return this.http.get<any>(`${this.apiBaseUrl}${this.endpoint}/countries`);
  }

  getCountryCities(countryId: number): Observable<string[]> {
    // let countrID: number = parseInt(countryId);
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/countries/${countryId}/cities`,
    );
  }

  // getCountryStates(countryId: number): Observable<ICountryState[]> {
  //   return this.http.get<any>(
  //     `${this.apiBaseUrl}${this.endpoint}/countries/${countryId}/states`,
  //   );
  // }

  // getStateCities(stateId: number): Observable<string[]> {
  //   return this.http.get<any>(
  //     `${this.apiBaseUrl}${this.endpoint}/states/${stateId}/cities`,
  //   );
  // }

  getCitiesBySearch(
    search: string,
    countryId: number,
    stateId?: number,
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiBaseUrl}${this.endpoint}/countries/${countryId}/states/${stateId}/cities/search`,
      {
        params: {
          SearchCityText: search,
        },
      },
    );
  }
}
