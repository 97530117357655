import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'xpw-form-select',
  template: `
    <nz-form-item>
      <nz-form-label *ngIf="label"
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="hasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-select
          nzShowSearch
          [nzSuffixIcon]="'xpw-outline:' + icon"
          [disabled]="disabledInput"
          [nzMode]="mode"
          [nzMaxTagCount]="countToShow"
          [nzOptions]="options"
          [nzMaxTagCount]="10"
          [(ngModel)]="value"
          (nzOnSearch)="onSearch($event)"
          (blur)="onTouched()"
          (ngModelChange)="selectChange($event)"
        >
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwSelectFormComponent),
      multi: true,
    },
  ],
})
export class XpwSelectFormComponent extends AbstractInputComponent {
  @Input('options') options: any[]; // ['electricity', 'water', 'gas'];
  @Input('label') label: string;
  @Input('countToShow') countToShow: number;
  @Input('disabled') disabledInput: boolean = false;
  @Input('icon') icon: string = 'down';
  @Input('mode') mode: any = 'default'; // 'multiple' | 'tags' | 'default'
  @Input() hasFeedback: boolean = true;
  @Output() onSelectedChange = new EventEmitter();
  @Output() nameForSearch = new EventEmitter<string>();

  selectChange(event: any) {
    // console.log(event);
    this.onSelectedChange.emit(event);
  }

  onSearch(event: string) {
    this.nameForSearch.emit(event);
  }
}
