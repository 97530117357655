import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDepartment } from '@features/departments/departments-store/departments.interface';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  apiBaseUrl = '';
  endpoint = 'departments';

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getDepartments(): Observable<any> {
    return this.http.get<any>(this.apiBaseUrl + this.endpoint);
  }

  getListOfStorage(): Observable<any> {
    return this.http.get<any>(this.apiBaseUrl + this.endpoint + '/storages');
  }

  TransferOrganizations(
    srcDepartmentUID: string,
    destDepartmentUID: string,
    organizationIds: string[],
  ): Observable<any> {
    if (!srcDepartmentUID && !destDepartmentUID && !organizationIds) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.post<any>(
      this.apiBaseUrl + this.endpoint + '/TransferOrganizations',
      {
        srcDepartmentUID: srcDepartmentUID,
        destDepartmentUID: destDepartmentUID,
        organizationIds: organizationIds,
      },
    );
  }

  addDepartment(departmentUpdated: IDepartment): Observable<any> {
    if (!departmentUpdated) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.post<any>(this.apiBaseUrl + this.endpoint, {
      departmentName: departmentUpdated.departmentName,
      defaultCountryId: departmentUpdated.defaultCountryId,
      countryIds: departmentUpdated.countryIds,
      storageName: departmentUpdated.storageName,
    });
  }

  updateDepartment(departmentUpdated: IDepartment): Observable<any> {
    if (!departmentUpdated) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.put<any>(
      this.apiBaseUrl + this.endpoint + '/' + departmentUpdated.departmentUID,
      {
        departmentName: departmentUpdated.departmentName,
        defaultCountryId: departmentUpdated.defaultCountryId,
        countryIds: departmentUpdated.countryIds,
        storageName: departmentUpdated.storageName,
      },
      {
        params: { departmentUID: departmentUpdated.departmentUID },
      },
    );
  }

  deleteDepartment(deleteDepartmentUID: string): Observable<any> {
    if (!deleteDepartmentUID) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.delete<any>(
      this.apiBaseUrl + this.endpoint + '/' + deleteDepartmentUID,
    );
  }

  getOrganizationList(departmentUID: string): Observable<any> {
    if (!departmentUID) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.get<any>(
      this.apiBaseUrl +
        this.endpoint +
        '/' +
        departmentUID +
        '/organizations/names',
    );
  }
}
