import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';
import * as moment from 'moment';

@Component({
  selector: 'xpw-form-cron-jobs',
  template: `
    <nz-form-item>
      <nz-form-control
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <label nz-label>Select Frequency Type</label>
        <nz-select
          i18n-placeholder
          nzPlaceHolder="Select a type"
          style="width: 100%;"
          [(ngModel)]="scheduleType"
          (ngModelChange)="updateFrequencyType()"
        >
          <nz-option
            *ngFor="let type of cronTypes"
            [nzValue]="type"
            [nzLabel]="type"
          >
          </nz-option>
        </nz-select>

        <div *ngIf="scheduleType === 'Weekly'">
          <label nz-label>On Day</label>
          <nz-select
            i18n-placeholder
            nzPlaceHolder="Select a Day"
            style="width: 100%;"
            [(ngModel)]="dayOfWeek"
            (ngModelChange)="updateCronString()"
          >
            <nz-option [nzValue]="0" nzLabel="Sunday"> </nz-option>
            <nz-option [nzValue]="1" nzLabel="Monday"> </nz-option>
            <nz-option [nzValue]="2" nzLabel="Tuesday"> </nz-option>
            <nz-option [nzValue]="3" nzLabel="Wednesday"> </nz-option>
            <nz-option [nzValue]="4" nzLabel="Thursday"> </nz-option>
            <nz-option [nzValue]="5" nzLabel="Friday"> </nz-option>
            <nz-option [nzValue]="6" nzLabel="Saturday"> </nz-option>
          </nz-select>
        </div>

        <div *ngIf="scheduleType === 'Monthly'">
          <label nz-label>On Day</label>
          <nz-select
            i18n-placeholder
            nzPlaceHolder="Select a Day"
            style="width: 100%;"
            [(ngModel)]="dayOfMonth"
            (ngModelChange)="updateCronString()"
          >
            <nz-option
              *ngFor="let day of days"
              [nzValue]="day"
              [nzLabel]="day"
            ></nz-option>
          </nz-select>
        </div>

        <div *ngIf="scheduleType === 'Hourly'">
          <label nz-label>Every (Hours)</label>
          <nz-select
            i18n-placeholder
            nzPlaceHolder="Select an Hour"
            style="width: 100%;"
            [(ngModel)]="intervalHourly"
            (ngModelChange)="updateCronString()"
          >
            <nz-option
              *ngFor="let hour of hours"
              [nzValue]="hour"
              [nzLabel]="hour"
            ></nz-option>
          </nz-select>
        </div>

        <xpw-form-input-time
          [hasFeedback]="false"
          [utcCorrection]="false"
          *ngIf="scheduleType !== '' && scheduleType !== 'Hourly'"
          [label]="
            timeZoneForDisplayBottom
              ? 'At Time (' + timeZoneForDisplayBottom + ')'
              : 'At Time'
          "
          [(ngModel)]="time"
          (ngModelChange)="onTimeChange($event)"
        />
      </nz-form-control>
    </nz-form-item>
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CronJobsComponent),
      multi: true,
    },
  ],
})
export class CronJobsComponent
  extends AbstractInputComponent
  implements OnChanges
{
  @Input('timeZoneForDisplayBottom') timeZoneForDisplayBottom: string = '';
  @Input() minutForHourly: number = 0;
  @Output() scheduleTypeChanged = new EventEmitter<string>();

  scheduleType: string = '';
  time: Date | null = null;
  dayOfWeek: number = 0;
  dayOfMonth: number = 1;
  intervalHourly: number = 1;
  cronString: string = '';
  cronTypes = ['Monthly', 'Weekly', 'Daily', 'Hourly']; // Add more types as needed
  days: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  hours: number[] = [1, 2, 3, 4, 6, 8, 12];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['minutForHourly'] &&
      !changes['minutForHourly'].isFirstChange()
    ) {
      this.updateCronString();
    }
  }

  setDefault() {
    this.time = new Date();
    this.time.setHours(0, 0, 0, 0);
    this.dayOfWeek = 0;
    this.dayOfMonth = 1;
    this.intervalHourly = 1;
  }
  updateFrequencyType() {
    this.setDefault();
    this.updateCronString();
  }

  updateCronString(): void {
    this.scheduleTypeChanged.emit(this.scheduleType);
    const timeFormat = this.time ? moment(this.time).format('HH:mm') : '00:00';
    let timeParts = timeFormat.split(':');
    const hours = timeParts[0]?.replace(/0(\d+)/g, '$1');
    const minutes = timeParts[1]?.replace(/0(\d+)/g, '$1');

    switch (this.scheduleType) {
      case 'Monthly':
        this.dayOfMonth < 1
          ? (this.dayOfMonth = 1)
          : this.dayOfMonth > 31
            ? (this.dayOfMonth = 31)
            : this.dayOfMonth;
        this.cronString = `${minutes || '*'} ${hours || '*'} ${this.dayOfMonth || '1'} * *`;
        break;
      case 'Weekly':
        this.cronString = `${minutes || '*'} ${hours || '*'} * * ${this.dayOfWeek}`;
        break;
      case 'Daily':
        this.cronString = `${minutes || '*'} ${hours || '*'} * * *`;
        break;
      case 'Hourly':
        this.intervalHourly < 1
          ? (this.intervalHourly = 1)
          : this.intervalHourly > 23
            ? (this.intervalHourly = 23)
            : this.intervalHourly;
        this.cronString = `${this.minutForHourly || '0'} */${this.intervalHourly} * * *`;
        break;
      default:
        this.cronString = '';
    }
    console.log(this.cronString);
    this.onChange(this.cronString);
  }

  onTimeChange(newTime: Date): void {
    this.time = newTime;
    this.updateCronString();
  }

  // ControlValueAccessor interface methods
  override writeValue(value: any): void {
    if (value) {
      this.cronString = value;
      this.parseCRON(this.cronString);
    }
  }

  parseCRON(cronString: string) {
    const parts = cronString?.split(' ');
    if (parts.length !== 5) {
      console.error('Invalid CRON string format');
      return null;
    }

    const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;
    let result = {
      type: '',
      time: null,
      intervalHourly: 1,
      dayOfWeek: '',
      dayOfMonth: '',
    };

    if (cronString === '* * * * *') {
      result.type = '';
    } else if (
      /^\d+$/.test(minute) &&
      hour === '*' &&
      dayOfMonth === '*' &&
      month === '*' &&
      dayOfWeek === '*'
    ) {
      result.type = 'Minutely';
    } else if (
      /^\d+$/.test(minute) &&
      /^\*\/\d+$/.test(hour) &&
      dayOfMonth === '*' &&
      month === '*' &&
      dayOfWeek === '*'
    ) {
      result.type = 'Hourly';
      result.intervalHourly = parseInt(hour.split('/')[1], 10);
    } else if (
      /^\d+$/.test(minute) &&
      /^\d+$/.test(hour) &&
      dayOfMonth === '*' &&
      month === '*' &&
      dayOfWeek === '*'
    ) {
      result.type = 'Daily';
      result.time = new Date();
      result.time.setHours(parseInt(hour, 10));
      result.time.setMinutes(parseInt(minute, 10));
    } else if (
      /^\d+$/.test(minute) &&
      /^\d+$/.test(hour) &&
      dayOfMonth === '*' &&
      month === '*' &&
      /^\d+$/.test(dayOfWeek)
    ) {
      result.type = 'Weekly';
      result.dayOfWeek = dayOfWeek;
      result.time = new Date();
      result.time.setHours(parseInt(hour, 10));
      result.time.setMinutes(parseInt(minute, 10));
    } else if (
      /^\d+$/.test(minute) &&
      /^\d+$/.test(hour) &&
      /^\d+$/.test(dayOfMonth) &&
      month === '*' &&
      dayOfWeek === '*'
    ) {
      result.type = 'Monthly';
      result.dayOfMonth = dayOfMonth;
      result.time = new Date();
      result.time.setHours(parseInt(hour, 10));
      result.time.setMinutes(parseInt(minute, 10));
    } else {
      result.type = '';
    }

    this.scheduleType = result.type;
    this.time = result.time;
    this.dayOfWeek = this.toNumber(result.dayOfWeek);
    this.dayOfMonth = this.toNumber(result.dayOfMonth);
    this.intervalHourly = result.intervalHourly;
    this.scheduleTypeChanged.emit(this.scheduleType);
  }

  toNumber(value: string): number | null {
    return value ? parseInt(value, 10) : null;
  }
}
