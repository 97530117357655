<nz-layout class="app-layout">
  <nz-sider
    nzTheme="light"
    class="menu-sidebar"
    nzCollapsible
    nzWidth="280px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <xpw-main-nav
      [isCollapsed]="isCollapsed"
      (Collapsed)="isCollapsed = !isCollapsed"
    />
  </nz-sider>

  <div myCustomDirective [isOpen]="isOpen" class="device">
    <xpw-device-table />
  </div>

  <nz-layout>
    <nz-content>
      <nz-header>
        <div class="app-header">
          <xpw-organization-badge></xpw-organization-badge>
          <label style="display: none"
            >language test:
            <app-language-switcher></app-language-switcher>
          </label>
          <span
            style="
              display: flex;
              flex-direction: row;
              width: 14rem;
              justify-content: flex-end;
            "
          >
            <xpw-notifications></xpw-notifications>
            <xpw-user-profile></xpw-user-profile
          ></span>
        </div>
      </nz-header>

      <nz-layout class="layout-content">
        <div class="flex-deviceslide-breadcrumbs">
          <span class="icon-parent" (click)="isOpen = !isOpen">
            <!-- <xpw-icon-layout-device-open *ngIf="!isOpen" />
            <xpw-icon-layout-device-close *ngIf="isOpen" /> -->
          </span>

          <!-- <xpw-breadcrumbs></xpw-breadcrumbs> -->
        </div>

        <div class="router-content">
          <ng-content></ng-content>
        </div>
      </nz-layout>
    </nz-content>
  </nz-layout>
</nz-layout>
