import { createReducer, on } from '@ngrx/store';
import { initialState } from './audit.state';
import { clearAuditAction, updateAuditAction } from './audit.actions';

export const auditReducer = createReducer(
  initialState,
  on(updateAuditAction, (state, { message }) => ({
    ...state,
    message: message,
  })),
  on(clearAuditAction, (state) => ({
    ...state,
    message: initialState.message,
  })),
);
