import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { LanguageService } from './language.service';
import { NzI18nService, en_US, he_IL, de_DE, ru_RU } from 'ng-zorro-antd/i18n';
import { getLocaleDirection } from '@angular/common';

@Component({
  selector: 'app-language-switcher',
  template: `
    <select (change)="onLanguageChange($event)">
      <option
        *ngFor="let lang of availableLanguages"
        [value]="lang"
        [selected]="lang === current"
      >
        {{ lang }}
      </option>
    </select>
  `,
})
export class LanguageSwitcherComponent implements OnInit {
  availableLanguages: string[] = [
    'en-US',
    'de-DE',
    'he-HE',
    'ru-RU',
    'en-Util',
  ];
  current = this.locale;
  constructor(
    private languageService: LanguageService,
    private nzI18nService: NzI18nService,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit(): void {
    this.nzI18nService.setLocale(this.setLocale());
  }

  onLanguageChange(selectedLanguage: any): void {
    this.languageService.switchLanguage(selectedLanguage.target.value);
  }

  getLocaleDirection(): 'right' | 'left' {
    return getLocaleDirection(this.locale)?.toLowerCase() === 'rtl'
      ? 'right'
      : 'left';
  }
  setLocale() {
    switch (this.current) {
      case 'en-US':
        en_US.DatePicker.lang.dateFormat = 'MM/dd/yyyy';
        return en_US;
      case 'he-HE':
        he_IL.DatePicker.lang.dateFormat = 'dd/MM/yyyy';
        he_IL.DatePicker.lang.dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';
        return he_IL;
      case 'de-DE':
        return de_DE;
      case 'ru-RU':
        return ru_RU;
      default:
        return en_US;
    }
  }
}
