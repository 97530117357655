import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

const VAPID_PUBLIC_KEY =
  'BDFkpVSk0XtwFhtqeZwd42cvWaHLne1iatLpeItwdbgNRyGT1Yc35pzJ1M0z4fM-apg1EKWp7vVyRMHSWk6Zy0E';

@Injectable({
  providedIn: 'root',
})
export class SWNotificationService {
  constructor(private swPush: SwPush) {
    if (environment.production) this.requestPermission();
  }

  private subscribeToNotifications() {
    this.swPush
      .requestSubscription({
        serverPublicKey: VAPID_PUBLIC_KEY,
      })
      .then((subscription) => {
        console.log('User is subscribed:', subscription);
        // Send subscription to your server here.
      })
      .catch((err) =>
        console.error('Could not subscribe to notifications', err),
      );
  }

  showNotification(title: string, options?: NotificationOptions) {
    if (Notification.permission === 'granted') {
      new Notification(title, options);
    }
  }

  requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this.subscribeToNotifications();
          console.log(
            '%c Notification permission granted.',
            'background: #222; color: #bada55',
          );
        } else {
          console.log(
            '%c Notification permission denied.',
            'background: #222; color: #bada55',
          );
        }
      });
    }
  }
}
