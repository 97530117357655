import { createReducer, on } from '@ngrx/store';
import { fileImportInitialState } from './file-import.state';
import {
  getFileImportTypesSuccess,
  getTemplateFile,
  setFileData,
  setFileImportType,
  showPopup,
  uploadSetFileDataFailure,
  uploadSetFileDataSuccess,
} from './file-import.actions';

export const fileImportReducer = createReducer(
  fileImportInitialState,
  on(getFileImportTypesSuccess, (state, { fileImportTypes }) => ({
    ...state,
    fileimportTypes: fileImportTypes,
  })),
  on(setFileImportType, (state, { fileImportTypeID }) => ({
    ...state,
    selectedFileImportType: fileImportTypeID,
  })),
  on(getTemplateFile, (state) => ({
    ...state,
    currentFileData: null,
  })),
  on(setFileData, (state, { sheetName }) => ({
    ...state,
    currentFileData: { sheetName },
  })),
  on(uploadSetFileDataFailure, (state, { error }) => ({
    ...state,
    response: error,
  })),
  on(showPopup, (state, { isVisible }) => ({
    ...state,
    popupVisible: isVisible,
  })),
  on(uploadSetFileDataSuccess, (state, { response }) => ({
    ...state,
    response,
  })),
);
