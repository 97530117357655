import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'xpw-form-input-verification-code',
  template: `
    <nz-form-item id="xpw-form-input-verification-code_lable">
      <nz-form-control
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-form-label>{{ label }}</nz-form-label>
        <div class="xpw-verification-inputs">
          <input
            type="text"
            nz-input
            [(ngModel)]="digit1"
            (ngModelChange)="valueChanged()"
            maxlength="1"
            (keyup)="moveFocus($event, nextInput1)"
            (paste)="handlePaste($event)"
            #currentInput0
          />
          <input
            type="text"
            nz-input
            [(ngModel)]="digit2"
            (ngModelChange)="valueChanged()"
            maxlength="1"
            (keyup)="moveFocus($event, nextInput2)"
            (paste)="handlePaste($event)"
            #nextInput1
          />
          <input
            type="text"
            nz-input
            [(ngModel)]="digit3"
            (ngModelChange)="valueChanged()"
            maxlength="1"
            (keyup)="moveFocus($event, nextInput3)"
            (paste)="handlePaste($event)"
            #nextInput2
          />
          <input
            type="text"
            nz-input
            [(ngModel)]="digit4"
            (ngModelChange)="valueChanged()"
            maxlength="1"
            (keyup)="moveFocus($event, nextInput4)"
            (paste)="handlePaste($event)"
            #nextInput3
          />
          <input
            type="text"
            nz-input
            [(ngModel)]="digit5"
            (ngModelChange)="valueChanged()"
            maxlength="1"
            (keyup)="moveFocus($event, nextInput5)"
            (paste)="handlePaste($event)"
            #nextInput4
          />
          <input
            type="text"
            nz-input
            [(ngModel)]="digit6"
            (ngModelChange)="valueChanged()"
            maxlength="1"
            (keyup)="moveFocus($event, currentInput0)"
            (paste)="handlePaste($event)"
            #nextInput5
          />
        </div>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      .xpw-form-input-verification-code_lable {
      }
      .xpw-verification-inputs input {
        width: 40px;
        margin: 0 5px;
        text-align: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwVerificationCodeComponent),
      multi: true,
    },
  ],
})
export class XpwVerificationCodeComponent extends AbstractInputComponent {
  @Input('label') label: string;
  @Input('icon') icon: string;

  public digit1: string = '';
  public digit2: string = '';
  public digit3: string = '';
  public digit4: string = '';
  public digit5: string = '';
  public digit6: string = '';

  override writeValue(value: any): void {
    if (value) {
      this.digit1 = value[0] || '';
      this.digit2 = value[1] || '';
      this.digit3 = value[2] || '';
      this.digit4 = value[3] || '';
      this.digit5 = value[4] || '';
      this.digit6 = value[5] || '';
    } else {
      this.digit1 = '';
      this.digit2 = '';
      this.digit3 = '';
      this.digit4 = '';
      this.digit5 = '';
      this.digit6 = '';
    }
  }

  valueChanged() {
    this.onChange(
      `${this.digit1}${this.digit2}${this.digit3}${this.digit4}${this.digit5}${this.digit6}`,
    );
  }
  moveFocus(
    event: any,
    nextInput?: HTMLInputElement,
    prevInput?: HTMLInputElement,
  ): void {
    const target = event.target;
    if (event.key === 'Backspace' && prevInput && !target.value) {
      prevInput.focus();
    } else if (nextInput && target.value.length === 1) {
      nextInput.focus();
    }
  }
  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pasteData = event.clipboardData?.getData('text') || '';
    if (pasteData.length === 6 && /^\d{6}$/.test(pasteData)) {
      this.digit1 = pasteData[0];
      this.digit2 = pasteData[1];
      this.digit3 = pasteData[2];
      this.digit4 = pasteData[3];
      this.digit5 = pasteData[4];
      this.digit6 = pasteData[5];
      this.valueChanged();
    }
  }
}
