// File Import Interface

export interface IFileImportState {
  fileimportTypes: IFileImportType[];
  selectedFileImportType: ImportType;

  currentFileData?: FileData;
  popupVisible: boolean;

  response?: FileImportResponse;
}
interface FileImportResponse {
  fileImportUID: string;
  message: string;
}
interface FileData {
  sheetName: string;
}
export interface IFileImportType {
  fileImportTypeID: number;
  fileImportTypeEnum: ImportType;
  description: string;
  hasDataTemplate: boolean;
  supportedFileTypes: FileExtension[];
}
enum FileExtension {
  'Xlsx' = 'Xlsx',
  'Csv' = 'Csv',
  'Txt' = 'Txt',
  'Pdf' = 'Pdf',
}
export enum ImportType {
  InsertMeters = 1,
  UpdateMeters = 2,
  Consumption = 3,
}
