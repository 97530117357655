import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICurrentUser } from 'src/app/store/current-user/current-user.interface';
import { selectCurrentUserProperties } from 'src/app/store/current-user/current-user.selector';

@Component({
  selector: 'xpw-organization-badge',
  template: ` <div class="organization-badge">
    <nz-avatar
      style="color:rgba(0, 0, 0, 0.45); background-color:rgba(0, 0, 0, 0.06);"
      nzIcon="xpw-outline:organizations"
      [nzSrc]="sourceIconImage"
    >
      wefwefw</nz-avatar
    >
    <H3>{{ organizationName }} </H3>
  </div>`,
  styleUrls: ['./organization-badge.component.less'],
})
export class OrganizationBadgeComponent implements OnInit {
  constructor(private store: Store) {}

  organizationName: string = '';
  organizationLetter: string = '';
  sourceIconImage: string | null = null;

  currUser$: Observable<ICurrentUser> = this.store.select(
    selectCurrentUserProperties,
  );
  ngOnInit() {
    this.currUser$.subscribe((user) => {
      // get logoImage from user
      this.sourceIconImage = user.currentOrganization?.icon;

      if (user) {
        // get organization name from organizations and currentOrganizationUID
        const currentOrganization = user.organizations.find(
          (org) => org.uid === user.currentOrganization.uid,
        );
        if (currentOrganization) {
          this.organizationName = currentOrganization.name;
          // take first letter of organization name
          this.organizationLetter = this.organizationName.charAt(0);
        } else {
        }

        // this.organizationName = user.currentOrganizationName;
      }
    });
  }
}
