import {
  IFilterOptions,
  IPagingRequest,
  IPagingResponse,
  OrganizationStatus,
} from '@shared/utility/global-enums';

export enum OrganisationRequestType {
  ElectricityIntervalData = 1,
  ElectricityRegistersData = 2,
  ElectricityMeasurementsData = 3,
  ElectricityEventsData = 4,
  WaterIntervalData = 5,
}

export interface IOrganizationResult {
  items: IOrganization[];
  pagingInfo: IPagingResponse;
  isLoading: boolean;
}
export interface IOrganizationState {
  organizations: IOrganizationResult;
  pagingRequest: IPagingRequest;
  departmentUID: string;
  filter: {
    StatusId: OrganizationStatus[];
    CountryId: number[];
    WeatherDataRequired: string[];
  };
  filterOptions: IFilterOptions[];
  search: string;
  selectedOrganization: IOrganization | null;
  selectedOrganizationUID: string | null;
  selectedRootOrganization: IOrganization | null;
  organizationTypes: any[];
  newOrganization: boolean;
  organizationsRequestList: IOrganizationRequestList;
  organizationApiCredentials: IOrganizationApiCredentials;
  organizationChannelConfigurationList: IOrganizationChannelConfigurations;
  selectedChannelConfigurations: IChannelConfigurations;
  dataSourcesSettingsList: IDataSourceSettingsTable;
  selectedDataSourceSettings: {
    utilityServiceID: UtilityService | null;
    settings: IDataSourceSettings | null;
    isDataSourceSettingsNew: boolean | null;
    usedDataSourceTypes: DataSourceType[] | null;
  };
  drawersState: {
    organizationEditDrawer: boolean;
    apiCredentialsSettingsDrawer: boolean;
    dataSourceSettingsDrawer: boolean;
    requestEditDrawer: boolean;
    uploadOrganizationIconPopupVisible: boolean;
    channelConfigurationDrawer: boolean;
    deliveryMethodEditDrawer: boolean;
  };
  ValidationErrors: any;
  lastProcessing: Date | null;
  deliveryMethods: IDeliveryMethod[];
  selectedDeliveryMethod: IDeliveryMethod | null;
  deliveryMethodsFileTransferTypes: IDeliveryMethodFileTransferType[];
}

export interface IOrganization {
  organizationUID: string;
  organizationName: string;
  countryID: number;
  organizationTypeID: number;
  stateID: number | null;
  city: string | null;
  zipCode: string | null;
  streetAddress: string | null;
  latitude: number | null;
  longitude: number | null;
  organizationStatusId: number;
  organizationMedia: {
    icon: string;
    logo: string;
  };
  daysTillSuspension: number;
  contactFullName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  createdAt: string;
  weatherDataRequired: boolean;
  isProcessDailyMaxDemands: boolean | null;
  maxDaysUpdateBack: number | null;
  scheduleTimeZoneID: string | null;
  isValidationDisabled: boolean | null;
  numberOfMeters: number | null;
  demandInterval: number;
  // organizationType: {
  //   organizationTypeId: number;
  //   name: string;
  //   parentId: number;
  //   parentName: string;
  //   children: any[];
  // };
  // organizationStatus: {
  //   organizationStatusId: number;
  //   organizationStatusEnum: string;
  // };
}

export interface IApiCredentialsUpdate {
  maxCallsPerHour: number;
  maxCallsPerDay: number;
  isActive: boolean;
}

export enum UsageTypeID {
  ActiveEnergyDelivered = 1,
  ActiveEnergyReceived = 2,
  ActiveEnergyNet = 3,
  ActiveEnergyUnidirectional = 4,
  ReactiveEnergyDelivered = 5,
  ReactiveEnergyReceived = 6,
  ReactiveEnergyNet = 7,
  ApparentEnergy = 8,
  Water = 9,
  Gas = 10,
  KwDemandDelivered = 12,
  KwDemandReceived = 13,
  KWDemandNet = 14,
  KwDemandUnidirectional = 15,
  KVArDemandDelivered = 16,
  KVArDemandReceived = 17,
  KVArDemandNet = 18,
  KVaDemand = 19,
}
export const UsageTypeIDTitle = {
  1: $localize`Active Energy Delivered`,
  2: $localize`Active Energy Received`,
  3: $localize`Active Energy Net`,
  4: $localize`Active Energy Unidirectional`,
  5: $localize`Reactive Energy Delivered`,
  6: $localize`Reactive Energy Received`,
  7: $localize`Reactive Energy Net`,
  8: $localize`Apparent Energy`,
  9: $localize`Water`,
  10: $localize`Gas`,
  12: $localize`Kw Demand Delivered`,
  13: $localize`Kw Demand Received`,
  14: $localize`KW Demand Net`,
  15: $localize`Kw Demand Unidirectional`,
  16: $localize`KVAr Demand Delivered`,
  17: $localize`KVAr Demand Received`,
  18: $localize`KVAr Demand Net`,
  19: $localize`KVa Demand`,
};

export interface IChannel {
  usageTypeID: UsageTypeID;
  channel: string;
}

export interface IChannelConfigurations {
  channelConfigurationUID: string | null;
  channelConfigurationName: string;
  mappings: IChannel[];
}

export interface IOrganizationChannelConfigurations {
  organizationUID: string;
  channelConfigurationsList: IChannelConfigurations[];
}

export interface IApiCredentials {
  apiCredentialsUID: string;
  clientID: string;
  secretKey: string;
  secretExpirationDate: Date | null;
  maxCallsPerHour: number;
  maxCallsPerDay: number;
  isActive: boolean;
}

export interface IOrganizationApiCredentials {
  organizationUID: string;
  apiCredentials: IApiCredentials | null;
}

export interface IOrganizationRequest {
  requestTypeID: number;
  isEnabled: boolean;
  frequency: string | null;
  startFrom: Date | null;
}

export interface IOrganizationRequestList {
  organizationUId: string;
  scheduleTimeZoneID: string;
  organizationRequests: IOrganizationRequest[];
}

export enum ParameterType {
  string = 'string',
  url = 'url',
  password = 'password',
  listOfStrings = 'listOfStrings',
}

export interface ISettingsParameter {
  name: string;
  type: ParameterType;
  isEditable: boolean;
}

export interface ISettingsParameterValue extends ISettingsParameter {
  value: string[] | string;
}

export enum DataSourceType {
  GefenAPI = 1,
  OpenWayAPI = 2,
  IID_FileImport = 4,
  XPWAPI = 5,
}

export const ParameterTypeTitle = {
  applicationGroups: $localize`Application Groups`,
  customers: $localize`Customers`,
  token: $localize`Token`,
  uniqueIdentifier: $localize`Unique Identifier`,
  url: $localize`URL`,
};

export interface IDataSourceTypes {
  sourceTypeID: DataSourceType;
  parameters: ISettingsParameterValue[];
}

export interface IDataSourceSettings {
  dataSourceSettingUID: string;
  sourceTypeID: DataSourceType;
  enabled: boolean;
  parameters: ISettingsParameterValue[];
}

export interface IUtilityServiceSettings {
  utilityServiceID: UtilityService;
  dataSources: IDataSourceSettings[];
}

export interface IDataSourceSettingsTable {
  organizationUId: string;
  dataSourceTypes: IDataSourceTypes[];
  dataSourceSettings: IUtilityServiceSettings[];
}

export interface IDeliveryMethod {
  deliveryMethodUID: string;
  fileTransferTypeID: number;
  deliveryMethodName: string;
  parameters: [
    {
      name: string;
      value: string;
      type: string;
    },
  ];
}
export interface IDeliveryMethodFileTransferType {
  fileTransferTypeID: number;
  parameters: IDeliveryMethodParameters[];
}

export interface IDeliveryMethodParameters {
  name: string;
  value: string;
  type: string;
}

export enum UtilityService {
  Electricity = 1,
  Water = 2,
  Gas = 3,
  /*  Fuel = 4,
  CoolingData = 5,
  CompressedAir = 6,
  ThermalOil = 7,
  Steam = 8,
  Wastewater = 9,
  HVAC = 10, */ // Commited by Leah req task %4368
}
