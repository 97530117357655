import { createAction, props } from '@ngrx/store';
import {
  ICountry,
  ICountryState,
  IDepratmentCountries,
} from '@shared/utility/global-enums';

export enum LocationActionTypes {
  ShowCountries = '[Location] Show Countries',
  ShowStates = '[Location] Show States',

  GetAllCountries = '[Location] Get All Countries',
  GetDepartmentCountries = '[Location] Get Department Countries',

  GetCountriesSuccess = '[Location] Get Department Countries Success',
  GetAllCountriesSuccess = '[Location] Get All Countries Success',

  GetCountryStates = '[Location] Get Country States',
  GetCountryStatesSuccess = '[Location] Get Country States Success',

  GetStateCities = '[Location] Get State Cities',
  GetCountryCities = '[Location] Get Country Cities',
  GetCitiesBySearch = '[Location] Get Cities By Search',
  GetCitiesSuccess = '[Location] Get  Cities Success',

  SetCountry = '[Location] Select Country',
  SetState = '[Location] Select State',
  SetCity = '[Location] Select City',

  LocationError = '[Location] Error',
  ResetLocation = '[Location] Reset Location',

  ResetCities = '[Location] Reset Cities',
}

export const showCountries = createAction(
  LocationActionTypes.ShowCountries,
  props<{ show: boolean }>(),
);
export const showStates = createAction(
  LocationActionTypes.ShowStates,
  props<{ show: boolean }>(),
);

export const getAllCountries = createAction(
  LocationActionTypes.GetAllCountries,
);
export const getDepartmentCountries = createAction(
  LocationActionTypes.GetDepartmentCountries,
);
export const getCountriesSuccess = createAction(
  LocationActionTypes.GetCountriesSuccess,
  props<{ departmentCountries: IDepratmentCountries }>(),
);
export const getAllCountriesSuccess = createAction(
  LocationActionTypes.GetAllCountriesSuccess,
  props<{ countries: ICountry[] }>(),
);

export const getCountryStates = createAction(
  LocationActionTypes.GetCountryStates,
  props<{ countryID: number }>(),
);
export const getCountryStatesSuccess = createAction(
  LocationActionTypes.GetCountryStatesSuccess,
  props<{ states: ICountryState[] }>(),
);

export const getStateCities = createAction(
  LocationActionTypes.GetStateCities,
  props<{ stateID: number }>(),
);
export const getCitiesSuccess = createAction(
  LocationActionTypes.GetCitiesSuccess,
  props<{ cities: string[] }>(),
);

export const setCountry = createAction(
  LocationActionTypes.SetCountry,
  props<{ countryId: number }>(),
);
export const setState = createAction(
  LocationActionTypes.SetState,
  props<{ stateId: number }>(),
);
export const setCity = createAction(
  LocationActionTypes.SetCity,
  props<{ city: string }>(),
);

export const locationError = createAction(
  LocationActionTypes.LocationError,
  props<{ error: any }>(),
);
export const getCountryCities = createAction(
  LocationActionTypes.GetCountryCities,
  props<{ countryId: number }>(),
);

export const getCitiesBySearch = createAction(
  LocationActionTypes.GetCitiesBySearch,
  props<{ search: string }>(),
);
export const resetLocation = createAction(LocationActionTypes.ResetLocation);

export const resetCities = createAction(LocationActionTypes.ResetCities);
