import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  IChannelConfigurations,
  UsageTypeIDTitle,
} from '@features/organizations/store/organizations.interface';

@Component({
  selector: 'app-channel-configurations-table',
  template: `
    <div *ngIf="displayOnTable; else textDisplay">
      <nz-table
        #basicTable
        nzBordered
        [nzData]="dataSource"
        [nzShowPagination]="false"
      >
        <thead>
          <tr>
            <th>Description</th>
            <th>Channel</th>
            <th>Usage Types</th>
            <th *ngIf="canEdit">Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let config of dataSource">
            <ng-container
              *ngFor="let mapping of config.mappings; let i = index"
            >
              <tr>
                <td *ngIf="i === 0" [attr.rowspan]="config.mappings.length">
                  <strong>{{ config.channelConfigurationName }}</strong>
                </td>
                <td>{{ mapping.channel }}</td>
                <td>{{ usageTypiTitles[mapping.usageTypeID] }}</td>

                <td
                  *ngIf="canEdit && i === 0"
                  [attr.rowspan]="config.mappings.length"
                >
                  <xpw-button type="link" (click)="onEdit(config)">
                    <xpw-icon icon="edit"></xpw-icon>
                  </xpw-button>
                  <xpw-button
                    type="link"
                    nz-popconfirm
                    nzPopconfirmTitle="Are you sure?"
                    (nzOnConfirm)="onDelete(config.channelConfigurationUID)"
                  >
                    <xpw-icon icon="delete"></xpw-icon>
                  </xpw-button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>
    </div>

    <ng-template #textDisplay>
      <div *ngFor="let config of dataSource">
        <div class="flex-row space-between">
          <h3>{{ config.channelConfigurationName }}</h3>
          <div class="flex-row flex-end" style="width: auto;">
            <xpw-button type="link" (click)="onEdit(config)">
              <xpw-icon icon="edit"></xpw-icon>
            </xpw-button>
            <xpw-button
              type="link"
              nz-popconfirm
              nzPopconfirmTitle="Are you sure?"
              (nzOnConfirm)="onDelete(config.channelConfigurationUID)"
            >
              <xpw-icon icon="delete"></xpw-icon>
            </xpw-button>
          </div>
        </div>
        <nz-table
          #smallTable
          [nzData]="config.mappings"
          nzSize="small"
          nzBordered
          [nzShowPagination]="false"
        >
          <thead>
            <tr>
              <th>Channel</th>
              <th>Usage Type</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mapping of smallTable.data">
              <td>{{ mapping.channel }}</td>
              <td>{{ usageTypiTitles[mapping.usageTypeID] }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </ng-template>
  `,
  styleUrls: ['./channel-configuration-table.component.less'],
})
export class XpwChannelConfigurationsTableComponent {
  @Input() dataSource: IChannelConfigurations[] = [];
  @Input() canEdit: boolean = false;
  @Input() displayOnTable: boolean = true;
  usageTypiTitles = UsageTypeIDTitle;
  @Output() editConfiguration = new EventEmitter<IChannelConfigurations>();
  @Output() deleteConfiguration = new EventEmitter<string>();
  @Output() addAction = new EventEmitter<void>();

  onEdit(config: IChannelConfigurations): void {
    this.editConfiguration.emit(config);
  }

  onDelete(UID: string): void {
    this.deleteConfiguration.emit(UID);
  }

  onAdd(): void {
    this.addAction.emit();
  }
}
