import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  selectFileImportTypes,
  selectPopupVisible,
  selectResponse,
  selectSelectedFileImportType,
} from 'src/app/store/file-import/file-import.selector';
import { XlsxReaderService } from '@core/services/file-reader/xlsx-reader.service';

import {
  NzUploadChangeParam,
  NzUploadFile,
  NzUploadType,
  NzUploadXHRArgs,
  UploadFileStatus,
} from 'ng-zorro-antd/upload';
import { Subject, Subscription } from 'rxjs';
import {
  setFileData,
  showPopup,
  uploadSetFileData,
} from 'src/app/store/file-import/file-import.actions';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { ImportTypeTitle } from '@shared/utility/global-enums-titles';
import { NotificationService } from '@core/services/notifications/notifications.service';
@Component({
  selector: 'xpw-file-import-popup',
  template: `
    <nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="hidePopup()">
      <div style="width: calc(100% - 30px);" *nzModalTitle>
        <span>{{ title }} </span>
      </div>

      <div *nzModalContent>
        <nz-upload
          [nzAccept]="'.csv,.xlsx'"
          [nzType]="type"
          [nzLimit]="1"
          [nzMultiple]="false"
          [nzCustomRequest]="customReq"
          [(nzFileList)]="files"
          [nzFileListRender]="fileListTpl"
          (nzChange)="handleFileChange($event)"
        >
          <span *ngIf="!uploadingState">
            <p class="ant-upload-drag-icon">
              <xpw-icon icon="inbox" />
            </p>
            <p class="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p class="ant-upload-hint">Files formats supported: XLSX.</p>
          </span>
          <ng-template #fileListTpl let-list>
            <nz-list nzBordered>
              <nz-list-item *ngFor="let item of list">
                <span nz-typography>
                  <ng-container *ngIf="item.status == 'done'">
                    <div class="file-item file-item-success">
                      <xpw-icon icon="file" type="twotone"></xpw-icon>
                      <div class="file-info">
                        <p class="file-name">{{ item.name }}</p>
                        <span class="file-error-label"
                          >{{ item?.size / 1000 }} kByte</span
                        >
                      </div>
                      <xpw-icon
                        icon="delete"
                        (click)="removeFile(item)"
                      ></xpw-icon>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="item.status == 'error'">
                    <div class="file-item file-item-error">
                      <xpw-icon icon="error-file" type="twotone"></xpw-icon>
                      <div class="file-info">
                        <p class="file-name">{{ item.name }}</p>
                        <span class="file-error-label">{{
                          detailFileInfo
                        }}</span>
                      </div>
                      <xpw-icon
                        icon="delete"
                        (click)="removeFile(item)"
                      ></xpw-icon>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="item.status == 'uploading'">
                    <span nz-icon [nzType]="'loading'"></span>
                  </ng-container>
                </span>
              </nz-list-item>
            </nz-list>
          </ng-template>
        </nz-upload>

        <div
          *ngIf="uploadingState"
          class="ant-upload-list ant-upload-list-picture"
        >
          <div class="flex-row align-center space-around card">
            <p>
              <label>Sheets</label>
              <span><xpw-icon icon="sheet" /> {{ sheetOptions.length }}</span>
            </p>
            <p>
              <label>Records</label>
              <span><xpw-icon icon="meter" /> {{ meterCount }}</span>
            </p>
          </div>
          <div class="flex-row align-center">
            <div>Please choose the sheet you want to upload</div>
            <xpw-select
              [disabled]="sheetOptions.length === 0"
              [options]="sheetOptions"
              [(value)]="selectedSheet"
              (valueChange)="selectSheet($event)"
              style="width: 200px;display:block;"
            />
          </div>
        </div>
      </div>
      <div *nzModalFooter>
        <button nz-button nzType="default" (click)="hidePopup()">Cancel</button>
        <button
          nz-button
          nzType="primary"
          (click)="handleContinue()"
          [disabled]="!isSubmitValid()"
          [nzLoading]="isConfirmLoading"
        >
          Continue
        </button>
      </div>
    </nz-modal>
  `,
  styleUrls: ['./file-import-popup.component.less'],
})
export class XpwFileImportPopupComponent {
  // Declaring class properties
  isVisible = false;
  isConfirmLoading = false;
  fileImportTypes: any[] = [];
  selectedFileImportType: any;
  detailFileInfo: string | null = null;
  title: string = '';
  type: NzUploadType = 'drag';
  selectedSheet: any = null;
  files: NzUploadFile[] = [];
  uploadingState = false;
  sheetOptions: any[] = [];
  meterCount: number = 0;
  private fileChangeSubject = new Subject();

  constructor(
    private store: Store,
    private xlsReader: XlsxReaderService,
    private notifications: NotificationService,
  ) {
    // Subscribing to store selectors
    this.subscribeToStore();
  }

  // Method to subscribe to store selectors
  private subscribeToStore(): void {
    this.store.select(selectPopupVisible).subscribe((isVisible) => {
      this.isVisible = isVisible;
    });
    this.store.select(selectResponse).subscribe((response) => {
      if (response) this.handleResponse(response);
    });
    this.store
      .select(selectSelectedFileImportType)
      .subscribe((fileImportTypeID) => {
        this.selectedFileImportType = fileImportTypeID;
        this.title = ImportTypeTitle[fileImportTypeID];
      });
    this.store.select(selectFileImportTypes).subscribe((fileImportTypes) => {
      this.fileImportTypes = GlobalFunctions.convertToOptions(
        fileImportTypes,
        'fileImportTypeID',
        'description',
      );
    });
  }

  handleFileChange(info: NzUploadChangeParam): void {
    //check file amount and remove any extra filesexcept the first one
    if (info.fileList.length > 1) {
      this.files = info.fileList.slice(-1);
    }

    if (!this.checkFileType(info.file)) {
      info.file.status = 'error';
      this.detailFileInfo = $localize`Invalid file type. Please upload a CSV or XLSX file`;
      // this.notifications.error(undefined, this.detailFileInfo);
      this.type = 'drag';
      this.uploadingState = false;
      return;
    }
  }

  handleContinue(): void {
    this.isConfirmLoading = true;
    this.uploadFile();
  }

  hidePopup(): void {
    this.resetFileUpload();
    this.store.dispatch(showPopup({ isVisible: false }));
  }

  // Function to create sheet options
  createSheetOptions(data: { [key: string]: number }) {
    this.sheetOptions = Object.keys(data).map((key) => {
      return { label: key, value: data[key] + key, count: data[key] };
    });
  }

  // Function to handle file status
  handleFileStatus(file: NzUploadFile, item: NzUploadXHRArgs) {
    if (file.uid === item.file.uid) {
      if (this.sheetOptions.every((item) => item.count === 0)) {
        this.setFileStatus(file, 'error', $localize`File is empty`);
      } else {
        if (this.sheetOptions.length == 1) {
          this.selectSheet(this.sheetOptions[0].value);
        }
        this.setFileStatus(file, 'done');
      }
    }
  }

  // Function to set file status and handle notifications
  setFileStatus(
    file: NzUploadFile,
    status: UploadFileStatus,
    message?: string,
  ) {
    file.status = status;
    this.detailFileInfo = message || null;

    switch (status) {
      case 'done':
        this.type = 'select';
        this.uploadingState = true;
        // this.notifications.success(
        //   undefined,
        //   $localize`${file.name} is in the correct format.`,
        // );
        break;
      case 'error':
        this.type = 'drag';
        this.uploadingState = false;
        this.detailFileInfo =
          this.detailFileInfo ??
          $localize`${file.name} file local read failed.`;
        // this.notifications.error(undefined, this.detailFileInfo);
        break;
    }
  }

  // Refactored readFileResponse function
  readFileResponse = (
    data: { [key: string]: number },
    item: NzUploadXHRArgs,
  ) => {
    this.createSheetOptions(data);
    this.files.forEach((file) => this.handleFileStatus(file, item));
    this.fileChangeSubject.next(item.file);
  };

  customReq: (item: NzUploadXHRArgs) => Subscription = (
    item: NzUploadXHRArgs,
  ) => {
    this.uploadingState = true;
    this.type = 'select'; // this line is just to remove the border of the upload box;

    if (this.checkFileType(item.file)) {
      this.xlsReader.processFile(item.file, (data) =>
        this.readFileResponse(data, item),
      );
    }
    return this.fileChangeSubject.subscribe();
  };

  selectSheet = (sheetNameValue: number) => {
    const sheet = this.sheetOptions.find(
      (options) => options.value === sheetNameValue,
    );
    this.selectedSheet = sheet.value;

    this.meterCount = sheet.count;

    this.store.dispatch(
      setFileData({
        sheetName: sheet.label,
      }),
    );
  };

  uploadFile = () => {
    this.store.dispatch(
      uploadSetFileData({ file: this.files[0].originFileObj }),
    );
  };

  checkFileType = (file: NzUploadFile) => {
    const fileType = file.name.split('.').pop().toLocaleLowerCase();
    if (fileType !== 'csv' && fileType !== 'xlsx') {
      return false;
    }
    return true;
  };
  removeFile = (file: NzUploadFile) => {
    this.files = this.files.filter((f) => f.uid !== file.uid);
    if (this.files.length === 0) {
      this.uploadingState = false;
      this.type = 'drag';
    }
  };

  handleResponse = (response: any) => {
    this.isConfirmLoading = false;
    if (response.fileImportUID) {
      this.notifications.loading(
        undefined,
        $localize`File uploaded successfully and starting processing`,
      );
      this.hidePopup();
    } else {
      this.files[0].status = 'error';
      this.uploadingState = false;
      this.type = 'drag';
      this.detailFileInfo = response.message;
      this.selectedSheet = null;
      if (response.status == 500)
        this.detailFileInfo = $localize`An error occurred while uploading the file. Please try again.`;
    }
  };

  isSubmitValid = (): boolean => {
    return (
      this.files.length > 0 &&
      this.selectedSheet !== null &&
      this.meterCount > 0
    );
  };

  resetFileUpload = () => {
    this.files = [];
    this.uploadingState = false;
    this.type = 'drag';
    this.sheetOptions = [];
    this.selectedSheet = null;
    this.meterCount = 0;
  };
}
