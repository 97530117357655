import {
  Directive,
  ElementRef,
  Renderer2,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  LOCALE_ID,
} from '@angular/core';
import { getLocaleDirection } from '@angular/common';

@Directive({
  selector: '[myCustomDirective]',
})
export class MyCustomDirective implements OnChanges, OnInit {
  @Input() isOpen: boolean = false; // Default value, change it according to your requirement

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpen']) {
      this.updateStyles();
    }
  }
  ngOnInit(): void {
    this.isOpen = false;
    this.updateStyles();
  }
  private updateStyles(): void {
    const element = this.elementRef.nativeElement;

    // Set inline styles based on the 'isOpen' value
    if (this.isOpen) {
      this.renderer.setStyle(element, 'width', '400px');
      this.renderer.setStyle(element, 'min-width', '400px');
      this.renderer.setStyle(element, 'max-width', '400px');
      this.renderer.setStyle(element, 'flex', '400px');
      this.renderer.setStyle(element, 'transform', 'translateX(0px)');
    } else {
      this.renderer.setStyle(element, 'width', '0px');
      this.renderer.setStyle(element, 'min-width', '0px');
      this.renderer.setStyle(element, 'max-width', '0px');
      this.renderer.setStyle(element, 'flex', '0px');
      let direction = -400;
      if (getLocaleDirection(this.locale) == 'rtl') direction = 400;
      this.renderer.setStyle(
        element,
        'transform',
        `translateX(${direction}px)`,
      );
    }
  }
}
