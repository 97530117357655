import { IImportHistoryResult } from '@features/import-history/import-history-store/import-history.interface';
import { IDeliveryMethod } from '@features/organizations/store/organizations.interface';
import { IFilterOptions, IPagingResponse } from '@shared/utility/global-enums';

import { IPagingRequest } from '@shared/utility/global-enums';

export interface IReport {
  // reportUID: string;
  // name: string;
  // dateCreated: Date;
  // status: string;
  // metersCount: number;
  deliveryMethodUID: string;
  hasFile: boolean;
  numOfMeters: number;
  reportConfigUID: string;
  reportName: string;
  reportStatusID: number;
  reportTemplateID: number;
  reportUID: string;
  scheduledDate: Date;
}
export interface IReportConfig {
  reportConfigUID: string;
  reportConfigName: string;
  reportTemplateID: number;
  fileName: string;
  deliveryMethodUID: string;
  frequency: string;
  useEstimatedMissingData: boolean;
  useEstimatedNotValidData: boolean;
  reportConfigStatusID: ReportConfigStatus;
  meterGroupUIDs: string[];

  latestReports: IReport[] | null;
}
export enum DeliveryMethod {
  SFTP = 'SFTP',
  EMAIL = 'EMAIL',
  FTP = 'FTP',
  API = 'API',
  DOWNLOAD = 'DOWNLOAD',
}

export interface IReportState {
  reportConfigs: IReportResult;
  selectedReportConfig: IReportConfig;
  pagingRequest: IPagingRequest;
  filter: {
    ReportConfigStatus: ReportConfigStatus[];
    MeterGroupUID: string[];
    ReportTemplateID: number[];
    DeliveryMethodUID: string[];
  };
  filterOptions: IFilterOptions[];
  search: string;
  error: string | null;
  isNewReportConfig: boolean;
  templates: any;
  meterGroups: any[];
  deliveryMethods: any[];
  validationErrors: any;
  reportDrawers: {
    reportConfig: boolean;
  };
}

export interface IReportResult {
  items: IReportConfig[];
  pagingInfo: IPagingResponse;
  isLoading: boolean;
}

export enum ReportConfigStatus {
  Active = 1,
  Suspended = 2,
  Deleted = 3,
}
export enum ReportStatus {
  Waiting = 1,
  Delivered = 2,
  Failed = 3,
}
