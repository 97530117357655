import { createAction, props } from '@ngrx/store';
import { IDepartment } from './departments.interface';

export enum DepartmentsActionType {
  selectDepartment = '[Department] Select Department',
  getDepartmentList = '[Department] Get Department List',
  getDepartmentListSuccess = '[Department] Get Department List Success',
  getStorageList = '[Department] Get List Of Storage',
  getStorageListSuccess = '[Department] Get List Of Storage - success',
  getStorageListFailure = '[Department] Get List Of Storage - Failure',
  DepartmentError = '[Department] Error',
  updateDepartment = '[Department] Save edited department',
  updateDepartmentSuccess = '[Department] Save edited department success',
  updateDepartmentFailure = '[Department] Save edited department failure',
  createNewDepartment = '[Department] Create new department',

  setNewDepartment = '[Department] Save new department',
  setNewDepartmentSuccess = '[Department] Save new department success',
  setNewDepartmentFailure = '[Department] Save new department failure',
  deleteDepartment = '[Department] Delete department',
  deleteDepartmentSuccess = '[Department] Delete department success',
  deleteDepartmentFailure = '[Department] Delete department failure',
  ShowErrorMessage = '[Department] Show Errors Messages',
  ShowSuccessMessage = '[Department] Show Messages Success',

  ResetDepartmentState = '[Department] Reset Department State',
  SearchChange = '[Department] Search Change',
}

export const selectDepartment = createAction(
  DepartmentsActionType.selectDepartment,
  props<{ DepartmentUID: string }>(),
);

// department list
export const getDepartmentList = createAction(
  DepartmentsActionType.getDepartmentList,
);

export const getDepartmentListSuccess = createAction(
  DepartmentsActionType.getDepartmentListSuccess,
  props<{ Departments: IDepartment[] }>(),
);

// Storage list
export const getStorageList = createAction(
  DepartmentsActionType.getStorageList,
);

export const getStorageListSuccess = createAction(
  DepartmentsActionType.getStorageListSuccess,
  props<{ storageList: string[] }>(),
);

// Save or update edited department
export const updateDepartment = createAction(
  DepartmentsActionType.updateDepartment,
  props<{ departmentUpdated: IDepartment }>(),
);

export const updateDepartmentSuccess = createAction(
  DepartmentsActionType.updateDepartmentSuccess,
  props<{ updatedDepartment: IDepartment }>(),
);

export const updateDepartmentFailure = createAction(
  DepartmentsActionType.updateDepartmentFailure,
  props<{ ValidationErrors: any }>(),
);

// add New department

export const createNewDepartment = createAction(
  DepartmentsActionType.createNewDepartment,
);

export const setNewDepartment = createAction(
  DepartmentsActionType.setNewDepartment,
  props<{ departmentUpdated: IDepartment }>(),
);

export const setNewDepartmentSuccess = createAction(
  DepartmentsActionType.setNewDepartmentSuccess,
  props<{ createdDepartment: IDepartment }>(),
);

export const setNewDepartmentFailure = createAction(
  DepartmentsActionType.setNewDepartmentFailure,
  props<{ ValidationErrors: any }>(),
);

//delete department
export const deleteDepartment = createAction(
  DepartmentsActionType.deleteDepartment,
  props<{ departmentUID: string }>(),
);

export const deleteDepartmentSuccess = createAction(
  DepartmentsActionType.deleteDepartmentSuccess,
  props<{ departmentUID: string }>(),
);

export const deleteDepartmentFailure = createAction(
  DepartmentsActionType.deleteDepartmentFailure,
  props<{ ValidationErrors: any }>(),
);

export const showErrors = createAction(
  DepartmentsActionType.ShowErrorMessage,
  props<{ message: string }>(),
);

export const showSuccessMessage = createAction(
  DepartmentsActionType.ShowSuccessMessage,
  props<{ message: string }>(),
);

export const resetDepartmentState = createAction(
  DepartmentsActionType.ResetDepartmentState,
);

export const searchChange = createAction(
  DepartmentsActionType.SearchChange,
  props<{ search: string }>(),
);
