export interface IActUser {
  emailAddress: string;
  userUId: string;
  loginIdentifierTypeID: number;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  languageID: number;
  userName: string;
  password: string;
  otp: string;
}

export interface IActivationForm {
  status: UserInvitationLinkStatus;
  actUser: IActUser;
  ValidationErrors: any;
  loading: boolean;
  doesAzureAccountExist: boolean;
}

export interface IGetStatusResponse {
  status: UserInvitationLinkStatus;
  userUId: string;
  loginIdentifierTypeID: number;
  emailAddress: string;
  userName: string;
  doesAzureAccountExist: boolean;
  languageID: number;
}

export enum UserInvitationLinkStatus {
  Valid = 1,
  Expired = 2,
  AccountAlreadyActivated = 3,
  AccountNotExistAnymore = 4,
  AccountRegistrationSuccess = 10,
  ServiceError = 666,
}
