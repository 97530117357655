import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfirmDialogService {
  private isVisibleSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private confirmDialogData: BehaviorSubject<ConfirmDialogData> =
    new BehaviorSubject<ConfirmDialogData>({ handleOk: () => {} });

  public isVisible$: Observable<boolean> = this.isVisibleSubject.asObservable();
  public confirmDialogData$: Observable<ConfirmDialogData> =
    this.confirmDialogData.asObservable();

  showConfirmModal({
    title = 'Title',
    handleOk = () => {},
    handleCancel = () => {},
    content = 'Conetnt',
    cancelText = 'Cancel',
    okText = 'Confirm',
    auditCommentsVisible = true,
    okButtonIsRed = false,
  }): void {
    this.isVisibleSubject.next(true);
    this.confirmDialogData.next({
      title,
      handleOk,
      content,
      cancelText,
      auditCommentsVisible,
      okText,
      okButtonIsRed: okButtonIsRed,
      handleCancel,
    });
  }

  CloseDialog(): void {
    this.isVisibleSubject.next(false);
  }
  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.isVisibleSubject.unsubscribe();
    this.confirmDialogData.unsubscribe();
  }
}
export interface ConfirmDialogData {
  title?: string;
  content?: string;
  auditCommentsVisible?: boolean;
  okText?: string;
  cancelText?: string;
  handleOk?: () => void;
  handleCancel?: () => void;
  okButtonIsRed?: boolean;
}
