import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xpw-select',
  template: `
    <nz-form-label [nzFor]="label">{{ label }}</nz-form-label>
    <nz-select
      nzWidth="100%"
      nzShowSearch
      [nzMode]="mode"
      [nzOptions]="options"
      [(ngModel)]="value"
      (ngModelChange)="valueChange.emit($event)"
      [disabled]="disabledInput"
      nzSize="large"
    >
    </nz-select>
  `,

  styleUrls: ['./xpw-select.component.less'],
})
export class XpwSelectComponent {
  @Input() options: any[] = [];
  @Input() label: string = '';
  @Input() mode: any = 'default';
  @Input() value: any = '';
  @Input('disabled') disabledInput: boolean = false;
  @Output() valueChange = new EventEmitter<any>();
}
