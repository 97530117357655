import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  EventBusService,
  Events,
} from '@core/services/interceptors/event-bus.service';
import { Subscription, Subject } from 'rxjs';
import { NzNotificationPlacement } from 'ng-zorro-antd/notification';
import { ErrorKeyLabels } from '@shared/utility/errors-keys-msg';
import { Action } from '../actions/actions.service';
import {
  NotificationButton,
  NotificationData,
} from '@core/components/notifications/notifications.component';

import { SWNotificationService } from './sw-notifications.service';
import { BrowserStatusService } from '../browser/browser-status.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  httpResponseSub: Subscription;

  private notificationTemplateData: Subject<NotificationData> =
    new Subject<NotificationData>();

  public notificationTemplateData$ =
    this.notificationTemplateData.asObservable();

  options = {
    placement: 'bottomRight' as NzNotificationPlacement,
    duration: 5000,
  };

  constructor(
    private eventBus: EventBusService,
    private swNotificationService: SWNotificationService,
    private browserStatus: BrowserStatusService,
  ) {
    this.httpResponseSub = this.eventBus.on(
      Events.httpResponse,
      (response: HttpErrorResponse) => {
        if (response.error?.toastMsg as ToastMessage) {
          this.processToast(response.error.toastMsg);
        }
      },
    );
  }

  success(title: string = 'Success', txtMsg: string) {
    this.processToast({
      titleKey: title,
      messageKeys: [txtMsg],
      type: NotificationType.Success,
    });
  }

  error(title: string = 'Error', message: string = 'Something went wrong!') {
    this.processToast({
      titleKey: title,
      messageKeys: [message],
      type: NotificationType.Error,
    });
  }

  info(title: string = 'Info', txtMsg: string) {
    this.processToast({
      titleKey: title,
      messageKeys: [txtMsg],
      type: NotificationType.Info,
    });
  }

  warning(title: string = 'warning', txtMsg: string) {
    this.processToast({
      titleKey: title,
      messageKeys: [txtMsg],
      type: NotificationType.Warning,
    });
  }

  // we should create a loading spinner with template black ng-zorro notification
  loading(title: string = 'Processing', txtMsg: string) {
    this.processToast(
      {
        titleKey: title,
        messageKeys: [txtMsg],
        type: NotificationType.Loading,
      },
      20000,
    );
  }

  clearAllNotifications() {
    this.notificationTemplateData.next(null);
  }
  ngOnDestroy(): void {
    this.httpResponseSub.unsubscribe();
  }

  processToast(
    toast: ToastMessage,
    duration: number = this.options.duration,
    placement: NzNotificationPlacement = 'bottomRight',
  ): void {
    const { title, message } = this.getToastTitleAndMessage(toast);
    const buttons = this.getButtons(toast);
    this.notificationTemplateData.next({
      type: toast.type,
      title,
      message,
      duration,
      buttons,
      placement,
    });

    if (!this.browserStatus.isFocused())
      this.swNotificationService.showNotification(title, { body: message });
  }

  getButtons(toast: ToastMessage): NotificationButton[] {
    if (toast.buttons) {
      return toast.buttons.map((button) => {
        return {
          text: button.textKey,
          actions: button.actions,
        };
      });
    }
    return [];
  }
  //  extract the title and message from the toast message
  getToastTitleAndMessage(toastError: ToastMessage): {
    title: string;
    message: string;
  } {
    try {
      if (typeof toastError === 'string') {
        return { title: 'Error', message: toastError };
      }

      let message: string;
      if (Array.isArray(toastError.messageKeys)) {
        message = toastError.messageKeys
          .map((key) => this.getStringFromKey(key))
          .join('\n');
      } else {
        message = this.getStringFromKey(toastError.messageKeys);
      }
      return {
        title: this.getStringFromKey(toastError.titleKey),
        message: message,
      };
    } catch (e) {
      console.log(e);
      return { title: 'Error', message: 'Something went wrong!' };
    }
  }

  // get the string from the key
  getStringFromKey(key: string): string {
    if (key.includes('$')) {
      return this.getStringFromKeyWithVariables(key);
    }

    return ErrorKeyLabels[key] ?? key;
  }

  // show a toast message with variables
  getStringFromKeyWithVariables(key: string): string {
    const parts = key.split('$');
    const _key = parts[0];
    const values = parts.slice(1).map(Number); // Convert all remaining parts to numbers
    const label = ErrorKeyLabels[_key](...values); // Spread operator to pass all values as arguments
    return label;
  }
}

export interface ToastMessage {
  messageKeys: string[];
  titleKey: string;
  type: NotificationType;
  buttons?: ToastButton[];
}
interface ToastButton {
  textKey: string;
  actions: Action[];
}
export enum NotificationType {
  Success = 1,
  Warning = 2,
  Error = 3,
  Info = 4,
  Loading = 5,
}
