import { createReducer, on } from '@ngrx/store';
import * as actUserActions from './act-user.actions';
import { initialStateActivationForm } from './act-user.state';
import { UserInvitationLinkStatus } from './act-user.interface';

export const ActUserStoreReducer = createReducer(
  initialStateActivationForm,
  on(actUserActions.getStatusSuccess, (state, { getStatusResp }) => {
    return {
      ...state,
      status: getStatusResp.status,
      doesAzureAccountExist: getStatusResp.doesAzureAccountExist,
      actUser: { ...state.actUser, ...getStatusResp },
      loading: false,
    };
  }),
  on(actUserActions.setErrorStatus, (state) => {
    return {
      ...state,
      status: UserInvitationLinkStatus.ServiceError,
      doesAzureAccountExist: false,
      actUser: { ...state.actUser },
    };
  }),
  on(actUserActions.getStatusFailure, (state, { errors }) => {
    return {
      ...state,
      errors: errors,
    };
  }),

  on(actUserActions.updateActUserForm, (state, { actUser }) => {
    return {
      ...state,
      actUser: { ...state.actUser, ...actUser },
    };
  }),
  on(actUserActions.completeRegistration, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    actUserActions.completeRegistrationFailure,
    (state, { ValidationErrors }) => {
      return {
        ...state,
        ValidationErrors,
        loading: false,
      };
    },
  ),
);
