import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Role } from '@features/user/user-store/user.interface';
import { selectCurrentUserRoles } from 'src/app/store/current-user/current-user.selector';
import { Observable, filter, map } from 'rxjs';

function hasRole(roles: Role[], requiredRoles: Role[]): boolean {
  return requiredRoles.some((roleToCheck) => roles.includes(roleToCheck));
}

export function roleGuard(...requiredRoles: Role[]): CanActivateFn {
  return (route, state): Observable<boolean> => {
    let store = inject(Store);
    let router = inject(Router);

    return store.pipe(
      select(selectCurrentUserRoles),
      filter((roles) => roles.length > 0), // Wait until roles are not null
      map((roles) => {
        // Check if the user has any of the required roles
        const hasRequiredRoles = hasRole(roles, requiredRoles);
        // If the user does not have any of the required roles, the user will be redirected to the default page
        if (!hasRequiredRoles) router.navigate(['dashboard/energy-overview']);
        return hasRequiredRoles;
      }),
    );
  };
}
