import {
  IActivationForm,
  UserInvitationLinkStatus,
} from './act-user.interface';

export const initialStateActivationForm: IActivationForm = {
  status: null,
  actUser: {
    userUId: '',
    loginIdentifierTypeID: null,
    emailAddress: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    languageID: null,
    userName: '',
    password: '',
    otp: '',
  },
  doesAzureAccountExist: false,
  ValidationErrors: null,
  loading: false,
};
