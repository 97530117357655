import {
  Component,
  Input,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxOptionType } from '@shared/xpw-ui-kit/basic/checkbox/xpw-checkbox.component';
import { AbstractInputArrayComponent } from '../abstract-input-array.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'xpw-form-input-multicheckbox',
  template: `
    <nz-form-item>
      <nz-form-label [nzFor]="label" *ngIf="label"
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="nzHasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <xpw-checkbox-group
          [options]="_filteredOptions"
          (optionsChange)="optionsChanged($event)"
        />
      </nz-form-control>
    </nz-form-item>
  `,

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputMultiCheckboxComponent),
      multi: true,
    },
  ],
})
export class XpwInputMultiCheckboxComponent
  extends AbstractInputArrayComponent
  implements OnChanges
{
  @Input() options: CheckboxOptionType[] = [];
  @Input('label') label: string;
  @Input() filterable: boolean = false;
  @Input('hasFeedback') nzHasFeedback: boolean = true;

  _filteredOptions: CheckboxOptionType[];
  searchText: string = '';
  keyPress$ = new Subject<string>();

  optionsChanged(event: CheckboxOptionType[]) {
    // get array of values that are true
    let values: any[] = event
      .filter((x) => x.checked)
      .map((x) => x.value) as any[];

    this.onChange(values);
  }

  ngOnInit() {
    this._filteredOptions = this.options;
    this.updateCheckedOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this._filteredOptions = this.options;
      this.updateCheckedOptions();
    }
  }

  override writeValue(value: any[]): void {
    super.writeValue(value);
    if (value) {
      this.updateCheckedOptions();
    }
  }

  private updateCheckedOptions() {
    if (this.value && this.options) {
      this.options.forEach((option) => {
        option.checked = this.value.includes(option.value);
      });
    }
  }
}
