import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IActivationForm } from './act-user.interface';

const selectUserFeature = createFeatureSelector<IActivationForm>('actUserForm');

export const selectActUserForm = createSelector(
  selectUserFeature,
  (state: IActivationForm) => state,
);

export const selectValidationErrors = createSelector(
  selectUserFeature,
  (state: IActivationForm) => state.ValidationErrors,
);

export const selectLoadingStatus = createSelector(
  selectUserFeature,
  (state: IActivationForm) => state.loading,
);
