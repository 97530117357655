import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Injectable({ providedIn: 'root' })
export class XlsxReaderService {
  constructor() {}
  data: AOA = [
    [1, 2],
    [3, 4],
  ];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';

  processFile(
    file: any,
    callback?: (data: any, sheetNames?: string[]) => void,
  ) {
    /* wire up file reader */

    // const target: DataTransfer = <DataTransfer>evt.target;

    // if (files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */

      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];

      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

      if (callback) {
        callback(this.getSheetRowCounts(wb.SheetNames, wb));
      }
    };

    reader.readAsBinaryString(file);
  }

  // create a function that will take all sheets and count the number of rows, and return a key value pair of sheet name and row count
  getSheetRowCounts(
    sheetNames: string[],
    wb: XLSX.WorkBook,
  ): { [key: string]: number } {
    const sheetRowCounts: { [key: string]: number } = {};
    sheetNames.forEach((sheetName) => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName];
      sheetRowCounts[sheetName] = XLSX.utils.sheet_to_json(ws).length;
    });
    return sheetRowCounts;
  }
}
