import { createReducer, on } from '@ngrx/store';
import * as DepartmentActions from './departments.actions';
import { initialDepartmentState } from './departments.state';

// IDepartmentState interface
export const DepartmentReducer = createReducer(
  initialDepartmentState,
  on(DepartmentActions.selectDepartment, (state, { DepartmentUID }) => ({
    ...state,
    newDepartment: false,
    successResult: false,
    selectedDepartment:
      state.departments.find((item) => item.departmentUID === DepartmentUID) ||
      null,
  })),
  on(DepartmentActions.createNewDepartment, (state) => ({
    ...state,
    newDepartment: true,
    successResult: false,
    selectedDepartment: initialDepartmentState.selectedDepartment,
  })),
  on(
    DepartmentActions.setNewDepartmentSuccess,
    (state, { createdDepartment }) => ({
      ...state,
      departments: [createdDepartment, ...state.departments],
      filteredDepartments: [createdDepartment, ...state.filteredDepartments],
      successResult: true,
    }),
  ),
  on(DepartmentActions.getDepartmentListSuccess, (state, { Departments }) => ({
    ...state,
    departments: Departments,
    filteredDepartments: Departments,
  })),
  on(DepartmentActions.getStorageListSuccess, (state, { storageList }) => ({
    ...state,
    storageList: storageList,
  })),
  on(
    DepartmentActions.updateDepartmentSuccess,
    (state, { updatedDepartment }) => ({
      ...state,
      departments: state.departments.map((item) =>
        item.departmentUID === updatedDepartment.departmentUID
          ? updatedDepartment
          : item,
      ),
      filteredDepartments: state.filteredDepartments.map((item) =>
        item.departmentUID === updatedDepartment.departmentUID
          ? updatedDepartment
          : item,
      ),
      successResult: true,
    }),
  ),
  on(
    DepartmentActions.updateDepartmentFailure,
    (state, { ValidationErrors }) => {
      return {
        ...state,
        ValidationErrors,
      };
    },
  ),
  on(DepartmentActions.deleteDepartmentSuccess, (state, { departmentUID }) => ({
    ...state,
    departments: state.departments.filter(
      (item) => item.departmentUID !== departmentUID,
    ),
    filteredDepartments: state.filteredDepartments.filter(
      (item) => item.departmentUID !== departmentUID,
    ),
  })),
  on(
    DepartmentActions.setNewDepartmentFailure,
    (state, { ValidationErrors }) => {
      return {
        ...state,
        ValidationErrors,
      };
    },
  ),
  on(DepartmentActions.resetDepartmentState, (state) => ({
    ...initialDepartmentState,
  })),
  on(DepartmentActions.searchChange, (state, { search }) => ({
    ...state,
    searchText: search,
    filteredDepartments: state.departments.filter(
      (item) =>
        item.departmentName.toUpperCase().includes(search.toUpperCase()) ||
        item.storageName.toUpperCase().includes(search.toUpperCase()),
    ),
  })),
);
