import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@shared/xpw-ui-kit/form/abstract-input.component';
import { Store } from '@ngrx/store';
import {
  selectShowStates,
  selectStates,
} from 'src/app/store/location/location.selector';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { setCity, setState } from 'src/app/store/location/location.actions';

@Component({
  selector: 'xpw-form-select-states',
  template: `
    <ng-container *ngIf="!disabledInput">
      <nz-form-item>
        <nz-form-label *ngIf="label"
          >{{ label }} {{ isOptional ? ' (Optional)' : '' }}</nz-form-label
        >
        <nz-form-control
          [nzHasFeedback]="hasFeedback"
          [nzValidateStatus]="errorStatus()"
          [nzErrorTip]="getErrorLabel()"
        >
          <nz-select
            nzSuffixIcon="xpw-outline:{{ icon }}"
            nzShowSearch
            [disabled]="disabledInput"
            [nzMode]="mode"
            [nzMaxTagCount]="countToShow"
            [nzOptions]="options"
            [nzMaxTagCount]="10"
            [(ngModel)]="value"
            (blur)="onTouched()"
            (ngModelChange)="selectChange($event)"
          >
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwFormStatesComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class XpwFormStatesComponent
  extends AbstractInputComponent
  implements OnInit
{
  @Input('options') options: any[]; // ['electricity', 'water', 'gas'];

  @Input('label') label: string;
  @Input('countToShow') countToShow: number;
  @Input('disabled') disabledInput: boolean = false;
  @Input('icon') icon: string = 'down';
  @Input('mode') mode: any = 'default'; // 'multiple' | 'tags' | 'default'
  @Input() hasFeedback: boolean = true;
  @Output() onSelectedChange = new EventEmitter();

  // stateData: ICountryState[];
  selectChange(id: any) {
    // console.log(event);
    // const state = this.stateData.find((c) => c.id === event);
    this.store.dispatch(setState({ stateId: id }));
    this.store.dispatch(setCity({ city: null }));
    this.onSelectedChange.emit(event);
  }

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.store.select(selectStates).subscribe((states) => {
      // this.stateData = states;
      this.options = GlobalFunctions.convertToOptions(
        states,
        'id',
        'name',
        this.isOptional,
      );
    });

    this.store.select(selectShowStates).subscribe((showStates) => {
      console.log('showStates :', showStates);
      this.disabledInput = !showStates;
      this.cdr.markForCheck();
    });
  }
}
