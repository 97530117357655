import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XpwIconMenuConsumptionComponent } from './icons/xpw-icon-menu-consumption.component';
// import { IconInsertDirective } from './icon-insert.deirective';
import { XpwIconMenuAccountComponent } from './icons/xpw-icon-menu-account.component ';
import { XpwIconBreadcrumbsHomeComponent } from './icons/xpw-icon-breadcrumbs-home';
import { XpwIconLayoutDeviceOpenComponent } from './icons/xpw-icon-layout-device-open';
import { XpwIconMenuElectricityComponent } from './icons/xpw-icon-menu-electricity.component';
import { XpwIconMenuEventsComponent } from './icons/xpw-icon-menu-events.component';
import { XpwIconMenuFuelComponent } from './icons/xpw-icon-menu-fuel.component';
import { XpwIconMenuNotificationsComponent } from './icons/xpw-icon-menu-notifications.components';
import { XpwIconMenuWaterComponent } from './icons/xpw-icon-menu-water.component';
import { XpwIconMenuReadingsComponent } from './icons/xpw-icon-menu-readings.component';
import { XpwIconMenuLogoutComponent } from './icons/xpw-icon-menu-logout.component';
// import { NzDemoIconNamespaceComponent } from './icons/xpw-icon-nz-try';
// import { IconTwoComponent } from './icon-two.component';
import { XpwIconloginPassword } from './icons/xpw-icon-login-password.component';
import { XpwIconMenuEllipseComponent } from './icons/xpw-icon-menu-ellipse.component';
import { XpwIconMenuCloseComponent } from './icons/xpw-icon-menu-close';
import { XpwIconMenuOpenComponent } from './icons/xpw-icon-menu-open';
import { XpwIconLayoutDeviceCloseComponent } from './icons/xpw-icon-layout-device-close';
import { XpwIconSelectionDevice } from './icons/xpw-icon-selection-device';
import { XpwIconSelectionOrganization } from './icons/xpw-icon-selection-organization';
import { XpwIconSelectionDevices } from './icons/xpw-icon-selection-devices';
import { XpwIconSelectionSite } from './icons/xpw-icon-selection-site';
import { XpwIconSelectionChevron } from './icons/xpw-icon-selection-chevron';
import { XpwIconBreadcrumbsDevider } from './icons/xpw-icon-breadcrumbs-devider';
import { XpwIconMenuDepartments } from './icons/xpw-icon.menu-departments.component';
import { XpwIconMenuStructure } from './icons/xpw-icon.menu-structure.component';

const components = [
  XpwIconMenuConsumptionComponent,
  XpwIconMenuAccountComponent,
  XpwIconBreadcrumbsHomeComponent,
  XpwIconLayoutDeviceOpenComponent,
  XpwIconLayoutDeviceCloseComponent,
  XpwIconMenuElectricityComponent,
  XpwIconMenuEventsComponent,
  XpwIconMenuFuelComponent,
  XpwIconMenuNotificationsComponent,
  XpwIconMenuWaterComponent,
  XpwIconMenuReadingsComponent,
  XpwIconMenuLogoutComponent,
  XpwIconloginPassword,
  XpwIconMenuEllipseComponent,
  XpwIconMenuCloseComponent,
  XpwIconMenuOpenComponent,
  XpwIconSelectionDevice,
  XpwIconSelectionDevices,
  XpwIconSelectionOrganization,
  XpwIconSelectionChevron,
  XpwIconSelectionSite,
  XpwIconBreadcrumbsDevider,
  XpwIconMenuDepartments,
  XpwIconMenuStructure,
];

@NgModule({
  declarations: [components],
  imports: [CommonModule],
  exports: [components],
})
export class IconsModule {}
