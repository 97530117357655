import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Injectable()
export class XpwTableService {
  private destroy$ = new Subject<boolean>();

  private isScrolledSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isScrolled$: Observable<boolean> =
    this.isScrolledSubject.asObservable();

  private tableScrollSubject: BehaviorSubject<CdkVirtualScrollViewport> =
    new BehaviorSubject<CdkVirtualScrollViewport>(null);
  public tableScroll$: Observable<CdkVirtualScrollViewport> =
    this.tableScrollSubject.asObservable();

  constructor() {}

  public setTableScroll(tableScroll: CdkVirtualScrollViewport): void {
    this.tableScrollSubject.next(tableScroll);
    this.eventsSub();
  }

  public scrollToIndex = (index: number) => {
    this.tableScrollSubject.value.scrollToIndex(index, 'smooth');
  };

  private eventsSub() {
    this.tableScrollSubject.value
      .elementScrolled()
      .pipe(
        takeUntil(this.destroy$),
        map(() => this.tableScrollSubject.value.measureScrollOffset() > 0),
        distinctUntilChanged(),
      )
      .subscribe((isScrolled: boolean) => {
        this.isScrolledSubject.next(isScrolled);
      });
  }

  destroyTable(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
