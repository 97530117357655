import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IMeterGroup,
  IMeterGroupView,
} from '@features/meter-group/store/meter-group.interface';
import { SelectMetersService } from '@features/select-meters/service/select-meters.service';

@Injectable({
  providedIn: 'root',
})
export class MeterGroupsService implements SelectMetersService {
  apiBaseUrl = '';
  endpoint = 'metergroups';

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getMetersList(metersGroupUID: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/${metersGroupUID}/meters`,
    );
  }
  getMeters(
    paging: any,
    search: string,
    metersGroupUID: string,
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/${metersGroupUID}/meters`,
      {
        params: {
          pageNumber: paging.pageNumber,
          pageSize: paging.pageSize,
          orderBy: paging.orderBy,
          isDescending: paging.isDescending,
          search: search,
        },
      },
    );
  }

  updateMeterGroupMetersList(
    meterGroupUID: string,
    meterUIDsToAdd: string[] = null,
    meterUIDsToRemove: string[] = null,
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/${meterGroupUID}/meters`,
      {
        meterUIDsToAdd: meterUIDsToAdd,
        meterUIDsToRemove: meterUIDsToRemove,
      },
    );
  }
  getMeterGroups(): Observable<IMeterGroupView[]> {
    return this.http.get<any>(`${this.apiBaseUrl}${this.endpoint}`, {});
  }

  addMeterGroup(MeterGroupUpdated: IMeterGroup): Observable<any> {
    if (!MeterGroupUpdated) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.post<any>(`${this.apiBaseUrl}${this.endpoint}`, {
      meterGroupName: MeterGroupUpdated.meterGroupName,
      parentMeterGroupUID: MeterGroupUpdated.parentMeterGroupUID,
    });
  }

  updateMeterGroup(MeterGroupUpdated: IMeterGroup): Observable<any> {
    if (!MeterGroupUpdated) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/${MeterGroupUpdated.meterGroupUID}`,
      {
        meterGroupName: MeterGroupUpdated.meterGroupName,
        parentMeterGroupUID: MeterGroupUpdated.parentMeterGroupUID,
      },
    );
  }

  deleteMeterGroup(deleteMeterGroupUID: string): Observable<any> {
    if (!deleteMeterGroupUID) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/${deleteMeterGroupUID}`,
    );
  }
}
