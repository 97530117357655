import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'xpw-form-lable',
  template: `
    <nz-form-item>
      <nz-form-control
        [nzHasFeedback]="nzHasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <div id="xpw-form-lable_container">
          <div id="xpw-form-lable-container_lable">
            {{ label }}
          </div>
          <div id="xpw-form-lable-container_value">
            <xpw-icon [icon]="icon"> </xpw-icon>{{ value }}
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      #xpw-form-lable_container {
        display: flex;
        padding: var(--Space-Padding-paddingXS, 8px)
          var(--Space-Padding-padding, 16px);
        flex-direction: column;
        align-items: start;
        gap: 10px;
        align-self: stretch;
        border-radius: var(--BorderRadius-borderRadiusLG, 3px);
        background: var(--Fill-colorFillTertiary, rgba(0, 0, 0, 0.04));
      }

      #xpw-form-lable-container_lable {
        color: gray;
      }
      #xpw-form-lable-container_value {
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwFormLableComponent),
      multi: true,
    },
  ],
})
export class XpwFormLableComponent extends AbstractInputComponent {
  @Input('label') label: string;
  @Input('icon') icon: string;
  @Input('hasFeedback') nzHasFeedback: boolean = false;
}
