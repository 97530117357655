import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  LogPublisher,
  LogConsole,
  LogLocalStorage,
  LogWebApi,
  LogLocalStorageBulkToApi,
} from './log-publishers';
import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class LogPublishersService {
  private http: HttpClient;

  constructor(
    handler: HttpBackend,
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    // to read the JSON file we are using the httpbackend handler so not to go throught the inteceptor
    this.http = new HttpClient(handler);
    // Build publishers arrays
    this.buildPublishers();
  }

  // Public properties
  publishers: LogPublisher[] = [];

  buildPublishers(): void {
    let logPub: LogPublisher;

    this.getLoggers().subscribe((response) => {
      for (let pub of response.filter((p) => p.isActive)) {
        switch (pub.loggerType) {
          case LoggerType.Console:
            logPub = new LogConsole();
            break;
          case LoggerType.LocalStorage:
            logPub = new LogLocalStorage();
            break;
          case LoggerType.WebApi:
            logPub = new LogWebApi(this.httpClient);
            break;
          case LoggerType.LocalStorageToWebApi:
            logPub = new LogLocalStorageBulkToApi(this.httpClient);
            break;
        }

        // Set location of logging
        logPub.location = pub.loggerLocation;

        // Add publisher to array
        this.publishers.push(logPub);
      }
    });
  }

  getLoggers(): Observable<LogPublisherConfig[]> {
    return this.http.get(this.getfile()).pipe(
      map((response: any) => response),
      catchError(this.handleError),
    );
  }
  handleError(error: HttpErrorResponse) {
    console.log(error.message);
    return throwError(() => error || 'Node.js server error');
  }

  getfile() {
    const PUBLISHERS_FILE = '/assets/log-publishers.json';
    let languagePath = '/' + this.locale;
    if(!environment.production){
      languagePath = ''
    }
    return window.location.origin + languagePath + PUBLISHERS_FILE;
  }
}


class LogPublisherConfig {
  loggerName: string;
  loggerType: LoggerType;
  loggerLocation: string;
  isActive: boolean;
}

enum LoggerType {
  Console,
  LocalStorage,
  WebApi,
  LocalStorageToWebApi,
}
