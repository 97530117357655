<div class="container">
  <div class="logo-activation-form">
    <div class="logo-activation-form-logo">
      <img src="assets/logo/logo-big.svg" />
    </div>
    <div class="logo-activation-form-slogan">
      <p>Smarter consumption for a brighter tomorrow.</p>
    </div>
  </div>

  <div class="activation-form-card">
    <div *ngIf="!(actUserForm$ | async)?.status" class="activation-form">
      <div class="title-activation-form">
        <h1 i18n>Registration</h1>
      </div>
      <div class="content-activation-form">
        <p>
          <span nz-icon nzType="xpw-outline:spinner"></span>
        </p>
      </div>
    </div>

    <div class="activation-form" [ngSwitch]="(actUserForm$ | async)?.status">
      <!-- Has error -->
      <ng-container *ngSwitchCase="userInvitationLinkStatus.ServiceError">
        <ng-container *ngTemplateOutlet="templateFormHasError"></ng-container>
      </ng-container>

      <!-- Expired Case -->
      <ng-container *ngSwitchCase="userInvitationLinkStatus.Expired">
        <ng-container *ngTemplateOutlet="templateFormHasError"></ng-container>
      </ng-container>

      <!-- Account Not Exist Anymore Case -->
      <ng-container
        *ngSwitchCase="userInvitationLinkStatus.AccountNotExistAnymore"
      >
        <ng-container *ngTemplateOutlet="templateFormHasError"></ng-container>
      </ng-container>

      <!-- Valid for Validation Case -->
      <ng-container *ngSwitchCase="userInvitationLinkStatus.Valid">
        <ng-container
          *ngTemplateOutlet="templateValidForValidationForm"
        ></ng-container>
      </ng-container>

      <!-- Account Registration Completed Case -->
      <ng-container
        *ngSwitchCase="userInvitationLinkStatus.AccountRegistrationSuccess"
      >
        <ng-container
          *ngTemplateOutlet="templateAccountActivatedForm"
        ></ng-container>
      </ng-container>

      <!-- Account Already Activated Case -->
      <ng-container
        *ngSwitchCase="userInvitationLinkStatus.AccountAlreadyActivated"
      >
        <ng-container
          *ngTemplateOutlet="templateAccountActivatedForm"
        ></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<!-- Templates -->
<ng-template #helpCenter>
  <div class="tooltip">
    <p>
      Contact us by email: <br />
      <a href="mailto:any@email.com">any&#64;email.com</a>
    </p>
    <p>
      Call us: <br />
      <a href="tel:+972341230000">+97 234 123 00 00</a>
    </p>
    <a (click)="helpCenterTooltipVisible = false"> Close </a>
  </div>
</ng-template>

<ng-template #templateFormHasError>
  <div class="title-activation-form">
    <h1 i18n>Registration</h1>
  </div>
  <div class="content-activation-form">
    <p>
      <span
        class="registration-icons"
        nz-icon
        nzType="xpw-outline:registration-faild"
      ></span>
    </p>
    <h3>Ooops!</h3>
    <p
      *ngIf="
        (actUserForm$ | async)?.status === userInvitationLinkStatus.ServiceError
      "
    >
      Service doesn't work!
    </p>
    <p
      *ngIf="
        (actUserForm$ | async)?.status === userInvitationLinkStatus.Expired
      "
    >
      Account is expired. Please get in touch with support.
    </p>
    <p
      *ngIf="
        (actUserForm$ | async)?.status ===
        userInvitationLinkStatus.AccountNotExistAnymore
      "
    >
      Account no longer exists. Please get in touch with support.
    </p>
  </div>
  <div class="bottom-xpw-buttons">
    <xpw-button type="primary" (click)="onLoginLinkClick()">Support</xpw-button>
    <xpw-button
      nz-popover
      nzPopoverTitle="Would you like any assistance?"
      [(nzPopoverVisible)]="helpCenterTooltipVisible"
      nzPopoverPlacement="top"
      nzPopoverTrigger="click"
      [nzPopoverContent]="helpCenter"
      id="help-center"
      type="link"
      i18n
    >
      <xpw-icon icon="question" />
      Help Center
    </xpw-button>
  </div>
</ng-template>

<ng-template #templateAccountActivatedForm>
  <div class="title-activation-form">
    <h1 i18n>Registration</h1>
  </div>
  <div class="content-activation-form">
    <p>
      <span
        class="registration-icons"
        nz-icon
        nzType="xpw-outline:registration-success"
      ></span>
    </p>
    <h3>Success!</h3>
    <p>Congratulations, you have successfully registered and can now log in.</p>
  </div>
  <div class="bottom-xpw-buttons">
    <xpw-button type="primary" (click)="onLoginLinkClick()">Login</xpw-button>
    <xpw-button
      nz-popover
      nzPopoverTitle="Would you like any assistance?"
      [(nzPopoverVisible)]="helpCenterTooltipVisible"
      nzPopoverPlacement="top"
      nzPopoverTrigger="click"
      [nzPopoverContent]="helpCenter"
      id="help-center"
      type="link"
      i18n
    >
      <xpw-icon icon="question" />
      Help Center
    </xpw-button>
  </div>
</ng-template>

<ng-template #templateValidForValidationForm>
  <div class="content-activation-form">
    <h1 i18n>Registration</h1>
    <p>
      You need to complete the registration process to gain access to the
      system.
    </p>
    <div class="steps-activation-form">
      <nz-steps [nzCurrent]="currentStep" nzSize="small">
        <nz-step
          [nzIcon]="currentStep == 0 ? 'xpw-outline:id' : 'xpw-outline:dot'"
          nzTitle="User Info"
        ></nz-step>
        <nz-step
          [nzIcon]="currentStep == 1 ? 'xpw-outline:locked' : 'xpw-outline:dot'"
          nzTitle="Password"
        ></nz-step>
        <nz-step
          [nzIcon]="currentStep == 2 ? 'xpw-outline:phone' : 'xpw-outline:dot'"
          nzTitle="Phone Verification"
        ></nz-step>
      </nz-steps>
    </div>

    <form nz-form [formGroup]="activationForm" [nzLayout]="'vertical'">
      <ng-container *ngIf="currentStep === 0">
        <xpw-form-lable
          formControlName="emailAddress"
          i18n-label
          label="Email Address / Username"
          icon="mail"
        />
        <xpw-form-input-text
          formControlName="firstName"
          i18n-placeholder
          placeholder="First Name"
          i18n-label
          label="First name"
        />
        <xpw-form-input-text
          formControlName="lastName"
          i18n-placeholder
          placeholder="Last Name"
          i18n-label
          label="Last Name"
        />
        <xpw-form-select
          formControlName="languageID"
          [options]="languages"
          i18n-label
          label="Language"
        />
      </ng-container>

      <ng-container *ngIf="currentStep === 1">
        <xpw-form-input-password
          [(passwordVisible)]="passwordVisible"
          [displayErrors]="false"
          formControlName="password"
          i18n-placeholder
          placeholder="Password"
          i18n-label
          label="Password"
        />
        <div class="errors-display-list">
          <div
            class="error-message"
            [class.dirty]="!activationForm.get('password')?.dirty"
            [class.invalid]="
              activationForm.get('password')?.errors?.['pswLength'] ||
              activationForm.get('password')?.errors?.['required']
            "
          >
            At least 8 characters
          </div>
          <div
            class="error-message"
            [class.dirty]="!activationForm.get('password')?.dirty"
            [class.invalid]="
              activationForm.get('password')?.errors?.['hasLowercase'] ||
              activationForm.get('password')?.errors?.['required']
            "
          >
            One lowercase letter
          </div>
          <div
            class="error-message"
            [class.dirty]="!activationForm.get('password')?.dirty"
            [class.invalid]="
              activationForm.get('password')?.errors?.['hasUppercase'] ||
              activationForm.get('password')?.errors?.['required']
            "
          >
            One uppercase letter
          </div>
          <div
            class="error-message"
            [class.dirty]="!activationForm.get('password')?.dirty"
            [class.invalid]="
              activationForm.get('password')?.errors?.['hasNumberOrSpecial'] ||
              activationForm.get('password')?.errors?.['required']
            "
          >
            One number or special character.
          </div>
          <div
            class="error-message"
            [class.dirty]="!activationForm.get('password')?.dirty"
            [class.invalid]="
              activationForm.get('password')?.errors?.['pswMaxLengthExceed'] ||
              activationForm.get('password')?.errors?.['required']
            "
          >
            Your password length must not exceed 64 characters
          </div>
        </div>
        <xpw-form-input-password
          [(passwordVisible)]="passwordVisible"
          [displayErrors]="true"
          formControlName="confirmPassword"
          i18n-placeholder
          placeholder="Confirm Password"
          i18n-label
          label="Confirm Password"
        />
      </ng-container>

      <ng-container *ngIf="currentStep === 2">
        <xpw-form-input-phone
          formControlName="mobilePhone"
          i18n-placeholder
          placeholder="Phone number"
          i18n-label
          label="Phone number"
          (ngModelChange)="phoneNumberChanged()"
        />
        <div id="activation-form-verification">
          <nz-radio-group
            *ngIf="canChooseOptionForVerify"
            formControlName="verifyViaCall"
          >
            <label nz-radio [nzValue]="false">Verifying code via SMS</label>
            <label nz-radio [nzValue]="true">Verifying code via call</label>
          </nz-radio-group>
          <ng-container *ngIf="!canChooseOptionForVerify">
            <p *ngIf="!activationForm.get('verifyViaCall')?.value">
              A 6-digit code has been sent to your number.
            </p>
            <p *ngIf="activationForm.get('verifyViaCall')?.value">
              We're calling you now with a 6-digit code. Please answer your
              phone.
            </p>
          </ng-container>
          <ng-container *ngIf="canChooseOptionForVerify">
            <p *ngIf="!activationForm.get('verifyViaCall')?.value">
              We'll send a code to your mobile phone that can be entered to
              verify your identity.
            </p>
            <p *ngIf="activationForm.get('verifyViaCall')?.value">
              A phone call will be initiated to provide the verification code
              for confirming your identity.
            </p>
            <xpw-button
              type="primary"
              [disabled]="!activationForm.get('mobilePhone').valid"
              (click)="sendOtc()"
            >
              <xpw-icon
                [icon]="
                  activationForm.get('verifyViaCall')?.value
                    ? 'phone'
                    : 'send-otc-sms'
                "
              ></xpw-icon>
              {{
                activationForm.get('verifyViaCall')?.value
                  ? 'Call Me'
                  : 'Send verification code'
              }}
            </xpw-button>
          </ng-container>
          <xpw-timer
            [timeInSeconds]="timeForTimer"
            *ngIf="timerIsActive"
          ></xpw-timer>
          <ng-container *ngIf="timerIsActive">
            <xpw-form-input-verification-code
              formControlName="otp"
              i18n-label
              label="Verification Code"
            />
            <xpw-button
              *ngIf="!canChooseOptionForVerify"
              type="link"
              i18n
              (click)="sendOtc()"
              [disabled]="isRecallButtonDisabled"
            >
              {{
                activationForm.get('verifyViaCall')?.value
                  ? 'Recall me'
                  : 'Resend code'
              }}
            </xpw-button>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="bottom-xpw-buttons">
    <label *ngIf="currentStep === 0" nz-checkbox [(ngModel)]="isAgreed">
      I agree to ExpertPower's Privacy Policy and Terms of Service
    </label>
    <xpw-button
      type="primary"
      (click)="nextStep()"
      *ngIf="currentStep < 2"
      [disabled]="isAgreed ? currentStepHasError() : true"
      >Continue</xpw-button
    >
    <xpw-button
      type="primary"
      i18n
      [disabled]="!(activationForm.valid && isAgreed)"
      (click)="submitForm()"
      *ngIf="currentStep === 2"
      [isLoading]="isLoading()"
      >Submit</xpw-button
    >
    <xpw-button type="default" (click)="prevStep()" *ngIf="currentStep > 0"
      >Back</xpw-button
    >
    <xpw-button
      nz-popover
      nzPopoverTitle="Would you like any assistance?"
      [(nzPopoverVisible)]="helpCenterTooltipVisible"
      nzPopoverPlacement="top"
      nzPopoverTrigger="click"
      [nzPopoverContent]="helpCenter"
      id="help-center"
      type="link"
      i18n
    >
      <xpw-icon icon="question" />
      Help Center
    </xpw-button>
  </div>
</ng-template>
