import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@shared/xpw-ui-kit/form/abstract-input.component';
import { Store } from '@ngrx/store';
import { selectCountryTimezone } from 'src/app/store/location/location.selector';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { Subscription, withLatestFrom } from 'rxjs';

@Component({
  selector: 'xpw-form-select-timezone',
  template: `
    <nz-form-item>
      <nz-form-label *ngIf="label"
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}</nz-form-label
      >
      <nz-form-control
        [nzHasFeedback]="hasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-select
          i18n-placeholder
          nzPlaceHolder="Select Time zone"
          nzShowSearch
          nzSuffixIcon="xpw-outline:{{ icon }}"
          [disabled]="disabledInput"
          [nzMode]="mode"
          [nzOptions]="options"
          [nzMaxTagCount]="10"
          [(ngModel)]="value"
          (blur)="onTouched()"
          (ngModelChange)="selectChange($event)"
        >
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwFormTimezoneComponent),
      multi: true,
    },
  ],
})
export class XpwFormTimezoneComponent
  extends AbstractInputComponent
  implements OnInit, OnDestroy
{
  @Input('options') options: any[]; // ['electricity', 'water', 'gas'];

  @Input('label') label: string;
  @Input('disabled') disabledInput: boolean = false;
  @Input('icon') icon: string = 'down';
  @Input('mode') mode: any = 'default'; // 'multiple' | 'tags' | 'default'
  @Input() hasFeedback: boolean = true;

  @Output() onSelectedChange = new EventEmitter();

  Subscriptions$: Subscription[] = [];
  selectChange(id: any) {
    // console.log(event);
    //    this.store.dispatch(setCountry({ countryId: id }));
    this.onSelectedChange.emit(event);
  }

  constructor(
    private store: Store,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.Subscriptions$.push(
      this.store.select(selectCountryTimezone).subscribe((timezone) => {
        this.options = GlobalFunctions.convertToOptions(timezone, 'id', 'id');
      }),
    );
  }

  ngOnDestroy(): void {
    this.Subscriptions$.forEach((sub) => sub.unsubscribe());
  }
}
