import { Component, Input } from '@angular/core';
import { NzTSType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'xpw-icon',
  template: ` <span
    nz-icon
    nz-tooltip
    [nzTooltipTitle]="iconTooltip"
    [nzType]="icon ? 'xpw-' + type + ':' + icon : null"
    [ngClass]="isPadding ? '' : 'no_padding'"
    style="{{ color ? 'color:' + color + ';' : null }}"
  >
  </span>`,
  styleUrls: ['./xpw-icon.component.less'],
})
export class XpwIconComponent {
  @Input() icon: string | null = null; //change  default value
  @Input() type: string = 'outline';
  @Input() isPadding: boolean = true;
  @Input() color: string | null = null;
  @Input() iconTooltip: string | NzTSType | null = null;
}
