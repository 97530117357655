import { ErrorHandler, NgModule } from '@angular/core';
import { EnsureModuleLoadedOnceGuard } from './guards/ensure-module-loaded-once.guard';
import { LayoutModule } from './layout/layout.module';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { BrowserModule } from '@angular/platform-browser';
import { LogPublishersService } from './log/log-publishers.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EventInterceptor } from './services/interceptors/event.interceptor';
import { EventBusService } from './services/interceptors/event-bus.service';

import { PopupService } from './services/popup/popup.modal.service';
import { DrawService } from './services/popup/drawer.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { OverlayModule } from './components/overlay/overlay.module';
import { GlobalErrorHandler } from './services/interceptors/error-handler.service';
import { IdleTimeoutService } from './services/idle-timeout/idle-timeout.service';
import { TokenRefreshService } from './services/refresh-token/refresh-token.service';

@NgModule({
  imports: [
    OverlayModule,
    LayoutModule,
    BrowserModule,
    NzButtonModule,
    NzModalModule,
    NzNotificationModule,
  ],
  exports: [LayoutModule, OverlayModule],
  declarations: [NotFoundComponent],
  providers: [
    LogPublishersService,
    PopupService,
    NzDrawerService,
    DrawService,
    EventBusService,
    IdleTimeoutService,
    TokenRefreshService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EventInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
}
