import { ILocationState } from './location.interface';

export const initialLocationState: ILocationState = {
  allCountries: [],

  showCountries: true,
  countries: [],
  defaultCountryId: null,
  selectedCountry: null,

  showStates: false,
  states: [],
  selectedState: null,

  cities: [],
  selectedCity: null,

  CountryTimzone: [],
  selectedTimezoneID: '',
};
