// src/app/services/action.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingURL } from '@core/components/main-nav/main-nav.state';

export enum ActionType {
  Redirect = 1,
}

export interface Action {
  actionType: ActionType;
  value: string;
  step?: number;
}

enum RedirectValue {
  'ImportHistory' = RoutingURL.importHistory,
}

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  constructor(private router: Router) {}

  executeActions(actions: Action[]): void {
    // Sort actions by 'step' to ensure correct order of execution
    const sortedActions = actions.sort((a, b) => a.step - b.step);

    // Execute each action in order
    sortedActions.forEach((action) => {
      switch (action.actionType) {
        case ActionType.Redirect:
          this.handleRedirect(action.value);
          break;
        default:
          console.warn('Unknown action type:', action.actionType);
      }
    });
  }

  private handleRedirect(url: string): void {
    this.router.navigate([RedirectValue[url]]);
  }
}
