import { createAction, props } from '@ngrx/store';
import { IPagingRequest, IPagingResponse } from '@shared/utility/global-enums';
import { SelectMetersState } from './select-meters.interface';

export enum SelectMetersActionType {
  getMeters = '[Select Meters] Get Meters',
  getMetersSuccess = '[Select Meters] Get Meters Success',
  getMetersFailure = '[Select Meters] Get Meters Failure',
  setSelectMetersService = '[Select Meters] Set Select Meters Service',

  setPagingRequest = '[Select Meters] Set Paging Request',
  setSearchText = '[Select Meters] Set Search Text',
  setSelectAllMeters = '[Select Meters] Set Select All Meters',

  setSelectedMeters = '[Select Meters] Set Selected Meters',

  resetSelectMeters = '[Select Meters] Reset Select Meters',
  updateSelectedMetersSuccess = '[Select Meters] Update Selected Meters Success',
}

export const setSelectMetersService = createAction(
  SelectMetersActionType.setSelectMetersService,
  props<{ serviceId: 'AllMeters' | 'MeterGroups'; propertyData: any | null }>(), // serviceId represents the identifier for service selection
);
export const getMeters = createAction(SelectMetersActionType.getMeters);
export const getMetersSuccess = createAction(
  SelectMetersActionType.getMetersSuccess,
  props<{ meters: any }>(),
);

export const getMetersFailure = createAction(
  SelectMetersActionType.getMetersFailure,
  props<{ error: any }>(),
);

export const setPagingRequest = createAction(
  SelectMetersActionType.setPagingRequest,
  props<{ pageNumber: number }>(), // pagingRequest represents the paging request
);

export const setSearchText = createAction(
  SelectMetersActionType.setSearchText,
  props<{ search: string }>(), // search represents the search text
);

export const setSelectAllMeters = createAction(
  SelectMetersActionType.setSelectAllMeters,
);

export const setSelectedMeters = createAction(
  SelectMetersActionType.setSelectedMeters,
  props<{ selectedMeters: any[] }>(),
);

export const resetSelectMeters = createAction(
  SelectMetersActionType.resetSelectMeters,
);

export const updateSelectedMetersSuccess = createAction(
  SelectMetersActionType.updateSelectedMetersSuccess,
);
