import { Injectable } from '@angular/core';
import {
  HubConnectionBuilder,
  HubConnection,
  HttpTransportType,
} from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import {
  NotificationService,
  ToastMessage,
} from '../notifications/notifications.service';
import { CurrentUserFlowService } from '../current-user/current-user.service';

@Injectable({ providedIn: 'root' })
export class SignalrService {
  apiBaseWS = '';

  constructor(
    private notificationService: NotificationService,
    private currentUserService: CurrentUserFlowService,
  ) {
    this.apiBaseWS = environment.apiBaseWS;
    this.currentUserService.accessToken$.subscribe({
      next: (accessToken) => {
        console.log('created new accessToken');
        this.startConnection(accessToken);
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // console.log('Completed');
      },
    });
  }
  private hubConnection: HubConnection;

  private startConnection(token: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.apiBaseWS}notifications`, {
        accessTokenFactory: () => token,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();
    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started');
        this.askServerListener();
      })
      .catch((err) => console.log('Error while starting connection: ' + err));
  }

  askServerListener() {
    this.hubConnection.on('SendMessageAsync', (data: any) => {
      console.log('SendMessageAsync TO ALL USERS: ', data);
    });

    this.hubConnection.on('SendMessageAsyncToUser', (data: ToastMessage) => {
      console.log('SendMessageAsyncToUser TO Current USER SignalR: ', data);
      this.notificationService.processToast(data);
    });

    this.hubConnection.onclose((error) => {
      console.log('Connection closed', error);
      this.currentUserService.getSignalAccessToken();
    });
  }
}
