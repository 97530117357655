import { Component, OnInit } from '@angular/core';
import { CurrentUserFlowService } from '@core/services/current-user/current-user.service';
import { IdleTimeoutService } from '@core/services/idle-timeout/idle-timeout.service';
import { SignalrService } from '@core/services/signalR/signalR.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private currentUserFlowService: CurrentUserFlowService,
    private idleTimeoutService: IdleTimeoutService,
    private signalRService: SignalrService, // this is the service needs to be injected to initialize the connection
  ) {
    this.currentUserFlowService.fetchCurrentUserData();
  }
}
