import { Injectable, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { interval, Subscription } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { CurrentUserFlowService } from '../current-user/current-user.service';

@Injectable({
  providedIn: 'root',
})
export class TokenRefreshService implements OnDestroy {
  private refreshInterval$: Subscription;
  private readonly refreshTokenInterval = 10 * 60 * 1000; // 10 minutes

  constructor(
    private msalService: MsalService,
    private currentUserFlowService: CurrentUserFlowService,
  ) {
    this.startTokenRefresh();
  }

  startTokenRefresh(): void {
    this.refreshInterval$ = interval(this.refreshTokenInterval).subscribe(
      () => {
        this.msalService
          .acquireTokenSilent({
            scopes: environment.ApiScopes,
          })
          .subscribe({
            next: (response: AuthenticationResult) => {
              console.log('Token refreshed');
            },
            error: (error) => {
              this.currentUserFlowService.logout();
            },
          });
      },
    );
  }

  ngOnDestroy(): void {
    if (this.refreshInterval$) {
      this.refreshInterval$.unsubscribe();
    }
  }
}
