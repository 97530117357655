import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IMeterState } from './meters.state';

// Define a feature selector to select the ISysMeter state
const selectMeterState = createFeatureSelector<IMeterState>('meters');

export const selectMeterList = createSelector(
  selectMeterState,
  (state) => state.meters,
);
export const selectMeterNameList = createSelector(
  selectMeterState,
  (state) => state.meterNameList,
);

export const selectMeterListPagingRequest = createSelector(
  selectMeterState,
  (state) => state.pagingRequest,
);

export const selectSelectedMeter = createSelector(
  selectMeterState,
  (state) => state.selectedMeter,
);

export const selectIsMeterNew = createSelector(
  selectMeterState,
  (state) => state.isNewMeter,
);

export const selectValidationErrorMeter = createSelector(
  selectMeterState,
  (state) => state.ValidationErrors,
);

export const selectReplacementHistory = createSelector(
  selectMeterState,
  (state) => state.replacementHistory,
);

export const selectSelectedReplacement = createSelector(
  selectMeterState,
  (state) => state.replacementHistory.selectedReplacement,
);

export const selectIsNewReplacement = createSelector(
  selectMeterState,
  (state) => state.replacementHistory.isNewReplacement,
);

export const selectMeterFilter = createSelector(
  selectMeterState,
  (state) => state.filter,
);
export const selectMeterSearch = createSelector(
  selectMeterState,
  (state) => state.search,
);
export const selectFilterOptions = createSelector(
  selectMeterState,
  (state) => state.filterOptions,
);

export const selectDrawerMeterState = createSelector(
  selectMeterState,
  (state) => state.drawersState.meterEditDrawer,
);

export const selectDrawerReplacementEditState = createSelector(
  selectMeterState,
  (state) => state.drawersState.replacementEditDrawer,
);
