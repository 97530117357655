import { createReducer, on } from '@ngrx/store';
import * as MeterGroupActions from './meter-group.actions';
import { initialMeterGroupState } from './meter-group.state';
import { IMeterGroupView } from './meter-group.interface';

// IMeterGroupState interface
export const MeterGroupReducer = createReducer(
  initialMeterGroupState,
  on(MeterGroupActions.selectMeterGroup, (state, { meterGroupUID }) => {
    // Helper function to recursively search for a meter group in the tree
    function findMeterGroupByUID(
      groups: IMeterGroupView[],
      uid: string,
    ): IMeterGroupView | null {
      for (const group of groups) {
        if (group.meterGroupUID === uid) {
          return group;
        }
        if (group.children && group.children.length > 0) {
          const found = findMeterGroupByUID(group.children, uid);
          if (found) {
            return found;
          }
        }
      }
      return null;
    }

    return {
      ...state,
      newMeterGroup: false,
      drawersState: {
        addMetersDrawer: false,
        editCreateMeterGroupDrawer: false,
      },
      selectedMeterGroup: {
        ...initialMeterGroupState.selectedMeterGroup,
        ...findMeterGroupByUID(state.meterGroups, meterGroupUID),
      },
    };
  }),
  on(MeterGroupActions.createNewMeterGroup, (state) => ({
    ...state,
    newMeterGroup: true,
    drawersState: {
      addMetersDrawer: false,
      editCreateMeterGroupDrawer: false,
    },
    selectedMeterGroup: {
      ...initialMeterGroupState.selectedMeterGroup,
    },
  })),
  // on(MeterGroupActions.updateSelectedMeterUIDs, (state, { meterUIDs }) => ({
  //   ...state,
  //   selectedMeterGroup: {
  //     ...state.selectedMeterGroup,
  //     meterUIDs: meterUIDs,
  //   },
  // })),

  on(
    MeterGroupActions.setLastChangedCreatedMeterGroup,
    (state, { meterGroup }) => ({
      ...state,
      lastGroupUpdated: meterGroup,
    }),
  ),
  on(
    MeterGroupActions.createChildNewMeterGroup,
    (state, { parentMeterGroupUID }) => ({
      ...state,
      newMeterGroup: true,
      drawersState: {
        addMetersDrawer: false,
        editCreateMeterGroupDrawer: false,
      },
      selectedMeterGroup: {
        ...initialMeterGroupState.selectedMeterGroup,
        parentMeterGroupUID: parentMeterGroupUID,
      },
    }),
  ),
  // on(MeterGroupActions.getMetersListSuccess, (state, { meterUIDs }) => ({
  //   ...state,
  //   selectedMeterGroup: { ...state.selectedMeterGroup, meterUIDs: meterUIDs },
  // })),

  on(MeterGroupActions.setUpdateMeterGroupSuccess, (state) => ({
    ...state,
    drawersState: {
      addMetersDrawer: false,
      editCreateMeterGroupDrawer: true,
    },
  })),
  on(
    MeterGroupActions.setNewMeterGroupSuccess,
    (state, { MeterGroupCreated }) => ({
      ...state,
      meterGroups: [...state.meterGroups, MeterGroupCreated],
    }),
  ),

  on(MeterGroupActions.getMeterGroupListSuccess, (state, { meterGroups }) => ({
    ...state,
    meterGroups: meterGroups,
    filteredMetersGroup: meterGroups,
  })),
  on(MeterGroupActions.validationError, (state, { ValidationErrors }) => {
    return {
      ...state,
      ValidationErrors,
    };
  }),
  on(MeterGroupActions.showErrors, (state, { message }) => {
    return {
      ...state,
      message,
    };
  }),
  on(MeterGroupActions.resetMeterGroupState, (state) => ({
    ...initialMeterGroupState,
  })),
  on(MeterGroupActions.searchChange, (state, { search }) => ({
    ...state,
    searchText: search,
    filteredMetersGroup: state.meterGroups.filter((item) =>
      item.meterGroupName.toUpperCase().includes(search.toUpperCase()),
    ),
  })),
  on(MeterGroupActions.searchChange, (state, { search }) => {
    let matchCount = 0;

    // Function to reset the 'selected' property to false for all groups
    const resetSelection = (groups: IMeterGroupView[]): IMeterGroupView[] => {
      return groups.map((group) => ({
        ...group,
        selected: false,
        children: resetSelection(group.children),
      }));
    };

    // Filter groups and count matches simultaneously
    const filterGroups = (
      groups: IMeterGroupView[],
      searchTerm: string,
    ): IMeterGroupView[] => {
      return groups
        .map((group) => {
          // Recursively filter children
          const filteredChildren = filterGroups(group.children, searchTerm);

          // Check if the current group name matches the search term
          const groupMatches = group.meterGroupName
            .toUpperCase()
            .includes(searchTerm.toUpperCase());

          // If the group matches, increase the match count
          if (groupMatches) {
            matchCount += 1;
          }

          // Include the group if it matches or if it has matching children
          if (groupMatches || filteredChildren.length > 0) {
            return {
              ...group,
              selected: groupMatches, // Mark selected = true if this group matches
              children: filteredChildren, // Only include filtered children
            };
          }

          // Exclude the group if it doesn't match and has no matching children
          return null;
        })
        .filter((group) => group !== null); // Filter out null groups
    };
    // If search is an empty string or null, reset selection for all groups
    const filteredGroups = search
      ? filterGroups(state.meterGroups, search)
      : resetSelection(state.meterGroups);
    return {
      ...state,
      searchText: search,
      filteredMetersGroup: filteredGroups,
      searchResultCount: matchCount, // Set the count of matched results
    };
  }),
  on(MeterGroupActions.setMeterUIDsToAdd, (state, { meterUIDs }) => ({
    ...state,
    selectedMeterGroup: {
      ...state.selectedMeterGroup,
      meterUIDsToAdd: meterUIDs,
    },
  })),
  on(MeterGroupActions.setMeterUIDsToRemove, (state, { meterUIDs }) => ({
    ...state,
    selectedMeterGroup: {
      ...state.selectedMeterGroup,
      meterUIDsToRemove: meterUIDs,
    },
  })),
  on(MeterGroupActions.setSaveButton, (state, { saveButton }) => ({
    ...state,
    saveButton,
  })),
  on(
    MeterGroupActions.updateMeterGroupMetersListSuccess,
    (state, { response }) => ({
      ...state,
      meterGroups: state.meterGroups.map((group: any) => {
        return group.meterGroupUID === state.selectedMeterGroup.meterGroupUID
          ? {
              ...group,
              metersCount: calculateMeterCount(group.metersCount, response),
            }
          : group;
      }),
      filteredMetersGroup: state.filteredMetersGroup.map((group: any) => {
        return group.meterGroupUID === state.selectedMeterGroup.meterGroupUID
          ? {
              ...group,
              metersCount: calculateMeterCount(group.metersCount, response),
            }
          : group;
      }),
      selectedMeterGroup: {
        ...state.selectedMeterGroup,
        meterUIDsToAdd: [],
        meterUIDsToRemove: [],
        metersCount: calculateMeterCount(
          state.selectedMeterGroup.metersCount,
          response,
        ),
        meterUpdate: getUpdateMessage(response),
      },
      drawersState: {
        ...state.drawersState,
        addMetersDrawer: true,
      },
    }),
  ),
);

function calculateMeterCount(current, response): number {
  if (response.metersAdded) {
    current += response.metersAdded;
  }
  if (response.metersRemoved) {
    current -= response.metersRemoved;
  }
  return current;
}
function getUpdateMessage(response): string {
  const messages = [];
  if (response.metersAdded) {
    messages.push(`${response.metersAdded} meters added`);
  }
  if (response.metersRemoved) {
    messages.push(`${response.metersRemoved} meters removed`);
  }
  return messages.join(', ');
}
