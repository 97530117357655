import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'xpw-form-input-password',
  template: `
    <nz-form-item>
      <nz-form-label
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
        </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="nzHasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-input-group [nzSuffix]="suffixTemplate">
          <input
            [type]="passwordVisible ? 'text' : 'password'"
            nz-input
            [value]="value"
            [placeholder]="placeholder"
            [(ngModel)]="value"
            [disabled]="readonly"
          />
        </nz-input-group>
        <ng-template #suffixTemplate>
          <span
            nz-icon
            [nzType]="passwordVisible ? 'xpw-outline:eye-invisible' : 'eye'"
            (click)="togglePasswordVisibility()"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      .ant-form-vertical nz-form-label.ant-form-item-label {
        padding: 0 0 4px 0;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputPasswordComponent),
      multi: true,
    },
  ],
})
export class XpwInputPasswordComponent extends AbstractInputComponent {
  @Input('label') label: string;
  @Input('icon') icon: string;
  @Input('passwordVisible') passwordVisible = false;
  @Input('hasFeedback') nzHasFeedback: boolean = true;
  @Output() passwordVisibleChange = new EventEmitter<boolean>();

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
    this.passwordVisibleChange.emit(this.passwordVisible);
  }
}
