import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { BaseDrawerTemplateComponent } from '@core/services/popup/base-drawer-template.component';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  IChannel,
  IChannelConfigurations,
  UsageTypeID,
  UsageTypeIDTitle,
} from '@features/organizations/store/organizations.interface';
import {
  addChannelConfigurations,
  editChannelConfigurations,
} from '@features/organizations/store/organizations.actions';
import {
  selectChannelConfigurations,
  selectSelectedChannelConfigurations,
} from '@features/organizations/store/organizations.selector';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { CustomVlidators } from '@shared/utility/custom-validators';

@Component({
  selector: 'app-channel-configuration-popup',
  template: `<ng-template #componentTemplate let-drawerRef="drawerRef">
    <form nz-form [formGroup]="xpwForm" [nzLayout]="'vertical'">
      <div class="_form-content">
        <section>
          <xpw-form-input-text
            formControlName="channelConfigurationName"
            label="Name"
            placeholder="Name"
          />
        </section>
        <section>
          <ng-container formArrayName="mappings">
            <nz-table #smallTable nzSize="small" [nzShowPagination]="false">
              <thead>
                <tr>
                  <th style="width: 30%;">Channel</th>
                  <th style="width: 50%;">Usage Type</th>
                  <th style="width: 20%;"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let mapping of mappings.controls; let i = index"
                  [formGroupName]="i"
                >
                  <tr>
                    <td>
                      <xpw-form-input-text
                        [hasFeedback]="false"
                        formControlName="channel"
                        placeholder="Channel"
                      />
                    </td>
                    <td>
                      <xpw-form-select
                        [hasFeedback]="false"
                        formControlName="usageTypeID"
                        placeholder="Select Usage Type"
                        [options]="_usageTypeOptions"
                      />
                    </td>
                    <td>
                      <xpw-button
                        nz-button
                        type="link"
                        (click)="removeMapping(i); xpwForm.markAsDirty()"
                      >
                        <xpw-icon icon="minus"></xpw-icon>
                      </xpw-button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </ng-container>
          <xpw-button
            [disabled]="addMAppingsDisabled"
            nz-button
            type="default"
            (click)="addMapping()"
            >Add Mapping</xpw-button
          >
        </section>
      </div>
      <div class="_controls">
        <xpw-button
          nz-button
          (click)="saveChannelConfiguration()"
          type="primary"
          [disabled]="
            !xpwForm.valid ||
            xpwForm.pristine ||
            xpwForm.get('mappings').value.length === 0
          "
          i18n="@@saveChanges"
        >
          <xpw-icon icon="save" />
          Save Changes
        </xpw-button>
        <xpw-button
          (click)="closeDrawer(drawerRef)"
          nz-button
          type="text"
          i18n="@@cancel"
        >
          Cancel
        </xpw-button>
      </div>
    </form>
  </ng-template> `,
  styleUrls: ['./organization-channel-configuration-popup.component.less'],
})
export class XpwChannelConfigurationPopupComponent
  extends BaseDrawerTemplateComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('myTemplate') template: TemplateRef<any>;

  subscriptions = new Subscription();
  _usageTypeOptions: any[] = [];
  addMAppingsDisabled: boolean = false;
  listChannelConfigurationNames: string[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this._usageTypeOptions = GlobalFunctions.convertEnumToOptions(
      UsageTypeID,
      UsageTypeIDTitle,
    );
    this.xpwForm = this.initForm();

    this.subscriptions.add(
      this.store
        .select(selectSelectedChannelConfigurations)
        .subscribe((channelConfiguration) => {
          if (channelConfiguration) {
            this.updateForm(channelConfiguration);
          }
        }),
    );

    this.subscriptions.add(
      this.store
        .select(selectChannelConfigurations)
        .subscribe((channelConfigurationList) => {
          this.listChannelConfigurationNames =
            channelConfigurationList.map(
              (value) => value.channelConfigurationName,
            ) ?? [];
        }),
    );

    // for validation duplicate names
    this.xpwForm
      .get('channelConfigurationName')
      .valueChanges.subscribe((value) => {
        const isDuplicate = this.listChannelConfigurationNames.includes(value);
        if (isDuplicate) {
          this.xpwForm
            .get('channelConfigurationName')
            .setErrors({ duplicateName: true });
        } else {
          this.xpwForm.get('channelConfigurationName').setErrors(null);
        }
      });

    // for validation mapping
    this.subscriptions.add(
      this.xpwForm.get('mappings').valueChanges.subscribe((value) => {
        this._usageTypeOptions = GlobalFunctions.convertEnumToOptions(
          UsageTypeID,
          UsageTypeIDTitle,
          value
            .filter((item) => item.usageTypeID !== null)
            .map((item) => item.usageTypeID as number),
        );

        // can't add channels if empty list
        this.addMAppingsDisabled =
          this._usageTypeOptions.length < 1 ? true : false;
        let duplicatedChannels = this.findDuplicateChannelIndices(value);
        if (duplicatedChannels.length > 0) {
          duplicatedChannels.forEach((item) => {
            this.xpwForm
              .get(`mappings.${item}.channel`)
              ?.setErrors({ notUniqueChannel: true });
          });
        }
        //
      }),
    );
  }

  findDuplicateChannelIndices(data: IChannel[]): number[] {
    const channelMap = new Map<string, number[]>();

    // Loop through the data to populate the map with channels and their indices
    data.forEach((item, index) => {
      if (item.channel.trim() === '') return; // Skip empty channel names

      if (!channelMap.has(item.channel)) {
        channelMap.set(item.channel, [index]);
      } else {
        channelMap.get(item.channel)!.push(index);
      }
    });

    // Get the indices of all duplicate channels
    const duplicateIndices = Array.from(channelMap.values())
      .filter((indices) => indices.length > 1)
      .flat();

    return duplicateIndices;
  }

  ngAfterViewInit() {}

  initForm(): FormGroup {
    return this.fb.group({
      channelConfigurationUID: [null],
      channelConfigurationName: ['', Validators.required],
      mappings: this.fb.array([]),
    });
  }

  get mappings(): FormArray {
    return this.xpwForm.get('mappings') as FormArray;
  }

  createMapping(mapping?: IChannel): FormGroup {
    return this.fb.group({
      usageTypeID: [mapping ? mapping.usageTypeID : null, Validators.required],
      channel: [mapping ? mapping.channel : '', Validators.required],
    });
  }

  addMapping(): void {
    this.mappings.push(this.createMapping());
  }

  removeMapping(index: number): void {
    this.mappings.removeAt(index);
  }

  updateForm(value: IChannelConfigurations): void {
    this.xpwForm.patchValue({
      channelConfigurationUID: value.channelConfigurationUID,
      channelConfigurationName: value.channelConfigurationName,
    });

    this.mappings.clear();
    value.mappings.forEach((mapping) => {
      this.mappings.push(this.createMapping(mapping));
    });
    console.log(this.xpwForm.value);
  }

  saveChannelConfiguration() {
    if (this.xpwForm.get('channelConfigurationUID').value === null) {
      this.store.dispatch(
        addChannelConfigurations({ channelConfiguration: this.xpwForm.value }),
      );
    } else {
      this.store.dispatch(
        editChannelConfigurations({ channelConfiguration: this.xpwForm.value }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
