import {
  Component,
  Input,
  signal,
  WritableSignal,
  inject,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'xpw-checkbox-group',
  template: `
    <nz-checkbox-group
      [ngModel]="optionsSignal()"
      (ngModelChange)="emitValue()"
      [ngClass]="getClass()"
    >
    </nz-checkbox-group>
  `,
  styleUrls: ['./xpw-checkbox.component.less'],
})
export class XpwCheckboxComponent implements OnChanges {
  optionsSignal: WritableSignal<CheckboxOptionType[]> = signal([]);

  // Use Input property as a setter to initialize the signal
  @Input() set options(value: CheckboxOptionType[]) {
    this.optionsSignal.set(value);
  }
  @Input() oneColumn: boolean = false;
  // This will allow parent components to listen for changes in the signal
  @Output() optionsChange = new EventEmitter<CheckboxOptionType[]>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      // Initialize or update the signal whenever input changes
      this.optionsSignal.set(changes['options'].currentValue);
    }
  }
  getClass(): string {
    return this.oneColumn ? 'one-column' : 'two-column';
  }

  emitValue(): void {
    this.optionsChange.emit(this.optionsSignal());
  }
}

export interface CheckboxOptionType {
  label: string;
  value: string | number;
  checked?: boolean;
  disabled?: boolean;
}
