import { Component, OnInit, Input } from '@angular/core';
import { Role } from '@features/user/user-store/user.interface';
import { NzButtonType } from 'ng-zorro-antd/button';

@Component({
  selector: 'xpw-dropdown',
  template: `
    <button
      nz-button
      [nzType]="type"
      nz-dropdown
      [nzDropdownMenu]="menu4"
      [nzSize]="'large'"
      nzTrigger="click"
      (nzVisibleChange)="visibleChange($event)"
      [ngClass]="type == 'default' ? 'transparent' : ''"
    >
      <ng-content />
      <span nz-icon nzType="down" [ngClass]="iconTrigger ? '' : 'up'"></span>

      <nz-dropdown-menu #menu4="nzDropdownMenu">
        <ul nz-menu>
          <ng-container *ngFor="let item of dropdownItems">
            <ng-container *appDisplayForRoles="item.roles">
              <li
                nz-menu-item
                (click)="item.action()"
                *ngIf="item.value !== 'divider'"
              >
                <xpw-icon [icon]="item.icon" />

                {{ item.label }}
              </li>

              <!-- <li nz-menu-divider *ngIf="item.value == 'divider'"></li>-->
              <nz-divider
                *ngIf="item.value == 'divider'"
                [nzText]="item.label"
              ></nz-divider>
            </ng-container>
          </ng-container>
        </ul>
      </nz-dropdown-menu>
    </button>
  `,
  styleUrls: ['./xpw-dropdown.component.less'],
})
export class XpwDropDownComponent implements OnInit {
  @Input() dropdownItems: {
    label: string;
    value: string;
    icon?: string;
    roles?: Role[];
    action?: () => void | undefined;
  }[] = [];
  @Input() type: NzButtonType = 'default';
  iconTrigger: boolean = true;
  visibleChange = (status: boolean) => {
    console.log(status);
    this.iconTrigger = !status;
  };
  constructor() {}

  ngOnInit() {}
}
