import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputBooleanComponent } from '../abstract-input-boolean.component';
import { NzTSType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'xpw-form-input-checkbox',
  template: `
    <nz-form-control
      [nzHasFeedback]="true"
      [nzValidateStatus]="errorStatus()"
      [nzErrorTip]="getErrorLabel()"
    >
      <label nz-checkbox [(nzChecked)]="value" [nzDisabled]="isDisabled"
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
        <xpw-icon
          [iconTooltip]="suffixIconTooltipTitle"
          [icon]="suffixIcon"
        ></xpw-icon>
      </label>
    </nz-form-control>
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputCheckboxComponent),
      multi: true,
    },
  ],
})
export class XpwInputCheckboxComponent extends AbstractInputBooleanComponent {
  @Input() label: string = '';
  @Input() suffixIcon: string | null = null;
  @Input() suffixIconTooltipTitle: string | NzTSType | null = null;
  @Input() isDisabled: boolean = false;
}
