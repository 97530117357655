import {
  Injectable,
  TemplateRef,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { BehaviorSubject, Observable, skip } from 'rxjs';
import { LanguageService } from '@core/language/language.service';
import { BaseDrawerTemplateComponent } from './base-drawer-template.component';

@Injectable({
  providedIn: 'root',
})
export class DrawService {
  public viewContainerRef: ViewContainerRef;

  public setViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  private myTmpl = new BehaviorSubject<EncapsulatedDrawerTemplate>({
    template: null,
    footer: null,
    title: 'random title',
    width: '30vw',
  });
  myTemplate: Observable<EncapsulatedDrawerTemplate> =
    this.myTmpl.asObservable();

  // Track open drawers by a unique identifier (e.g., a string ID)
  private openDrawers = new Map<string, NzDrawerRef<string>>();
  private localeDirection: 'right' | 'left';
  constructor(
    private drawerService: NzDrawerService,
    private languageService: LanguageService,
  ) {
    this.myTemplate.pipe(skip(1)).subscribe((view) => {
      this.createComponentModal(view);
    });
    this.localeDirection = this.languageService.getLocaleDirection();
  }

  openTemplateInDraw(view: EncapsulatedDrawerTemplate): void {
    this.myTmpl.next(view);
  }

  openDrawer(
    _componentRef: any,
    title = 'Drawer Title',
    width = '400px',
  ): NzDrawerRef<string> | null {
    // Check if the drawer with this identifier is already open
    const DrawerID =
      _componentRef.name +
      _componentRef.length +
      title.replace(/\s/g, '').toLowerCase();
    if (this.openDrawers.has(DrawerID)) {
      console.log('A drawer with this identifier is already open.');
      return null;
    }

    const componentRef: ComponentRef<BaseDrawerTemplateComponent> =
      this.viewContainerRef.createComponent(_componentRef);

    componentRef.changeDetectorRef.detectChanges();
    let ev: EncapsulatedDrawerTemplate = {
      template: componentRef.instance.componentTemplate,
      title: title,
      width: width,
      onClose: componentRef.instance.onClose,
    };

    // Open the drawer and store it in the Map
    const drawerRef = this.createComponentModal(ev);

    this.openDrawers.set(DrawerID, drawerRef);

    // Handle when the drawer closes
    drawerRef.afterClose.subscribe(() => {
      console.log('Drawer closed, removing from open drawers');
      this.openDrawers.delete(DrawerID); // Remove drawer from Map when closed
    });

    return drawerRef;
  }

  createComponentModal(view: EncapsulatedDrawerTemplate): NzDrawerRef<string> {
    const drawerRef = this.drawerService.create({
      nzTitle: view.title,
      nzWidth: view.width,
      nzPlacement: this.localeDirection,
      nzContent: view.template,

      nzOnCancel: () =>
        new Promise((resolve) => {
          let _resolve = view.onClose ? view.onClose() : true;

          setTimeout(() => resolve(_resolve), 3);
        }),
      nzClosable: false,
    });

    drawerRef.afterOpen.subscribe(() => {
      console.log('Drawer opened');
    });

    return drawerRef;
  }
}

export interface EncapsulatedDrawerTemplate {
  template: TemplateRef<any>;
  title?: string;
  width?: string;
  close?: boolean;
  footer?: TemplateRef<any>;
  onClose?: () => Promise<boolean>;
}
