import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CurrentUserEffects } from 'src/app/store/current-user/current-user.effects';

// If we are authorized on the site, we should go to the default page.

export const redirectToDefaultPage: CanActivateFn = (
  route,
  state,
): Observable<boolean> => {
  let router = inject(Router);
  let authService = inject(CurrentUserEffects);

  authService.isAuthorized.subscribe((isAuthorized) => {
    if (isAuthorized) {
      router.navigate(['dashboard/energy-overview']);
      return of(false);
    } else return of(true);
  });
  return of(true);
};
