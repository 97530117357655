import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFileImportState } from './file-import.interface';

const selectFileImportState =
  createFeatureSelector<IFileImportState>('fileImport');

export const selectFileData = createSelector(
  selectFileImportState,
  (state: IFileImportState) => state.currentFileData,
);

export const selectPopupVisible = createSelector(
  selectFileImportState,
  (state: IFileImportState) => state.popupVisible,
);

export const selectResponse = createSelector(
  selectFileImportState,
  (state: IFileImportState) => state.response,
);

export const selectFileImportTypes = createSelector(
  selectFileImportState,
  (state: IFileImportState) => state.fileimportTypes,
);
export const selectSelectedFileImportType = createSelector(
  selectFileImportState,
  (state: IFileImportState) => state.selectedFileImportType,
);
