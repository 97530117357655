import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Signal,
  LOCALE_ID,
  Inject,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { selectLoggedIn } from 'src/app/store/current-user/current-user.selector';
import { tap } from 'rxjs/operators';
import { ILoginData } from 'src/app/store/current-user/current-user.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xpw-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.less'],
})
export class OverlayComponent implements OnInit, OnDestroy {
  enabled = true;
  timerId: number = null;
  timerHideId: number = null;
  isLoggedIn: Observable<ILoginData>;
  message: string;

  @Input() delay = 500;

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  getfile() {
    const BACKGROUND_IMAGE = '/assets/images/TEMP-Loading-image.jpg';
    let languagePath = '/' + this.locale;
    if (!environment.production) {
      languagePath = '';
    }
    return window.location.origin + languagePath + BACKGROUND_IMAGE;
  }

  ngOnInit() {
    this.isLoggedIn = this.store.select(selectLoggedIn);
    this.isLoggedIn.subscribe((res) => {
      setTimeout(() => {
        this.enabled = !res.isLoggedIn;
        this.message = res.message;
      }, this.delay);
    });
  }

  ngOnDestroy() {}
}
