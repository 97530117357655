import { Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { XpwSelectFormComponent } from './xpw-select-form.component';

@Component({
  selector: 'xpw-form-select-tooltip',
  template: `
    <nz-form-item>
      <nz-form-label *ngIf="label"
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="hasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-select
          nzShowSearch
          [nzSuffixIcon]="'xpw-outline:' + icon"
          [disabled]="disabledInput"
          [nzMode]="mode"
          [nzMaxTagCount]="countToShow"
          [nzMaxTagCount]="10"
          [(ngModel)]="value"
          (nzOnSearch)="onSearch($event)"
          (blur)="onTouched()"
          (ngModelChange)="selectChange($event)"
        >
          <nz-option
            [nzCustomContent]="true"
            *ngFor="let option of options"
            [nzLabel]="option.label"
            [nzValue]="option.value"
          >
            <span
              nz-tooltip
              [nzTooltipTitle]="tooltipContent"
              [nzTooltipPlacement]="tooltipPlacement"
              [nzTooltipTitleContext]="{
                tooltipData: tooltipData ?? option?.tooltipData,
              }"
              >{{ option.label }}
            </span>
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwSelectFormTooltipComponent),
      multi: true,
    },
  ],
})
export class XpwSelectFormTooltipComponent extends XpwSelectFormComponent {
  @Input('tooltipContent') tooltipContent: TemplateRef<any> | null = null;
  @Input('tooltipPlacement') tooltipPlacement: string = 'left'; // 'top', 'right' ...
  @Input('tooltipData') tooltipData: any | null = null;
}
