import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xpw-device-table',
  templateUrl: 'device-tree.component.html',
})
export class DeviceTreeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
