import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IOrganizationState } from './organizations.interface';

const selectOrganizationsState =
  createFeatureSelector<IOrganizationState>('organizations');

export const selectDepartmentUID = createSelector(
  selectOrganizationsState,
  (state) => state.departmentUID,
);
export const selectSelectedOrganizationUID = createSelector(
  selectOrganizationsState,
  (state) => state.selectedOrganizationUID,
);
export const selectOrganizations = createSelector(
  selectOrganizationsState,
  (state) => state.organizations,
);
export const selectOrganizationsFilterOptions = createSelector(
  selectOrganizationsState,
  (state) => state.filterOptions,
);
export const selectSelectedOrganization = createSelector(
  selectOrganizationsState,
  (state) => state.selectedOrganization,
);
export const selectOrganizationFilter = createSelector(
  selectOrganizationsState,
  (state) => state.filter,
);
export const selectOrganizationPagingRequest = createSelector(
  selectOrganizationsState,
  (state) => state.pagingRequest,
);
export const selectOrganizationSearch = createSelector(
  selectOrganizationsState,
  (state) => state.search,
);
export const selectOrganizationTypes = createSelector(
  selectOrganizationsState,
  (state) => state.organizationTypes,
);

export const selectNewOrganizationProp = createSelector(
  selectOrganizationsState,
  (state) => state.newOrganization,
);
export const isResultLoading = createSelector(
  selectOrganizationsState,
  (state) => state.organizations.isLoading,
);

export const selectOrganizationRequest = createSelector(
  selectOrganizationsState,
  (state) => state.organizationsRequestList,
);

export const selectOrganizationApiCredentials = createSelector(
  selectOrganizationsState,
  (state) => state.organizationApiCredentials,
);

export const selectDataSourceSettings = createSelector(
  selectOrganizationsState,
  (state) => state.dataSourcesSettingsList,
);

export const selectSelectedDataSourceSettings = createSelector(
  selectOrganizationsState,
  (state) => state.selectedDataSourceSettings,
);

export const selectChannelConfigurations = createSelector(
  selectOrganizationsState,
  (state) =>
    state.organizationChannelConfigurationList.channelConfigurationsList,
);

export const selectSelectedChannelConfigurations = createSelector(
  selectOrganizationsState,
  (state) => state.selectedChannelConfigurations,
);

export const selectChannelConfigurationDrawer = createSelector(
  selectOrganizationsState,
  (state) => state.drawersState.channelConfigurationDrawer,
);

export const selectChannelConfigurationsOrganizationUID = createSelector(
  selectOrganizationsState,
  (state) => state.organizationChannelConfigurationList.organizationUID,
);

export const selectOrganizationValidationErrors = createSelector(
  selectOrganizationsState,
  (state) => state.ValidationErrors,
);

export const selectDrawerDataSourceState = createSelector(
  selectOrganizationsState,
  (state) => state.drawersState.dataSourceSettingsDrawer,
);
export const selectDrawerRequestEditState = createSelector(
  selectOrganizationsState,
  (state) => state.drawersState.requestEditDrawer,
);

export const selectUploadOrganozationIconPopupVisible = createSelector(
  selectOrganizationsState,
  (state) => state.drawersState.uploadOrganizationIconPopupVisible,
);

export const selectDrawerOrganizationEditState = createSelector(
  selectOrganizationsState,
  (state) => state.drawersState.organizationEditDrawer,
);

export const selectDrawerApiCredentialsSettingsState = createSelector(
  selectOrganizationsState,
  (state) => state.drawersState.apiCredentialsSettingsDrawer,
);

export const selectSelectedDataSourceUtilityService = createSelector(
  selectOrganizationsState,
  (state) => state.selectedDataSourceSettings.utilityServiceID,
);
export const selectSelectedDataSourceorganizationUId = createSelector(
  selectOrganizationsState,
  (state) => state.dataSourcesSettingsList.organizationUId,
);

export const selectLastProcessingDate = createSelector(
  selectOrganizationsState,
  (state) => state.lastProcessing,
);

export const selectDeliveryMethods = createSelector(
  selectOrganizationsState,
  (state) => state.deliveryMethods,
);

export const selectDeliveryMethodsFileTransferTypes = createSelector(
  selectOrganizationsState,
  (state) => state.deliveryMethodsFileTransferTypes,
);

export const selectSelectedDeliveryMethod = createSelector(
  selectOrganizationsState,
  (state) => state.selectedDeliveryMethod,
);

export const selectEditDeliveryMethodDrawer = createSelector(
  selectOrganizationsState,
  (state) => state.drawersState.deliveryMethodEditDrawer,
);
