import {
  Injectable,
  TemplateRef,
  Output,
  EventEmitter,
  ViewContainerRef,
  ComponentRef,
  Type,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BehaviorSubject, Observable, skip } from 'rxjs';
import { BaseDrawerTemplateComponent } from './base-drawer-template.component';

const OK_TIMEOUT = 1000; // 1 second
const CANCEL_TIMEOUT = 20; // 20 milliseconds

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  public viewContainerRef: ViewContainerRef;

  public setViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  @Output() onClosed = new EventEmitter();
  private myTmpl = new BehaviorSubject<EncapsulatedTemplate>({
    template: null,
    title: 'title',
    width: '80vw',
  });
  myTemplate: Observable<EncapsulatedTemplate> = this.myTmpl.asObservable();

  constructor(private modal: NzModalService) {
    this.myTemplate.pipe(skip(1)).subscribe((view) => {
      this.createComponentModal(view);
    });
  }

  openTemplateInPopup(view: EncapsulatedTemplate): void {
    this.myTmpl.next(view);
  }

  openModal(
    _componentRef: Type<BaseDrawerTemplateComponent>,
    title = 'Popup Title',
    width = '376px',
    callback?: () => void,
  ): void {
    const componentRef: ComponentRef<BaseDrawerTemplateComponent> =
      this.viewContainerRef.createComponent(_componentRef);

    componentRef.changeDetectorRef.detectChanges();
    let ev: EncapsulatedTemplate = {
      template: componentRef.instance.componentTemplate,
      title: title,
      width: width,
      callback,
    };
    this.createComponentModal(ev);
  }

  createComponentModal(view: EncapsulatedTemplate): void {
    try {
      this.modal.create({
        nzTitle: view.title,
        nzContent: view.template,
        nzWidth: view.width,
        nzCentered: true,
        nzOkText: 'Continue',
        nzOnOk: view.callback,
        nzCancelText: 'Cancel',
        nzOnCancel: () =>
          new Promise((resolve) => setTimeout(resolve, CANCEL_TIMEOUT)),
      });
    } catch (error) {
      console.error('Failed to create modal:', error);
    }
  }
}

export interface EncapsulatedTemplate {
  template: TemplateRef<any>;
  title?: string;
  width?: string;
  close?: boolean;
  callback?: () => void;
}
