export enum Countries {
  Afghanistan = 1,
  Albania = 2,
  Algeria = 3,
  American_Samoa = 4,
  Andorra = 5,
  Angola = 6,
  Anguilla = 7,
  Antarctica = 8,
  Argentina = 9,
  Armenia = 10,
  Aruba = 11,
  Australia = 12,
  Austria = 13,
  Azerbaijan = 14,
  Bahamas = 15,
  Bahrain = 16,
  Bangladesh = 17,
  Barbados = 18,
  Belarus = 19,
  Belgium = 20,
  Belize = 21,
  Benin = 22,
  Bermuda = 23,
  Bhutan = 24,
  Bolivia = 25,
  Bosnia_and_Herzegovina = 26,
  Botswana = 27,
  Brazil = 28,
  Brunei = 29,
  Bulgaria = 30,
  Burkina_Faso = 31,
  Burma_Myanmar = 32,
  Burundi = 33,
  Cambodia = 34,
  Cameroon = 35,
  Canada = 36,
  Cape_Verde = 37,
  Cayman_Islands = 38,
  Central_African_Republic = 39,
  Chad = 40,
  Chile = 41,
  China = 42,
  Colombia = 43,
  Comoros = 44,
  Cook_Islands = 45,
  Costa_Rica = 46,
  Croatia = 47,
  Cuba = 48,
  Cyprus = 49,
  Czech_Republic = 50,
  Democratic_Republic_of_the_Congo = 51,
  Denmark = 52,
  Djibouti = 53,
  Dominica = 54,
  Dominican_Republic = 55,
  Ecuador = 56,
  Egypt = 57,
  El_Salvador = 58,
  Equatorial_Guinea = 59,
  Eritrea = 60,
  Estonia = 61,
  Ethiopia = 62,
  Falkland_Islands = 63,
  Faroe_Islands = 64,
  Fiji = 65,
  Finland = 66,
  France = 67,
  French_Polynesia = 68,
  Gabon = 69,
  Gambia = 70,
  Gaza_Strip = 71,
  Georgia = 72,
  Germany = 73,
  Ghana = 74,
  Gibraltar = 75,
  Greece = 76,
  Greenland = 77,
  Grenada = 78,
  Guam = 79,
  Guatemala = 80,
  Guinea = 81,
  Guinea_Bissau = 82,
  Guyana = 83,
  Haiti = 84,
  Honduras = 85,
  Hong_Kong = 86,
  Hungary = 87,
  Iceland = 88,
  India = 89,
  Indonesia = 90,
  Iran = 91,
  Iraq = 92,
  Ireland = 93,
  Isle_of_Man = 94,
  Israel = 95,
  Italy = 96,
  Ivory_Coast = 97,
  Jamaica = 98,
  Japan = 99,
  Jordan = 100,
  Kazakhstan = 101,
  Kenya = 102,
  Kiribati = 103,
  Kosovo = 104,
  Kuwait = 105,
  Kyrgyzstan = 106,
  Laos = 107,
  Latvia = 108,
  Lebanon = 109,
  Lesotho = 110,
  Liberia = 111,
  Libya = 112,
  Liechtenstein = 113,
  Lithuania = 114,
  Luxembourg = 115,
  Macau = 116,
  Macedonia = 117,
  Madagascar = 118,
  Malawi = 119,
  Malaysia = 120,
  Maldives = 121,
  Mali = 122,
  Malta = 123,
  Mauritania = 124,
  Mauritius = 125,
  Mexico = 126,
  Micronesia = 127,
  Moldova = 128,
  Monaco = 129,
  Mongolia = 130,
  Montenegro = 131,
  Montserrat = 132,
  Morocco = 133,
  Mozambique = 134,
  Namibia = 135,
  Nauru = 136,
  Nepal = 137,
  Netherlands = 138,
  Netherlands_Antilles = 139,
  New_Caledonia = 140,
  New_Zealand = 141,
  Nicaragua = 142,
  Niger = 143,
  Nigeria = 144,
  North_Korea = 145,
  Norway = 146,
  Oman = 147,
  Pakistan = 148,
  Panama = 149,
  Papua_New_Guinea = 150,
  Paraguay = 151,
  Peru = 152,
  Philippines = 153,
  Poland = 154,
  Portugal = 155,
  Puerto_Rico = 156,
  Qatar = 157,
  Republic_of_the_Congo = 158,
  Romania = 159,
  Russia = 160,
  Rwanda = 161,
  Saint_Kitts_and_Nevis = 162,
  Saint_Lucia = 163,
  Samoa = 164,
  Saudi_Arabia = 165,
  Senegal = 166,
  Serbia = 167,
  Seychelles = 168,
  Sierra_Leone = 169,
  Singapore = 170,
  Slovakia = 171,
  Slovenia = 172,
  Somalia = 173,
  South_Africa = 174,
  South_Korea = 175,
  Spain = 176,
  Sri_Lanka = 177,
  Sudan = 178,
  Suriname = 179,
  Swaziland = 180,
  Sweden = 181,
  Switzerland = 182,
  Syria = 183,
  Taiwan = 184,
  Tajikistan = 185,
  Tanzania = 186,
  Thailand = 187,
  Togo = 188,
  Tonga = 189,
  Trinidad_and_Tobago = 190,
  Tunisia = 191,
  Turkey = 192,
  Turkmenistan = 193,
  Uganda = 194,
  Ukraine = 195,
  United_Arab_Emirates = 196,
  United_Kingdom = 197,
  United_States = 198,
  Uruguay = 199,
  Uzbekistan = 200,
  Vanuatu = 201,
  Venezuela = 202,
  Vietnam = 203,
  Yemen = 204,
  Zambia = 205,
  Zimbabwe = 206,
}

export interface IPagingResponse {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageNumber: number;
  totalCount: number;
  totalPages: number;
  pageSize: number;
}

export interface IPagingRequest {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  isDescending: boolean;
  sortByKey?: string;
}

export interface Paging {
  pagingRequest: IPagingRequest;
  pagingResponse: IPagingResponse;
}
export const initialPagingRequest: IPagingRequest = {
  pageNumber: 1,
  pageSize: 10,
  orderBy: '',
  isDescending: false,
};
export const initialPagingResponse: IPagingResponse = {
  hasNextPage: false,
  hasPreviousPage: false,
  pageNumber: 1,
  totalCount: 0,
  totalPages: 0,
  pageSize: 10,
};
export interface IFilterOptions {
  name: string;
  options: Option[] | null;
  type: string;
}
export interface Option {
  key: string;
  value: string;
}

export interface ICountry {
  id: number;
  name: string;
  hasStates: boolean;
  states?: ICountryState[];
  timeZones?: ITimezone[];
}
export interface IDepratmentCountries {
  countries: ICountry[];
  defaultCountryId: number;
}
export interface ICountryState {
  id: number;
  name: string;
}
export interface ITimezone {
  baseUtcOffset: string;
  daylightName: string;
  displayName: string;
  hasIanaId: boolean;
  id: string;
  standardName: string;
  supportsDaylightSavingTime: boolean;
}
export enum OrganizationStatus {
  Active = 1,
  // Suspended = 2,
  Deactivated = 3,
  //Deleted = 4,
}
