import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'xpw-timer',
  template: `
    <div id="xpw-form-timer_container">
      <div id="xpw-form-timer__container__lable">
        <p id="xpw-form-timer__container__lable__text">
          The code will be expire in
        </p>
        <p id="xpw-form-timer__container__lable__timer">
          {{ minutes }}:{{ seconds | twoDigit }}
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      #xpw-form-timer_container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: var(--Space-Margin-marginXS, 8px);
        align-self: stretch;
        border-radius: var(--BorderRadius-borderRadiusLG, 3px);
        background: var(--Fill-colorFillTertiary, rgba(0, 0, 0, 0.04));
      }

      #xpw-form-timer__container__lable {
        display: flex;
        flex-direction: row;
      }

      #xpw-form-timer__container__lable__text {
        color: var(--Text-colorTextDescription, rgba(0, 0, 0, 0.45));
        margin-top: 16px;
      }
      #xpw-form-timer__container__lable__timer {
        overflow: hidden;
        color: var(--Text-colorText, rgba(0, 0, 0, 0.88));
        text-overflow: ellipsis;
        /* Base/Strong */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        padding: 0 0 0 8px;
        margin-top: 16px;
      }
    `,
  ],
})
export class XpwTimerComponent {
  @Input() set timeInSeconds(time: number) {
    this.minutes = Math.floor(time / 60);
    this.seconds = time % 60;
  }
  @Output() timerFinished = new EventEmitter<void>();

  minutes: number;
  seconds: number;
}
