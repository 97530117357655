import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ActUserStoreReducer } from './act-user-store/act-user.reducer';
import { ActUserStoreEffects } from './act-user-store/act-user.effects';
import { EffectsModule } from '@ngrx/effects';
import { ActUserComponent } from './act-user-component/act-user.component';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [ActUserComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NzInputModule,
    NzFormModule,
    NzCardModule,
    NzStepsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('actUserForm', ActUserStoreReducer),
    EffectsModule.forFeature([ActUserStoreEffects]),
  ],
  exports: [ActUserComponent],
})
export class ActUserModule {}
