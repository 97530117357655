import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NotificationService,
  NotificationType,
} from '../../services/notifications/notifications.service';
import {
  NzNotificationPlacement,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { Action, ActionService } from '@core/services/actions/actions.service';

@Component({
  selector: 'xpw-notifications',
  template: `
    <ng-template let-notificationData="data">
      <div class="ant-notification-notice-content">
        <div class="ant-notification-notice-with-icon">
          <ng-container [ngSwitch]="notificationData.type">
            <span
              *ngSwitchCase="notificationType.Success"
              class="ant-notification-notice-icon"
            >
              <span
                nz-icon
                nzType="check-circle"
                nzTheme="fill"
                style="color: rgba(82, 196, 26, 1);"
              ></span>
            </span>
            <span
              *ngSwitchCase="notificationType.Error"
              class="ant-notification-notice-icon"
            >
              <span
                nz-icon
                nzType="close-circle"
                nzTheme="fill"
                style="color: rgba(255, 77, 79, 1);"
              ></span>
            </span>
            <span
              *ngSwitchCase="notificationType.Warning"
              class="ant-notification-notice-icon"
            >
              <span
                nz-icon
                nzType="exclamation-circle"
                nzTheme="fill"
                style="color: rgba(255, 169, 64, 1);"
              ></span>
            </span>
            <span
              *ngSwitchCase="notificationType.Info"
              class="ant-notification-notice-icon"
            >
              <span
                nz-icon
                nzType="info-circle"
                nzTheme="fill"
                style="color: rgba(64, 150, 255, 1);"
              ></span>
            </span>
            <span
              *ngSwitchCase="notificationType.Loading"
              class="ant-notification-notice-icon"
            >
              <span
                nz-icon
                nzType="loading"
                nzTheme="outline"
                style="color:blue;"
              ></span>
            </span>
          </ng-container>
          <div class="ant-notification-notice-message">
            {{ notificationData.title }}
          </div>
          <div class="ant-notification-notice-description">
            {{ notificationData.message }}
          </div>
          <ng-container *ngIf="notificationData.buttons.length > 0">
            <div class="_controls">
              <button
                nz-button
                nzType="default"
                *ngFor="let button of notificationData.buttons"
                (click)="executeAction(button.actions)"
              >
                {{ button.text }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: ['./notifications.component.less'],
})
export class NotificationsComponent implements AfterViewInit {
  notificationType = NotificationType; // import for template switch case

  @ViewChild(TemplateRef, { static: false })
  template?: TemplateRef<NotificationData>;

  notificationData: NotificationData = {
    type: this.notificationType.Loading,
    title: 'system loading',
    message:
      'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    duration: 5000,
    buttons: [],
    placement: 'bottomRight',
  };
  constructor(
    private notificationService: NotificationService,
    private notificationTemplate: NzNotificationService,
    private actioneService: ActionService,
  ) {}

  ngAfterViewInit() {
    this.notificationService.notificationTemplateData$.subscribe(
      (data: NotificationData) => {
        if (!data) this.clearNotifications();
        else this.createNotification(data);
      },
    );
  }
  createNotification = (data: NotificationData) => {
    this.notificationData = data;
    this.notificationTemplate.template(this.template!, {
      nzData: this.notificationData,
      nzDuration: this.notificationData.duration,
      nzPlacement: this.notificationData.placement,
    });
  };
  clearNotifications = () => {
    this.notificationTemplate.remove();
  };
  executeAction = (actions: Action[]) => {
    this.actioneService.executeActions(actions);
  };
}

export interface NotificationData {
  type: NotificationType;
  title: string;
  message: string;
  duration: number;
  buttons: Array<NotificationButton>;
  placement: NzNotificationPlacement;
}
export interface NotificationButton {
  text: string;
  actions: Action[];
}
