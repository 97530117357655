import { getLocaleDirection } from '@angular/common';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(@Inject(LOCALE_ID) public locale: string) {}
  private languageMap: { [key: string]: string } = {
    enUS: '/en-US',
    enUtil: '/en-Util',
    ru: '/ru-RU',
    de: '/de-DE',
    he: '/he-HE', // Add more language codes and their display names
  };

  switchLanguage(newLanguage: string): void {
    let currentPath = window.location.pathname;

    currentPath = this.removePropertiesFromString(currentPath);
    const newPath = `/${newLanguage}${currentPath}`;

    if (environment.production) {
      window.location.href = window.location.origin + newPath;
    }
  }

  getLanguageCodeFromPath(path: string): string {
    const languageCode = Object.keys(this.languageMap).find((key) =>
      path.startsWith(this.languageMap[key]),
    );

    return languageCode || 'enUS';
  }
  getLocaleDirection(): 'right' | 'left' {
    return getLocaleDirection(this.locale)?.toLowerCase() !== 'rtl'
      ? 'right'
      : 'left';
  }
  private removePropertiesFromString(inputString) {
    // Create a regular expression pattern from object property names
    const pattern = new RegExp(Object.values(this.languageMap).join('|'), 'g');

    // Use the pattern to replace matched properties with an empty string
    const result = inputString.replace(pattern, '');

    return result;
  }
}
