import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { CurrentUserFlowService } from '../current-user/current-user.service';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private idleTime = 60 * 30; // 30 minutes of idle time
  private timeoutPeriod = 10; //  minute after idle (seconds)

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private currentUserFlowService: CurrentUserFlowService,
    private router: Router,
  ) {
    this.setupIdleDetection();
  }

  private setupIdleDetection() {
    this.idle.setIdle(this.idleTime); // Set the idle time
    this.idle.setTimeout(this.timeoutPeriod); // Set the timeout period
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // Detect user interactions

    this.idle.onTimeout.subscribe(() => {
      this.handleLogout(); // Handle the logout when timeout occurs
    });

    this.idle.onIdleEnd.subscribe(() => {
      console.log('No longer idle');
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      console.log(`You will be logged out in ${countdown} seconds!`);
    });

    this.idle.watch(); // Start watching for inactivity
    console.log('Idle detection service initialized.');
  }

  private handleLogout() {
    console.log('Idle time exceeded. Logging out...');
    this.currentUserFlowService.logout();
  }

  public stopIdleDetection() {
    this.idle.stop(); // Stop the idle detection if needed
  }

  public resetIdleDetection() {
    this.idle.watch(); // Reset the idle detection, if you want to manually reset it
  }
}
