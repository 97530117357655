import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

// list of imported packages from ng-zorro
import { NzPackages } from './nz.packages';
import { ScrollingModule } from '@angular/cdk/scrolling';
// list of our custom components
import { XPW_COMPONENTS } from './xpw.components';

import { NzInputGroupComponent } from 'ng-zorro-antd/input';

// rename these components
import { IconsProviderModule } from './icons-provider.module';
import { TwoDigitPipe } from './xpw-timer/twoDigit.pipe';

import { XpwEnumValueToLabelPipe } from '../pipes/xpw-enum-value-to-label.pipe';
import { DisplayForRolesDirective } from '@shared/directives/visible-for-roles.directive';
import { XpwTableService } from './table/xpw-table.service';

@NgModule({
  imports: [
    NzPackages,
    ScrollingModule,
    FormsModule,
    IconsProviderModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [
    NzPackages,
    XPW_COMPONENTS,
    NzInputGroupComponent,
    FormsModule,
    HttpClientModule,
    DisplayForRolesDirective,
  ],
  declarations: [XPW_COMPONENTS, TwoDigitPipe, DisplayForRolesDirective],
  providers: [XpwEnumValueToLabelPipe, XpwTableService],
})
export class XpwUiKitModule {}
