import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from '@core/log/logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private logger: LoggerService) {}
  handleError(error: Error): void {
    console.error(
      'Xpw Caught error occurred GlobalErrorHandler implements ErrorHandler:',
      error,
    );
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    this.logger.logException(error);
  }
}
