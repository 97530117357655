import { NgModule } from '@angular/core';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { XpwUiKitModule } from './xpw-ui-kit/xpw-ui-kit.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DisplayForRolesDirective } from './directives/visible-for-roles.directive';
import { EnableForRolesDirective } from './directives/enable-for-roles.directive';
import { XpwDepartmentDropdownComponent } from './components/department-list/department-dropdown.component';
import { CommonModule } from '@angular/common';
import { ConfirmDirective } from './directives/confirm.directive';

@NgModule({
  imports: [
    XpwUiKitModule,
    ReactiveFormsModule,
    CommonModule,
    PowerBIEmbedModule,
  ],
  exports: [
    XpwUiKitModule,
    // ProvideContainerDirective,
    // DisplayForRolesDirective,
    EnableForRolesDirective,
    ConfirmDirective,
    XpwDepartmentDropdownComponent,
  ],
  declarations: [
    // ProvideContainerDirective,
    // DisplayForRolesDirective,
    EnableForRolesDirective,
    ConfirmDirective,
    XpwDepartmentDropdownComponent,
  ],
  providers: [],
})
export class SharedModule {}
