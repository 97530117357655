import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const dashboardTypeGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const validDashboardTypes = {
    'energy-overview': true,
    'demand-overview': true,
    'energy-distribution': true,
    'hourly-energy-intensity': false,
    'validation-overview': true,
    'validation-details': true,
    'detailed-report': true,
    'tranformer-load': true,
    'event-overview': true,
    'event-log': true,
    voltages: true,
    events: true,
    'energy-&-demands': true,
    'water-overview': true,
  };
  const dashboardType = route.paramMap.get('dashboardType');

  if (validDashboardTypes[dashboardType]) {
    return true;
  } else {
    router.navigate(['/404']);
    return false;
  }
};
