import { Component, OnDestroy, Signal } from '@angular/core';
import {
  IActUser,
  IActivationForm,
  UserInvitationLinkStatus,
} from '../act-user-store/act-user.interface';
import { Store } from '@ngrx/store';
import {
  completeRegistration,
  completeRegistrationSuccess,
  getStatus,
  updateActUserForm,
} from '../act-user-store/act-user.actions';
import {
  selectActUserForm,
  selectLoadingStatus,
  selectValidationErrors,
} from '../act-user-store/act-user.selectors';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, take, Subscription, interval } from 'rxjs';
import { selectCurrentRoute } from 'src/app/store/router/router.selectors';
import { CustomVlidators } from '@shared/utility/custom-validators';
import { HttpErrorResponse } from '@angular/common/http';
import { Language } from '@features/user/user-store/user.interface';
import { RegistrationService } from '@core/api/registration.service';
import { ErrorKey } from '@shared/utility/errors-keys-msg';
import { MsalService } from '@azure/msal-angular';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { LanguageTitle } from '@shared/utility/global-enums-titles';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-act-user',
  templateUrl: './act-user.component.html',
  styleUrls: ['./act-user.component.less'],
})
export class ActUserComponent implements OnDestroy {
  actUser: IActUser;
  currentPath?: string;
  currentParam?: string;
  actUserForm$: Observable<IActivationForm> | null = null;
  activationForm: FormGroup;
  registrationCompleted: boolean = false;
  timerIsActive: boolean = false;
  canChooseOptionForVerify: boolean = true;
  timeForTimer: number = 600;
  private timerSubscription: Subscription;
  userInvitationLinkStatus = UserInvitationLinkStatus;
  languages = GlobalFunctions.convertEnumToOptions(Language, LanguageTitle);
  currentStep = 0;
  isAgreed: boolean = false;
  passwordVisible: boolean = false;
  verifyViaCall: string = 'false';
  infoStepKeyList = ['emailAddress', 'languageID', 'firstName', 'lastName'];
  passwordsStepKeyList = ['password', 'confirmPassword'];
  helpCenterTooltipVisible: boolean = false;
  isRecallButtonDisabled: boolean = false;
  isLoading: Signal<boolean>;

  private actUserSubscribtion: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private devApi: RegistrationService,
    private authService: MsalService,
  ) {}

  ngOnInit() {
    this.actUserForm$ = this.store.select(selectActUserForm);
    this.actUserSubscribtion.push(
      this.actUserForm$.subscribe(
        (actUserForm) => (this.actUser = actUserForm.actUser),
      ),
    );

    this.actUserSubscribtion.push(
      this.store
        .select(selectValidationErrors)
        .subscribe((ValidationErrors) => {
          if (ValidationErrors) {
            if (ValidationErrors) this.handleErrors(ValidationErrors);
          }
        }),
    );

    this.isLoading = this.store.selectSignal(selectLoadingStatus);

    this.actUserSubscribtion.push(
      this.store.select(completeRegistrationSuccess).subscribe(() => {
        this.registrationCompleted = true;
      }),
    );

    this.actUserSubscribtion.push(
      this.store.select(selectCurrentRoute).subscribe((currentRoute) => {
        this.currentPath = currentRoute?.routeConfig?.path;
        this.currentParam = currentRoute?.queryParams['param'];
        if (this.currentParam)
          this.store.dispatch(getStatus({ param: this.currentParam }));
      }),
    );

    this.actUserForm$.pipe(take(2)).subscribe((actUserForm) => {
      this.activationForm = this.fb.group<any>({
        emailAddress: [
          {
            value: actUserForm.actUser.emailAddress,
            disabled: true,
          },
          Validators.required,
        ],
        languageID: [actUserForm.actUser.languageID],
        firstName: [
          actUserForm.actUser.firstName,
          Validators.required,
          CustomVlidators.alphabeticValidator(),
        ],
        lastName: [
          actUserForm.actUser.lastName,
          Validators.required,
          CustomVlidators.alphabeticValidator(),
        ],
        mobilePhone: [
          actUserForm.actUser.mobilePhone,
          [Validators.required],
          [CustomVlidators.validatePhoneNumber()],
        ],
        password: [
          actUserForm.actUser.password,
          [Validators.required],
          [CustomVlidators.passwordValidator()],
        ],
        confirmPassword: [
          '',
          [Validators.required],
          [CustomVlidators.confirmPasswordValidator('password')],
        ],
        otp: [actUserForm.actUser.otp, [Validators.required]],
        verifyViaCall: [false],
      });
      if (this.activationForm)
        this.activationForm.get('password').valueChanges.subscribe((value) => {
          if (value !== this.activationForm.get('confirmPassword').value) {
            this.activationForm
              .get('confirmPassword')
              .setErrors({ passwordMismatch: true });
          } else {
            this.activationForm.get('confirmPassword').setErrors(null);
          }
        });
    });
  }

  onLoginLinkClick() {
    this.authService.loginRedirect({
      redirectUri: environment.redirectUri,
      scopes: environment.ApiScopes,
    });
  }

  phoneNumberChanged() {
    this.canChooseOptionForVerify = true;
  }

  sendOtc() {
    if (this.timerIsActive === false) this.startTimer();
    this.activationForm.get('otp').patchValue('');
    this.canChooseOptionForVerify = false;
    this.disableRecallButton();
    this.actUserSubscribtion.push(
      this.devApi
        .sentOtcUserRegistration(
          this.actUser.userUId,
          this.activationForm.get('mobilePhone').value,
          this.activationForm.get('verifyViaCall').value,
        )
        .subscribe((result) => {
          if (result?.status) console.log(result?.status);
        }),
    );
  }

  submitForm() {
    this.store.dispatch(
      updateActUserForm({ actUser: this.activationForm.value }),
    );
    this.store.dispatch(
      completeRegistration({ actUser: this.actUser, param: this.currentParam }),
    );
    console.log(this.activationForm.value);
  }

  ngOnDestroy() {
    this.store.dispatch(
      updateActUserForm({ actUser: this.activationForm.value }),
    );
    this.actUserSubscribtion.map((subscr) => subscr.unsubscribe());
  }

  // Function to move to next step
  nextStep(): void {
    this.currentStep += 1;
  }

  // Function to move to previous step
  prevStep(): void {
    this.currentStep -= 1;
  }

  currentStepHasError(): boolean {
    if (this.currentStep == 0)
      return GlobalFunctions.checkErrorsFromForm(
        this.infoStepKeyList,
        this.activationForm,
      )
        ? true
        : false;
    if (this.currentStep == 1)
      return GlobalFunctions.checkErrorsFromForm(
        this.passwordsStepKeyList,
        this.activationForm,
      )
        ? true
        : false;
    return false;
  }

  startTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timeForTimer = 600; // Reset to 10 minutes
    this.timerIsActive = true;
    this.timerSubscription = interval(1000).subscribe(() => {
      this.timeForTimer--;
      if (this.timeForTimer <= 0) {
        this.timerIsActive = false;
        this.canChooseOptionForVerify = true;
        this.timerSubscription.unsubscribe();
      }
    });
  }

  disableRecallButton() {
    this.isRecallButtonDisabled = true;
    setTimeout(() => {
      this.isRecallButtonDisabled = false;
    }, 30000); // 30 seconds
  }

  handleErrors(ValidationErrors: HttpErrorResponse) {
    console.log('handleErrors', ValidationErrors);
    if (ValidationErrors.error) {
      const errors = ValidationErrors.error.validationErrorKeys;
      Object.keys(errors).forEach((key) => {
        errors[key].map((error) => {
          const errorKey = errors[key];
          let controlErrors: { [key: string]: boolean } | null = {};
          controlErrors[ErrorKey[errorKey]] = true;
          this.activationForm.get(key).setErrors(controlErrors);
        });
      });
    }
  }
}
