import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'xpw-tag-closable',
  template: `<nz-tag nzMode="closeable" (nzOnClose)="onClose()">{{
    label
  }}</nz-tag>`,
})
export class XpwClosableTagComponent {
  @Input() label: string = 'closable tag';
  @Output() onCloseClick = new EventEmitter<string>();

  onClose() {
    this.onCloseClick.emit(this.label);
  }
}
