import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AbstractInputComponent } from '@shared/xpw-ui-kit/form/abstract-input.component';
import { Store } from '@ngrx/store';
import {
  selectCountries,
  selectDefaultCountryId,
} from 'src/app/store/location/location.selector';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { setCity, setCountry } from 'src/app/store/location/location.actions';
import { ICountry } from '@shared/utility/global-enums';
import { Subscription, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'xpw-form-select-countries',
  template: `
    <nz-form-item>
      <nz-form-label *ngIf="label"
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}</nz-form-label
      >
      <nz-form-control
        [nzHasFeedback]="hasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-select
          i18n-placeholder
          nzPlaceHolder="Select Country"
          nzShowSearch
          nzSuffixIcon="xpw-outline:{{ icon }}"
          [disabled]="readonly"
          [nzMode]="mode"
          [nzOptions]="options"
          [nzMaxTagCount]="10"
          [(ngModel)]="value"
          (blur)="onTouched()"
          (ngModelChange)="selectChange($event)"
        >
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwFormCountriesComponent),
      multi: true,
    },
  ],
})
export class XpwFormCountriesComponent
  extends AbstractInputComponent
  implements OnInit, OnDestroy
{
  @Input('options') options: any[]; // ['electricity', 'water', 'gas'];

  @Input('label') label: string;
  @Input('disabled') disabledInput: boolean = false;
  @Input('icon') icon: string = 'down';
  @Input('mode') mode: any = 'default'; // 'multiple' | 'tags' | 'default'
  @Input() hasFeedback: boolean = true;

  @Output() onSelectedChange = new EventEmitter();

  Subscriptions$: Subscription[] = [];
  selectChange(id: any) {
    // console.log(event);
    // const _country: ICountry = { id: 198, name: 'USA', hasStates: true }; //mock data
    this.store.dispatch(setCountry({ countryId: id }));
    this.store.dispatch(setCity({ city: null }));
    this.onSelectedChange.emit(event);
  }

  constructor(
    private store: Store,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.Subscriptions$.push(
      this.store.select(selectCountries).subscribe((countries) => {
        this.options = GlobalFunctions.convertToOptions(
          countries,
          'id',
          'name',
          this.isOptional,
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.Subscriptions$.forEach((sub) => sub.unsubscribe());
    // initialize state for country
  }
}
