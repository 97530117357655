import { Pipe, PipeTransform } from '@angular/core';
// use this for timer

@Pipe({
  name: 'twoDigit',
})
export class TwoDigitPipe implements PipeTransform {
  transform(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
