import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { AccountStatus } from '@features/user/user-store/user.interface';

@Component({
  selector: 'xpw-account-status-tag',
  template: `
    <nz-tag [nzColor]="color">
      <xpw-icon icon="{{ icon }}" />
      <span>{{ stringTitle }}</span>
    </nz-tag>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpwAccountStatusTagComponent implements OnChanges {
  @Input() status: AccountStatus;
  stringTitle: string = '';
  icon: string | null = null;
  color: string = 'default';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['status']) {
      this.getData();
    }
  }

  getData() {
    switch (this.status) {
      case AccountStatus.Active:
        this.stringTitle = $localize`Active`;
        this.icon = 'check';
        this.color = 'green';
        break;
      case AccountStatus.Inactive:
        this.stringTitle = $localize`Inactive`;
        this.icon = null;
        this.color = 'default';
        break;
      case AccountStatus.Pending:
        this.stringTitle = $localize`Pending`;
        this.icon = 'clock-circle';
        this.color = 'default';
        break;
      case AccountStatus.Suspended:
        this.stringTitle = $localize`Suspended`;
        this.icon = null;
        this.color = 'default';
        break;
      case AccountStatus.Locked:
        this.stringTitle = $localize`Locked`;
        this.icon = 'locked';
        this.color = 'default';
        break;
      case AccountStatus.Expired:
        this.stringTitle = $localize`Expired`;
        this.icon = 'expired';
        this.color = 'red';
        break;
      case AccountStatus.Deleted:
        this.stringTitle = $localize`Deleted`;
        this.icon = null;
        this.color = 'default';
        break;
      default:
        this.stringTitle = $localize`Unknown`;
        this.icon = null;
        this.color = 'default';
    }
  }
}
