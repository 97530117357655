import { createReducer, on } from '@ngrx/store';
import { initialLocationState } from './location.state';
import * as LocationActions from './location.actions';

export const locationReducer = createReducer(
  initialLocationState,

  on(LocationActions.getDepartmentCountries, (state) => ({
    ...state,
    showStates: false,
  })),
  on(LocationActions.getAllCountries, (state) => ({
    ...state,
    showStates: false,
  })),

  on(LocationActions.getCountryStates, (state) => ({
    ...state,
    states: state.countries.find(
      (country) => country.id == state.selectedCountry.id,
    ).states,
    showStates: true,
  })),
  on(LocationActions.getStateCities, (state) => ({
    ...state,
    cities: [],
    showStates: true,
  })),
  on(LocationActions.getCountryCities, (state) => ({
    ...state,
    cities: [],
    showStates: false,
  })),

  on(LocationActions.getCountriesSuccess, (state, { departmentCountries }) => ({
    ...state,
    countries: departmentCountries.countries,
    defaultCountryId: departmentCountries.defaultCountryId,
    CountryTimzone: departmentCountries.countries.find(
      (country) => country.id == departmentCountries.defaultCountryId,
    ).timeZones,
    showStates: departmentCountries.countries.find(
      (country) => country.id == departmentCountries.defaultCountryId,
    ).hasStates,
    states: departmentCountries.countries.find(
      (country) => country.id == departmentCountries.defaultCountryId,
    ).states,
  })),
  on(LocationActions.getAllCountriesSuccess, (state, { countries }) => ({
    ...state,
    showCountries: true,
    allCountries: countries,
  })),
  on(LocationActions.getCountryStatesSuccess, (state, { states }) => ({
    ...state,
    showStates: true,
    states: states,
  })),
  on(LocationActions.getCitiesSuccess, (state, { cities }) => ({
    ...state,
    cities: cities,
  })),

  on(LocationActions.setCountry, (state, { countryId }) => ({
    ...state,
    selectedCountry: state.countries.find((country) => country.id == countryId),
    CountryTimzone: state.countries.find((country) => country.id == countryId)
      .timeZones,
    showStates: state.countries.find((country) => country.id == countryId)
      .hasStates,
    states: state.countries.find((country) => country.id == countryId).states,
    selectedState: null,
    cities: [],
  })),
  on(LocationActions.setState, (state, { stateId }) => ({
    ...state,
    selectedState: state.states.find((state) => state.id == stateId),
    cities: [],
  })),
  on(LocationActions.setCity, (state, { city }) => ({
    ...state,
    selectedCity: city,
  })),
  on(LocationActions.resetLocation, (state) => ({
    ...state,
    showStates: false,
    selectedCountry: null,
    selectedState: null,
    selectedCity: null,
    cities: [],
  })),
  // on(LocationActions.resetCities, (state) => ({
  //   ...state,
  //   cities: [],
  //   selectedCity: null,
  // })),
);
