import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  FileImportType,
  IImportHistoryResult,
} from '@features/import-history/import-history-store/import-history.interface';
import { IPagingRequest } from '@shared/utility/global-enums';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IFileImportType,
  ImportType,
} from 'src/app/store/file-import/file-import.interface';
import { GlobalFunctions } from '@shared/utility/global-functions';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  apiBaseUrl = '';
  endpoint = 'fileImports'; // Name form API

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getImportHistoryList(
    pagingRequest: IPagingRequest,
    filter: any,
    search: string,
  ): Observable<IImportHistoryResult> {
    return this.http.get<IImportHistoryResult>(
      this.apiBaseUrl + this.endpoint,
      {
        params: {
          PageNumber: pagingRequest.pageNumber,
          PageSize: pagingRequest.pageSize,
          OrderBy: pagingRequest.orderBy,
          IsDescending: pagingRequest.isDescending,
          StatusId: filter.StatusId,
          typeId: filter.FileImportTypeId,
          UserUID: filter.UserUID,
          Search: search,
        },
      },
    );
  }

  getImportHistoryFilterOptions(): Observable<any> {
    return this.http.get<any>(this.apiBaseUrl + this.endpoint + '/filters');
  }

  getFileImportTypes(): Observable<IFileImportType[]> {
    return this.http.get<IFileImportType[]>(
      this.apiBaseUrl + this.endpoint + '/types',
    );
  }

  uploadFile(file: File, sheetName: string, type: ImportType): Observable<any> {
    const formData = new FormData();
    formData.append('File', file);

    const params = new HttpParams().set('Type', type).set('Sheet', sheetName);

    return this.http.post(this.apiBaseUrl + this.endpoint, formData, {
      params,
    });
  }

  downloadTemplateFile(type: ImportType, options?: any): Observable<any> {
    return this.http.get(this.apiBaseUrl + this.endpoint + '/templates/empty', {
      params: {
        Type: type,
      },
      ...options,
    });
  }

  downloadReportFile(fileUID: string, errorFile: boolean): Observable<any> {
    const fileType: string = errorFile ? '/errors' : '';

    return this.http.get(
      this.apiBaseUrl + this.endpoint + '/' + fileUID + fileType,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  cancelFileImport(fileUID: string): Observable<any> {
    return this.http.put(
      this.apiBaseUrl + this.endpoint + '/' + fileUID + '/cancel',
      {},
    );
  }
}
