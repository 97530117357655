import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserStatusService {
  // Signals for various browser status states
  isFocused = signal<boolean>(true);
  isOnline = signal<boolean>(navigator.onLine);
  isIdle = signal<boolean>(false);
  windowSize = signal<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  private idleTimeout: any;
  private idleTimeLimit = 60000; // 1 minute

  constructor() {
    this.detectBrowserFocus();
    this.detectOnlineStatus();
    this.detectIdleStatus();
    this.detectWindowResize();
  }

  private detectBrowserFocus(): void {
    this.isFocused.set(!document.hidden);

    document.addEventListener('visibilitychange', () => {
      this.isFocused.set(!document.hidden);
    });

    window.addEventListener('focus', () => this.isFocused.set(true));
    window.addEventListener('blur', () => this.isFocused.set(false));
  }

  private detectOnlineStatus(): void {
    this.isOnline.set(navigator.onLine);

    window.addEventListener('online', () => this.isOnline.set(true));
    window.addEventListener('offline', () => this.isOnline.set(false));
  }

  private detectIdleStatus(): void {
    const resetIdleTimeout = () => {
      clearTimeout(this.idleTimeout);
      this.isIdle.set(false);
      this.idleTimeout = setTimeout(
        () => this.isIdle.set(true),
        this.idleTimeLimit,
      );
    };

    // Reset idle timer on user interaction
    ['mousemove', 'keydown', 'click', 'scroll'].forEach((event) => {
      window.addEventListener(event, resetIdleTimeout);
    });

    // Start the idle timeout countdown
    resetIdleTimeout();
  }

  private detectWindowResize(): void {
    // Initial size
    this.windowSize.set({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    window.addEventListener('resize', () => {
      this.windowSize.set({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }
}
