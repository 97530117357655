import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'xpw-form-input-phone',
  template: `
    <nz-form-item>
      <nz-form-label [nzFor]="formControlName">{{ label }}</nz-form-label>
      <nz-form-control [nzErrorTip]="getErrorLabel()">
        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
          <input
            nz-input
            [(ngModel)]="phoneNumber"
            (ngModelChange)="onPhoneNumberChange($event)"
            (keypress)="onKeyPress($event)"
            i18n-placeholder
            placeholder="XX XXX XX XX"
            [ngModelOptions]="{ standalone: true }"
          />
        </nz-input-group>
        <ng-template #addOnBeforeTemplate>
          <nz-select
            [(ngModel)]="selectedCountryCode"
            (ngModelChange)="onCountryCodeChange($event)"
            [nzPlaceHolder]="placeholderText"
            style="width: 130px"
            [ngModelOptions]="{ standalone: true }"
          >
            <nz-option
              *ngFor="let code of countryCodes"
              [nzLabel]="code.label"
              [nzValue]="code.value"
            ></nz-option>
          </nz-select>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputPhoneComponent),
      multi: true,
    },
  ],
})
export class XpwInputPhoneComponent extends AbstractInputComponent {
  @Input('placeholder') placeholderText: string;
  @Input('formControlName') formControlName: any;
  @Input('label') label: string;
  form: FormGroup;
  phoneNumber: string | null = null;
  selectedCountryCode = '+1';
  countryCodes = [
    { label: 'US +1', value: '+1' },
    { label: 'UA +380', value: '+380' },
    { label: 'GB +44', value: '+44' },
    { label: 'IL +972', value: '+972' },
    { label: 'AU +61', value: '+61' },
    { label: 'NZ +64', value: '+64' },
    { label: 'IN +91', value: '+91' },
    { label: 'DE +49', value: '+49' },
    { label: 'FR +33', value: '+33' },
    { label: 'IT +39', value: '+39' },
    { label: 'ES +34', value: '+34' },
    { label: 'JP +81', value: '+81' },
    { label: 'KR +82', value: '+82' },
    { label: 'CN +86', value: '+86' },
    { label: 'BR +55', value: '+55' },
    { label: 'MX +52', value: '+52' },
    { label: 'RU +7', value: '+7' },
    { label: 'ZA +27', value: '+27' },
    { label: 'NG +234', value: '+234' },
    { label: 'EG +20', value: '+20' },
    { label: 'TR +90', value: '+90' },
    { label: 'SA +966', value: '+966' },
    { label: 'AE +971', value: '+971' },
    { label: 'SG +65', value: '+65' },
    { label: 'MY +60', value: '+60' },
    { label: 'ID +62', value: '+62' },
    { label: 'PH +63', value: '+63' },
    { label: 'TH +66', value: '+66' },
    { label: 'VN +84', value: '+84' },
  ];

  override writeValue(value: any): void {
    if (value) {
      const [countryCode, phoneNumber] = value.split(' ');
      this.selectedCountryCode = countryCode;
      this.phoneNumber = this.formatNumber(phoneNumber);
    }
  }

  private updateCombinedValue() {
    const combinedValue = `${this.selectedCountryCode} ${this.phoneNumber.replace(/\D/g, '')}`;
    this.onChange(combinedValue);
  }

  onCountryCodeChange(newCode: string) {
    this.selectedCountryCode = newCode;
    this.updateCombinedValue();
  }

  onPhoneNumberChange(newNumber: string) {
    this.phoneNumber = this.formatNumber(newNumber);
    this.updateCombinedValue();
  }

  formatNumber(value: string): string {
    // Remove all non-digit characters
    const digits = value.replace(/\D/g, '');

    // Format the number into XX XXX XX XX
    let formatted = '';
    for (let i = 0; i < digits.length; i++) {
      if (i === 2 || i === 5 || i === 7) {
        formatted += ' ';
      }
      formatted += digits[i];
    }
    return formatted.trim();
  }
  onKeyPress(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
}
