import { Input, Pipe, PipeTransform } from '@angular/core';
import {
  DateFormatType,
  DateManagerService,
} from '@core/services/date-manager/date-manager.service';
import * as moment from 'moment';
import { NzI18nService } from 'ng-zorro-antd/i18n';

@Pipe({
  name: 'xpwDateTimeFormatter',
})
export class XpwDateTimeFormatterPipe implements PipeTransform {
  constructor(private dateService: DateManagerService) {}

  transform(date: Date): string {
    return this.dateService.setFormatDate(date, DateFormatType.DATETIME);
  }
}
