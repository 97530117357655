<div class="overlay" [class.active]="enabled">
  <div
    class="overlay-background"
    [ngStyle]="{ 'background-image': 'url(' + getfile() + ')' }"
  ></div>
  <div class="overlay-content">
    <span class="loader"></span>
    {{ message }}
    <ng-content></ng-content>
  </div>
</div>
