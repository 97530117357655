import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  Injector,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractDateTimeInputComponent } from '../abstract-datetime.component';
import { DateManagerService } from '@core/services/date-manager/date-manager.service';

@Component({
  selector: 'xpw-form-input-time',
  template: `
    <nz-form-item>
      <nz-form-label
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="hasFeedback"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-time-picker
          [(ngModel)]="value"
          nzFormat="HH:mm"
          [nzDisabled]="readonly"
          (ngModelChange)="timeChanged($event)"
          (blur)="onTouched()"
        ></nz-time-picker>
        <ng-content></ng-content>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: `
    nz-time-picker {
      width: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputTimeComponent),
      multi: true,
    },
  ],
})
export class XpwInputTimeComponent extends AbstractDateTimeInputComponent {
  @Input('label') label: string;
  @Input('hasFeedback') hasFeedback: boolean = true;
  @Input('utcCorrection') utcCorrection: boolean = true;

  get format(): string {
    return 'HH:mm';
  }

  constructor(
    private dateService: DateManagerService,
    injector: Injector,
  ) {
    super(injector);
  }

  timeChanged(value: any) {
    if (this.utcCorrection) {
      this.onChange(this.dateService.getOnlyTime(value));
    } else {
      this.onChange(value);
    }
  }

  override writeValue(value: any): void {
    if (value) {
      super.writeValue(value);
    } 
  }
}
