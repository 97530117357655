import { createAction, props } from '@ngrx/store';
import { IFileImportType, ImportType } from './file-import.interface';
import { HttpResponse } from '@angular/common/http';

export enum FileImportActions {
  GetFileImportTypes = '[File Import] Get File Import Types',
  GetFileImportTypesSuccess = '[File Import] Get File Import Types Success',

  SetFileImportType = '[File Import] Set Selected File Import Type',

  GetTemplateFile = '[File Import] Get Template File',

  SetFileData = '[File Import] Set File Data',
  UploadSetFileData = '[File Import] Upload Set File Data',

  UploadSetFileDataSuccess = '[File Import] Upload Set File Data Success',
  UploadSetFileDataFailure = '[File Import] Upload Set File Data Failure',

  ShowPopup = '[File Import] Show Popup',
  HidePopup = '[File Import] Hide Popup',

  DownloadTemplateFile = '[File Import] Download Template File',
  DownloadTemplateFileSuccess = '[File Import] Download Template File Success',
  DownloadTemplateFileFailure = '[File Import] Download Template File Failure',
}

export const getFileImportTypes = createAction(
  FileImportActions.GetFileImportTypes,
);
export const getFileImportTypesSuccess = createAction(
  FileImportActions.GetFileImportTypesSuccess,
  props<{ fileImportTypes: IFileImportType[] }>(),
);

export const setFileImportType = createAction(
  FileImportActions.SetFileImportType,
  props<{ fileImportTypeID: ImportType }>(),
);

export const getTemplateFile = createAction(
  FileImportActions.GetTemplateFile,
  props<{ fileImportTypeID: number }>(),
);

export const setFileData = createAction(
  FileImportActions.SetFileData,
  props<{ sheetName: string }>(),
);

export const uploadSetFileData = createAction(
  FileImportActions.UploadSetFileData,
  props<{ file: File }>(),
);

export const uploadSetFileDataSuccess = createAction(
  FileImportActions.UploadSetFileDataSuccess,
  props<{ response: any }>(),
);

export const uploadSetFileDataFailure = createAction(
  FileImportActions.UploadSetFileDataFailure,
  props<{ error: any }>(),
);

export const showPopup = createAction(
  FileImportActions.ShowPopup,
  props<{ isVisible: boolean }>(),
);

export const downloadTemplateFile = createAction(
  FileImportActions.DownloadTemplateFile,
  props<{ fileImportTypeID: ImportType }>(),
);

export const downloadTemplateFileSuccess = createAction(
  '[File Import] Download Template File Success',
  props<{ response: any }>(),
);

export const downloadTemplateFileFailure = createAction(
  '[File Import] Download Template File Failure',
  props<{ error: any }>(),
);
