import { Component, Input } from '@angular/core';
import { Role } from '@features/user/user-store/user.interface';
import { NzButtonSize, NzButtonType } from 'ng-zorro-antd/button';

@Component({
  selector: 'xpw-button',
  template: `<button
    [disabled]="disabledInput"
    nz-button
    [nzType]="type"
    [nzSize]="size"
    type="button"
    nzSearch
    [nzLoading]="isLoading"
    [nzDanger]="isRedButton"
    [ngClass]="{ 'transparent-back': type === 'default' }"
  >
    <ng-content></ng-content>
  </button>`,
  styleUrls: ['./xpw-button.component.less'],
})
export class XpwButtonComponent {
  @Input('isLoading') isLoading: boolean = false;
  @Input('disabled') disabledInput: boolean = false;
  @Input() isRedButton = false;
  size: NzButtonSize = 'large'; // 'large' | 'default' | 'small';
  @Input() type: NzButtonType = 'primary'; //'primary' | 'default' | 'dashed' | 'link' | 'text' | null;
}
