import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnDestroy,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Role } from '@features/user/user-store/user.interface';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectCurrentUserRoles } from 'src/app/store/current-user/current-user.selector';

@Directive({
  selector: '[appDisplayForRoles]',
})
export class DisplayForRolesDirective implements OnDestroy {
  private adminsRoles: Observable<Role[]>;
  private subscr: Subscription;
  private _roles: Role[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private store: Store,
  ) {
    this.adminsRoles = store.select(selectCurrentUserRoles);
  }
  @Input() set appDisplayForRoles(_roles: Role[]) {
    this._roles = _roles;
  }

  ngOnInit() {
    this.adminsRoles = this.store.select(selectCurrentUserRoles);
    this.updateView();
  }

  updateView(): void {
    this.subscr = this.adminsRoles.subscribe((adminRoles) => {
      if (!this._roles || this._roles.length === 0)
        this.viewContainer.createEmbeddedView(this.templateRef);
      else if (this._roles && adminRoles) {
        let hasRole = this._roles.some((role) => adminRoles.includes(role));
        if (hasRole) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.subscr.unsubscribe();
  }
}
