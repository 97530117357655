import { Injectable, Signal } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

//TO DO decide what 3rd party monitoring and logging

import { EmitEvent, EventBusService, Events } from './event-bus.service';
import { selectAuditMessage } from 'src/app/store/audit/audit.selector';
import { clearAuditAction } from 'src/app/store/audit/audit.actions';
import { MsalService } from '@azure/msal-angular';
import { CurrentUserFlowService } from '../current-user/current-user.service';

@Injectable()
export class EventInterceptor implements HttpInterceptor {
  audit: Signal<string>;

  constructor(
    private eventBus: EventBusService,
    private store: Store,
    private currentUserService: CurrentUserFlowService,
  ) {
    this.audit = store.selectSignal(selectAuditMessage);
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.currentUserService.isExpiredToken()) {
      this.currentUserService.logout();
    }
    this.eventBus.emit(new EmitEvent(Events.httpRequest, request));
    const headers: HttpHeaders = new HttpHeaders({
      AuditComments: this.audit(),
    });

    // Add headers to the request
    request = request.clone({
      headers: headers,
    });

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Clear function called on a successful response
          this.store.dispatch(clearAuditAction());
          //this.eventBus.emit(new EmitEvent(Events.httpResponse, event));
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.eventBus.emit(new EmitEvent(Events.httpResponse, error));
        // return of(null);
        throw error;
      }),
    );
  }
}
