import { createAction, props } from '@ngrx/store';
import { IMeterGroup, IMeterGroupView } from './meter-group.interface';
import { saveButton } from './meter-group.state';

export enum MeterGroupsActionType {
  selectMeterGroup = '[MeterGroup] Select MeterGroup',
  getMetersList = '[MeterGroup] Get meters List',
  getMetersListSuccess = '[MeterGroup] Get meters List Success',
  getMetersListFailure = '[MeterGroup] Get meters List Failure',
  getMeterGroupList = '[MeterGroup] Get MeterGroup List',
  getMeterGroupListSuccess = '[MeterGroup] Get MeterGroup List Success',
  setUpdateMeterGroup = '[MeterGroup] Save edited MeterGroup',
  setUpdateMeterGroupSuccess = '[MeterGroup] Save edited MeterGroup success',
  setUpdateMeterGroupFailure = '[MeterGroup] Save edited MeterGroup failure',
  createNewMeterGroup = '[MeterGroup] Create new MeterGroup',
  createChildNewMeterGroup = '[MeterGroup] Create child new MeterGroup',
  setNewMeterGroup = '[MeterGroup] Save new MeterGroup',
  setNewMeterGroupSuccess = '[MeterGroup] Save new MeterGroup success',
  setNewMeterGroupFailure = '[MeterGroup] Save new MeterGroup failure',
  deleteMeterGroup = '[MeterGroup] Delete MeterGroup',
  deleteMeterGroupSuccess = '[MeterGroup] Delete MeterGroup success',
  deleteMeterGroupFailure = '[MeterGroup] Delete MeterGroup failure',
  ShowErrorMessage = '[Meter] Show Errors Messages',
  ShowMetersGroupSuccessMessage = '[Meter] Show Messages Success',
  ValidationErrors = '[MeterGroup] Validation Errors',

  ResetMeterGroupState = '[MeterGroup] Reset MeterGroup State',
  SearchChange = '[MeterGroup] Search Change',

  selectMeterByName = '[MeterGroup] Select allmeter by name filter',
  unselectMeterByName = '[MeterGroup] UnSelect allmeter by name filter',
  updateSelectedMeterUIDs = '[Meter Group] Update Selected Meter UIDs',
  setLastChangedCreatedMeterGroup = '[Meter Group] Save last meter group',

  setMeterUIDsToAdd = '[Meter Group] Set Meter UIDs to Add',
  setMeterUIDsToRemove = '[Meter Group] Set Meter UIDs to Remove',

  setSaveButton = '[Meter Group] Set Save Button',
  updateMeterGroupMetersList = '[Meter Group] Update MeterGroup Meters List',
  updateMeterGroupMetersListSuccess = '[Meter Group] Update MeterGroup Meters List Success',
}

export const selectMeterGroup = createAction(
  MeterGroupsActionType.selectMeterGroup,
  props<{ meterGroupUID: string }>(),
);

export const selectMeterByName = createAction(
  MeterGroupsActionType.selectMeterByName,
  props<{ name: string }>(),
);
export const unselectMeterByName = createAction(
  MeterGroupsActionType.unselectMeterByName,
  props<{ name: string }>(),
);

export const updateSelectedMeterUIDs = createAction(
  MeterGroupsActionType.updateSelectedMeterUIDs,
  props<{ meterUIDs: string[] }>(),
);

// MeterGroup list
export const getMeterGroupList = createAction(
  MeterGroupsActionType.getMeterGroupList,
);

export const getMeterGroupListSuccess = createAction(
  MeterGroupsActionType.getMeterGroupListSuccess,
  props<{ meterGroups: IMeterGroupView[] }>(),
);

// Meters list
export const getMetersList = createAction(
  MeterGroupsActionType.getMetersList,
  props<{ meterGroup: IMeterGroup }>(),
);

export const getMetersListSuccess = createAction(
  MeterGroupsActionType.getMetersListSuccess,
  props<{ meterUIDs: string[] }>(),
);

// Save or update edited MeterGroup
export const setUpdateMeterGroup = createAction(
  MeterGroupsActionType.setUpdateMeterGroup,
  props<{ MeterGroupUpdated: IMeterGroup }>(),
);
export const setUpdateMeterGroupSuccess = createAction(
  MeterGroupsActionType.setUpdateMeterGroupSuccess,
);

// add New MeterGroup

export const createNewMeterGroup = createAction(
  MeterGroupsActionType.createNewMeterGroup,
);
export const createChildNewMeterGroup = createAction(
  MeterGroupsActionType.createChildNewMeterGroup,
  props<{ parentMeterGroupUID: string }>(),
);

export const setNewMeterGroup = createAction(
  MeterGroupsActionType.setNewMeterGroup,
  props<{ MeterGroupUpdated: IMeterGroup }>(),
);

export const setNewMeterGroupSuccess = createAction(
  MeterGroupsActionType.setNewMeterGroupSuccess,
  props<{ MeterGroupCreated: IMeterGroupView }>(),
);

//save last meter group

export const setLastChangedCreatedMeterGroup = createAction(
  MeterGroupsActionType.setLastChangedCreatedMeterGroup,
  props<{ meterGroup: IMeterGroup }>(),
);

//delete MeterGroup
export const deleteMeterGroup = createAction(
  MeterGroupsActionType.deleteMeterGroup,
  props<{ meterGroupUID: string }>(),
);

export const showErrors = createAction(
  MeterGroupsActionType.ShowErrorMessage,
  props<{ message: string }>(),
);

export const showMetersGroupSuccessMessage = createAction(
  MeterGroupsActionType.ShowMetersGroupSuccessMessage,
  props<{ message: string }>(),
);

export const validationError = createAction(
  MeterGroupsActionType.ValidationErrors,
  props<{ ValidationErrors: any }>(),
);

export const resetMeterGroupState = createAction(
  MeterGroupsActionType.ResetMeterGroupState,
);

export const searchChange = createAction(
  MeterGroupsActionType.SearchChange,
  props<{ search: string }>(),
);

export const setMeterUIDsToAdd = createAction(
  MeterGroupsActionType.setMeterUIDsToAdd,
  props<{ meterUIDs: string[] }>(),
);

export const setMeterUIDsToRemove = createAction(
  MeterGroupsActionType.setMeterUIDsToRemove,
  props<{ meterUIDs: string[] }>(),
);

export const updateMeterGroupMetersList = createAction(
  MeterGroupsActionType.updateMeterGroupMetersList,
);

export const updateMeterGroupMetersListSuccess = createAction(
  MeterGroupsActionType.updateMeterGroupMetersListSuccess,
  props<{ response: any }>(),
);

export const setSaveButton = createAction(
  MeterGroupsActionType.setSaveButton,
  props<{ saveButton: saveButton }>(),
);
