import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@shared/xpw-ui-kit/form/abstract-input.component';
import { Store } from '@ngrx/store';
import {
  selectCities,
  selectSelectedCity,
} from 'src/app/store/location/location.selector';
import { GlobalFunctions } from '@shared/utility/global-functions';
import {
  BehaviorSubject,
  debounceTime,
  filter,
  map,
  Observable,
  Subscription,
  combineLatest,
  startWith,
} from 'rxjs';
import {
  getCitiesBySearch,
  resetLocation,
} from 'src/app/store/location/location.actions';

@Component({
  selector: 'xpw-form-select-cities',
  template: `
    <ng-container *ngIf="!disabledInput">
      <nz-form-item>
        <nz-form-label *ngIf="label"
          >{{ label }} {{ isOptional ? ' (Optional)' : '' }}</nz-form-label
        >
        <nz-form-control
          [nzHasFeedback]="hasFeedback"
          [nzValidateStatus]="errorStatus()"
          [nzErrorTip]="getErrorLabel()"
        >
          <nz-select
            nzSuffixIcon="xpw-outline:{{ icon }}"
            nzShowSearch
            nzServerSearch
            (nzOnSearch)="search($event)"
            [disabled]="readonly"
            [nzMode]="mode"
            [nzMaxTagCount]="countToShow"
            [nzMaxTagCount]="10"
            i18n-nzPlaceHolder
            nzPlaceHolder="Start typing to search..."
            [(ngModel)]="value"
            (blur)="onTouched()"
            (ngModelChange)="selectChange($event)"
          >
            <ng-container *ngFor="let o of options">
              <nz-option
                *ngIf="!isLoading"
                [nzValue]="o.value"
                [nzLabel]="o.label"
              ></nz-option>
            </ng-container>
            <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
              <span nz-icon nzType="loading" class="loading-icon"></span>
              Loading Data...
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwFormCitiesComponent),
      multi: true,
    },
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpwFormCitiesComponent
  extends AbstractInputComponent
  implements OnInit, OnDestroy
{
  @Input('options') options: any[] = []; // ['electricity', 'water', 'gas'];

  @Input('label') label: string;
  @Input('countToShow') countToShow: number;
  @Input('disabled') disabledInput: boolean = false;
  @Input('icon') icon: string = 'search';
  @Input('mode') mode: any = 'default'; // 'multiple' | 'tags' | 'default'
  @Input() hasFeedback: boolean = true;

  // @Input() value: any;
  @Output() onSelectedChange = new EventEmitter();

  isLoading: boolean = false;
  selectedCity$: Observable<string>;
  cityOptions$: Observable<string[]>;
  searchChange$ = new BehaviorSubject('');
  Subscriptions$: Subscription[] = [];
  selectChange(event: any) {
    this.onSelectedChange.emit(event);
  }

  constructor(
    private store: Store,
    injector: Injector,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.selectedCity$ = this.store.select(selectSelectedCity);
    this.cityOptions$ = this.store.select(selectCities);
    this.Subscriptions$.push(
      this.searchChange$
        .asObservable()
        .pipe(
          filter((value) => value.length > 0),
          debounceTime(800),
        )
        .pipe(
          map((value) => {
            this.isLoading = true;
            this.store.dispatch(getCitiesBySearch({ search: value }));
          }),
        )
        .subscribe(),

      combineLatest([
        this.cityOptions$.pipe(startWith([])),
        this.selectedCity$.pipe(startWith(null)),
      ])
        .pipe(
          map(([cities, selectedCity]) => {
            cities = GlobalFunctions.filterDuplicates(cities);
            let options = cities.map((city) => ({ label: city, value: city }));

            if (this.isOptional) {
              options.unshift({ value: null, label: 'None' });
            }

            if (selectedCity) {
              options.unshift({
                label: selectedCity,
                value: selectedCity,
              });
            }

            return { options, value: selectedCity };
          }),
        )
        .subscribe(({ options, value }) => {
          this.options = options;
          this.value = value as string;
          this.isLoading = false;
        }),
    );
  }
  search(value: string): void {
    this.searchChange$.next(value);
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetLocation());
    this.Subscriptions$.forEach((sub) => sub.unsubscribe());
  }
}
