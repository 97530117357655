import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as actUserActions from './act-user.actions';
import { RegistrationService } from '@core/api/registration.service';

@Injectable()
export class ActUserStoreEffects {
  constructor(
    private devApi: RegistrationService,
    private actions$: Actions,
  ) {}

  getStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actUserActions.getStatus),
      concatMap((action) =>
        this.devApi.getUserInvitationStatus(action.param).pipe(
          map((result) =>
            actUserActions.getStatusSuccess({ getStatusResp: result }),
          ),
          catchError((errors) =>
            of(
              actUserActions.getStatusFailure({ errors: errors?.errors }),
              actUserActions.setErrorStatus(),
            ),
          ),
        ),
      ),
    ),
  );

  completeRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actUserActions.completeRegistration),
      concatMap((action) =>
        this.devApi.completeRegistration(action.actUser).pipe(
          map(() => actUserActions.getStatus({ param: action.param })),
          catchError((errors) =>
            of(
              actUserActions.completeRegistrationFailure({
                ValidationErrors: errors,
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
