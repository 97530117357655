import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ILocationState } from './location.interface';

const locationSelector = createFeatureSelector<ILocationState>('location');

export const selectCountries = createSelector(
  locationSelector,
  (state) => state.countries,
);

export const selectStates = createSelector(
  locationSelector,
  (state) => state.states,
);

export const selectCities = createSelector(
  locationSelector,
  (state) => state.cities,
);

export const selectSelectedCountry = createSelector(
  locationSelector,
  (state) => state.selectedCountry,
);
export const selectSelectedState = createSelector(
  locationSelector,
  (state) => state.selectedState,
);
export const selectSelectedCity = createSelector(
  locationSelector,
  (state) => state.selectedCity,
);

export const selectShowCountries = createSelector(
  locationSelector,
  (state) => state.showCountries,
);
export const selectShowStates = createSelector(
  locationSelector,
  (state) => state.showStates,
);

export const selectDefaultCountryId = createSelector(
  locationSelector,
  (state) => state.defaultCountryId,
);

export const selectCountryTimezone = createSelector(
  locationSelector,
  (state) => state.CountryTimzone,
);
