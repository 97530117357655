import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { CurrentUserEffects } from 'src/app/store/current-user/current-user.effects';

export const isloaded: CanActivateFn = (route, state): Observable<boolean> => {
  let store = inject(Store);
  let authService = inject(CurrentUserEffects);

  // store.dispatch(getCurrentUserData());

  return authService.isAuthorized;
};
