import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationStatus } from '@shared/utility/global-enums';
import {
  IApiCredentialsUpdate,
  IChannelConfigurations,
  IDataSourceSettings,
  IDataSourceTypes,
  IDeliveryMethod,
  IOrganization,
  IOrganizationApiCredentials,
  IOrganizationRequest,
  IOrganizationResult,
  IUtilityServiceSettings,
  UtilityService,
} from '@features/organizations/store/organizations.interface';
import { IPagingRequest } from '@shared/utility/global-enums';
import { Data } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  apiBaseUrl = '';
  endpoint = 'departments';

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getOrganizations(
    pagingRequest: IPagingRequest,
    departmentUID: string,
    filter: any,
    search: string,
  ): Observable<any> {
    return this.http.get<IOrganizationResult>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations`,
      {
        params: {
          PageNumber: pagingRequest.pageNumber,
          PageSize: pagingRequest.pageSize,
          OrderBy: pagingRequest.orderBy,
          IsDescending: pagingRequest.isDescending,
          statusId: filter.StatusId,
          countryId: filter.CountryId,
          weatherDataRequired: filter.WeatherDataRequired,
          search,
        },
      },
    );
  }
  getOrganizationsFilters(departmentUID: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/filters`,
    );
  }
  getOrganizationTypes(departmentUID: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/types`,
    );
  }

  createOrganization(
    organization: IOrganization,
    departmentUID: string,
  ): Observable<any> {
    return this.http.post<IOrganization>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations`,
      organization,
    );
  }
  updateOrganization(
    organization: IOrganization,
    departmentUID: string,
  ): Observable<any> {
    return this.http.put<IOrganization>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organization.organizationUID}`,
      organization,
    );
  }

  deleteOrganization(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}`,
    );
  }

  activateOrganization(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/status`,
      {
        organizationStatus: OrganizationStatus.Active,
      },
    );
  }

  deActivateOrganization(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/status`,
      {
        organizationStatus: OrganizationStatus.Deactivated,
      },
    );
  }

  getOrganizationApiCredentials(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.get<IOrganizationApiCredentials>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/apiCredentials`,
    );
  }

  updateOrganizationApiCredentials(
    prop: {
      organizationUID: string;
      apiCredentials: IApiCredentialsUpdate;
    },
    departmentUID: string,
  ): Observable<any> {
    return this.http.put<IApiCredentialsUpdate>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${prop.organizationUID}/apiCredentials`,
      {
        isActive: prop.apiCredentials.isActive,
        maxCallsPerDay: prop.apiCredentials.maxCallsPerDay,
        maxCallsPerHour: prop.apiCredentials.maxCallsPerHour,
      },
    );
  }

  renewSecretOrganizationApiCredentials(
    prop: {
      organizationUID: string;
    },
    departmentUID: string,
  ): Observable<any> {
    return this.http.put<void>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${prop.organizationUID}/apiCredentials/renewSecret`,
      {},
    );
  }

  getOrganizationRequests(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.get<IOrganizationRequest>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/requests`,
    );
  }

  updateOrganizationRequest(
    prop: {
      organizationUID: string;
      organizationRequests: IOrganizationRequest[];
    },
    departmentUID: string,
  ): Observable<any> {
    return this.http.put<IOrganizationRequest>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${prop.organizationUID}/requests`,
      { organizationRequests: prop.organizationRequests },
    );
  }

  getDataSourceSettingsList(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.get<IUtilityServiceSettings[]>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/datasourcesettings`,
    );
  }

  getLastProcessing(
    organizationUID: string,
    departmentUID: string,
  ): Observable<Date> {
    return this.http.get<Date>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/lastProcessing`,
    );
  }

  getDataSourceSettings(
    organizationUID: string,
    dataSourceSettingUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.get<IDataSourceSettings>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/datasourcesettings/${dataSourceSettingUID}`,
    );
  }

  getDataSourceSettingsSourcetypes(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.get<IDataSourceTypes[]>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/datasourcesettings/sourcetypes`,
    );
  }

  addDataSourceSettings(
    organizationUID: string,
    dataSourceSetting: IDataSourceSettings,
    departmentUID: string,
    utilityServiceID: UtilityService,
  ): Observable<any> {
    return this.http.post(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/datasourcesettings`,
      {
        utilityServiceID,
        ...dataSourceSetting,
      },
    );
  }

  EditDataSourceSettings(
    organizationUID: string,
    dataSourceSetting: IDataSourceSettings,
    departmentUID: string,
    utilityServiceID: UtilityService,
  ): Observable<any> {
    return this.http.put(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/datasourcesettings/${dataSourceSetting.dataSourceSettingUID}`,
      {
        utilityServiceID,
        sourceTypeID: dataSourceSetting.sourceTypeID,
        enabled: dataSourceSetting.enabled,
        parameters: dataSourceSetting.parameters,
      },
    );
  }

  deleteDataSourceSettings(
    organizationUID: string,
    dataSourceSettingUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.delete(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/datasourcesettings/${dataSourceSettingUID}`,
    );
  }

  getChannelConfigurations(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.get<IChannelConfigurations[]>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/channelconfigurations`,
    );
  }
  addChannelConfigurations(
    organizationUID: string,
    departmentUID: string,
    channelConfig: IChannelConfigurations,
  ): Observable<any> {
    return this.http.post(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/channelconfigurations`,
      {
        name: channelConfig.channelConfigurationName,
        mappings: channelConfig.mappings,
      },
    );
  }
  editChannelConfigurations(
    organizationUID: string,
    departmentUID: string,
    channelConfig: IChannelConfigurations,
  ): Observable<any> {
    return this.http.put(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/channelconfigurations/${channelConfig.channelConfigurationUID}`,
      {
        name: channelConfig.channelConfigurationName,
        mappings: channelConfig.mappings,
      },
    );
  }

  deleteChannelConfigurations(
    organizationUID: string,
    departmentUID: string,
    channelConfigurationUID: string,
  ): Observable<any> {
    return this.http.delete(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/channelconfigurations/${channelConfigurationUID}`,
    );
  }

  getDeliveryMethods(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/deliveryMethods`,
    );
  }

  getDeliveryMethodsFileTransferTypes(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/deliveryMethods/fileTransferTypes`,
    );
  }

  addDeliveryMethod(
    organizationUID: string,
    departmentUID: string,
    deliveryMethod: IDeliveryMethod,
  ): Observable<any> {
    return this.http.post(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/deliveryMethods`,
      deliveryMethod,
    );
  }

  updateDeliveryMethod(
    organizationUID: string,
    departmentUID: string,
    deliveryMethod: IDeliveryMethod,
    deliveryMethodUID: string,
  ): Observable<any> {
    return this.http.put(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/deliveryMethods/${deliveryMethodUID}`,
      deliveryMethod,
    );
  }

  deleteDeliveryMethod(
    organizationUID: string,
    departmentUID: string,
    deliveryMethodUID: string,
  ): Observable<any> {
    return this.http.delete(
      `${this.apiBaseUrl}${this.endpoint}/${departmentUID}/organizations/${organizationUID}/deliveryMethods/${deliveryMethodUID}`,
    );
  }
}
