import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NzI18nService } from 'ng-zorro-antd/i18n';

@Injectable({ providedIn: 'root' })
export class DateManagerService {
  private offSetCorrection: number = moment().utcOffset() * 2; // -180 is the offset for the timezone gmt+3
  private locale: string = this.nzi18nService.getLocale().locale;

  datePickerFormat: string =
    this.nzi18nService.getLocale().DatePicker.lang.dateFormat;

  dateTimePickerFormat: string =
    this.nzi18nService.getLocale().DatePicker.lang.dateFormat + ' HH:mm';

  constructor(private nzi18nService: NzI18nService) {}

  // correct API date to local date
  //   correctDateLocal(date: Date): Date {
  //     return date;
  //   }

  //   extractOnlyDate(date: Date): Date {
  //     return date;
  //   }

  //   // clear Offset from date
  //   clearOffsetDate(date: Date): Date {
  //     const offset = date.getTimezoneOffset();
  //     return new Date(date.getTime() + offset * 60 * 1000);
  //   }
  // offset correction with moment

  getOnlyTime(date: Date): Date {
    const dateWithNewTime = moment(date).add(
      this.offSetCorrection / 2,
      'minutes',
    );

    return dateWithNewTime.toDate();
  }

  setFormatDate(
    date: Date,
    format: DateFormatType = DateFormatType.DATETIME,
  ): string {
    const _date: moment.Moment = moment(date);
    if (!_date.isValid()) {
      return '-';
    }

    return _date
      .utc()
      .utcOffset(this.offSetCorrection)
      .locale(this.locale)
      .format(format);
  }

  setFormatDateWithOffsetCorrection(
    date: Date,
    format: DateFormatType,
  ): string {
    return moment.utc(date).zone(this.offSetCorrection).format(format);
  }

  getOnlyDate(date: Date): Date {
    const utcDate = moment.utc(moment(date).format('YYYY-MM-DD'));
    // Set the time components to 00:00:00.000 in UTC
    utcDate.set('hour', 0);
    utcDate.set('minute', 0);
    utcDate.set('second', 0);
    utcDate.set('millisecond', 0);
    // Convert the moment object back to a Date object
    return utcDate.toDate();
  }
}
export enum DateFormatType {
  DATETIME = 'L LT', // 'MM/DD/YYYY HH:mm',
  DATE = 'L', // 'MM/DD/YYYY',
  TIME = 'LT', // 'HH:mm',
}

export interface LocalDate {
  utc: string;
  offset: number;
}
