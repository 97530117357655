import {
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  signal,
} from '@angular/core';
import { FilterItem } from './xpw-abstract-table-list.component';
import { ErrorKeyLabels } from '@shared/utility/errors-keys-msg';
import { EnumTitleType } from '@shared/utility/global-enums-titles';

interface FilterDataWithTitle extends FilterItem {
  computedTitle: string;
  originalItem: FilterItem;
}

@Component({
  selector: 'xpw-table-header',
  template: `
    <thead>
      <tr>
        <th>
          <span i18n>Filtered By:</span>

          <span
            style="border: 1px solid #e6e6e6;
            padding: 10px;
            margin: 5px;
            border-radius: 2px;"
            *ngFor="let filtertype of processedFilters() | keyvalue"
          >
            <span>{{ stringKey[filtertype.key] }}: </span>
            <span *ngFor="let fData of filtertype.value">
              <xpw-tag-closable
                [label]="fData.computedTitle"
                (onCloseClick)="propertyRemoved(fData)"
              />
            </span>
          </span>
        </th>
        <xpw-button (click)="clearFilters()" type="text" i18n="@@Clear">
          <xpw-icon icon="clear" />
          Clear
        </xpw-button>
      </tr>
    </thead>
  `,
})
export class XpwTableHeaderComponent {
  private filterData = signal<{ [key: string]: any[] }>({});
  private titles = signal<{ [key: string]: EnumTitleType }>({});

  @Input() set filterDataForHeader(value: { [key: string]: any[] }) {
    console.log('filterDataForHeader', value);
    this.filterData.set(value);
  }

  @Input() set filterTitles(value: { [key: string]: EnumTitleType }) {
    this.titles.set(value);
  }

  @Output() filterPropertyRemoved = new EventEmitter<FilterItem>();
  @Output() clearAllFilters = new EventEmitter<void>();

  stringKey = ErrorKeyLabels;

  processedFilters = computed(() => {
    const result: { [key: string]: FilterDataWithTitle[] } = {};

    Object.entries(this.filterData()).forEach(([key, items]) => {
      // if (!Array.isArray(items)) return;
      result[key] = items.map((item) => ({
        ...item,
        computedTitle: this.computeTitle(item),
        originalItem: item, // Store the original item reference
      }));
    });

    return result;
  });

  private computeTitle(fData: any): string {
    return this.titles()[fData.type]
      ? this.titles()[fData.type][fData.value]
      : fData.label;
  }

  clearFilters() {
    this.clearAllFilters.emit();
  }

  propertyRemoved(
    removedProperty: FilterDataWithTitle & { originalItem: FilterItem },
  ) {
    this.filterPropertyRemoved.emit(removedProperty.originalItem);
  }
}
