import { createAction, props } from '@ngrx/store';
import {
  DataSourceType,
  IApiCredentials,
  IApiCredentialsUpdate,
  IChannelConfigurations,
  IDataSourceSettings,
  IDataSourceTypes,
  IDeliveryMethod,
  IDeliveryMethodFileTransferType,
  IOrganization,
  IOrganizationChannelConfigurations,
  IOrganizationRequest,
  IOrganizationResult,
  IUtilityServiceSettings,
  UtilityService,
} from './organizations.interface';
import {
  IFilterOptions,
  OrganizationStatus,
} from '@shared/utility/global-enums';
import { IPagingRequest } from '@shared/utility/global-enums';

export enum OrganizationsActionType {
  SetDepartmentUID = '[Organization] Set Department UID',
  SetSelectedOrganizationUID = '[Organization] Set Selected Organization UID',
  GetOrganization = '[Organization] Get Organization',
  GetOrganizationSuccess = '[Organization] Get Organization Success',
  GetOrganizationList = '[Organization] Get Organization List',
  GetOrganizationsFilters = '[Organization] Get Organization Filters',
  GetOrganizationsFiltersSuccess = '[Organization] Get Organization Filters Success',
  // selectRootOrganization = '[Organization] Select root Organization',
  // selectRootOrganizationSuccess = '[Organization] Select root Organization Success',
  GetOrganizationCountries = '[Organization] Get Organization Countries',
  GetOrganizationCountriesSuccess = '[Organization] Get Organization Countries Success',
  GetOrganizationListSuccess = '[Organization] Get Organization List Success',
  OrganizationError = '[Organization] Error',
  GetOrganizationTypes = '[Organization] Get Organization Types',
  GetOrganizationTypesSuccess = '[Organization] Get Organization Types Success',
  GetLastProcessing = '[Organization] Get Last Processing date',
  GetLastProcessingSuccess = '[Organization] Get Last Processing date Success',
  UpdateOrganization = '[Organization] Update Organization',
  UpdateOrganizationSuccess = '[Organization] Update Organization Success',
  CreateNewOrganization = '[Organization] Create New Organization',
  CreateNewOrganizationSuccess = '[Organization] Create New Organization Success',
  SetNewOrganization = '[Organization] Set New Organization',
  DeleteOrganization = '[Organization] Delete Organization',
  DeleteOrganizationSuccess = '[Organization] Delete Organization Success',
  ActivateOrganization = '[Organization] Activate Organization',
  DeActivateOrganization = '[Organization] DeActivate Organization',
  paginationChange = '[Organization] Pagination Change',
  filterChange = '[Organization] Filter Change',
  searchChange = '[Organization] Search Change',

  GetRequests = '[Organization] Get request',
  GetRequestsSuccess = '[Organization] Get request Successs',
  UpdateRequests = '[Organization] Update request',
  UpdateRequestsSuccess = '[Organization] Update request Successs',

  GetApiCredentials = '[Organization] Get api credentials',
  GetApiCredentialsSuccess = '[Organization] Get api credentials Successs',
  UpdateApiCredentials = '[Organization] Update api credentials',
  UpdateApiCredentialsSuccess = '[Organization] Update api credentials Successs',
  RenewApiCredentials = '[Organization] Renew api credentials',
  RenewApiCredentialsSuccess = '[Organization] Renew api credentials Successs',

  GetChannelConfigurations = '[Organization] Get Channel Configurations',
  GetCurrentChannelConfigurations = '[Organization] Get Channel Configurations for Current user department',
  GetChannelConfigurationsSuccess = '[Organization] Get Channel Configurations Successs',
  SetSelectedChannelConfigurations = '[Organization] Set Selected Channel Configuration to Store',
  AddChannelConfigurations = '[Organization] Add Channel Configurations',
  AddChannelConfigurationsSuccess = '[Organization] Add Channel Configurations Successs',
  EditChannelConfigurations = '[Organization] Update Channel Configurations',
  EditChannelConfigurationsSuccess = '[Organization] Update Channel Configurations Successs',
  DeleteChannelConfigurations = '[Organization] Delete Channel Configurations',
  DeleteChannelConfigurationsSuccess = '[Organization] Delete Channel Configurations Successs',

  GetDataSourceSettingsList = '[Organization] Get DaraSourceSettingsList',
  GetDataSourceSettingsListSuccess = '[Organization] Get DataSourceSettingsList Successs',
  SetEditDataSourceSettings = '[Organization] Selecte DaraSourceSetting from the Store ',
  EditDataSourceSettings = '[Organization] edit DataSourceSettings',
  EditDataSourceSettingsSuccess = '[Organization] edit DataSourceSettings Successs',
  GetDataSourceTypes = '[Organization] Get GetDataSourceTypes',
  GetDataSourceTypesSuccess = '[Organization] Get GetDataSourceTypes Success',
  GetDataSourceSettingsSuccess = '[Organization] Get DaraSourceSettings Success',
  SetNewDataSourceSettings = '[Organization] Create new DaraSourceSetting in the Store ',
  SetDataSourceParams = '[Organization] DaraSourceSettings set params',
  AddDataSourceSettings = '[Organization] Add DaraSourceSettings',
  AddDataSourceSettingsSuccess = '[Organization] Add DaraSourceSettings Success',
  DeleteDataSourceSettings = '[Organization] Delete DaraSourceSettings',
  DeleteDataSourceSettingsSuccess = '[Organization] Delete DaraSourceSettings Success',
  validationErrors = '[Organization] Got validations error',
  ShowErrorMessage = '[Organization] Show Errors Messages',
  ShowSuccessMessage = '[Organization] Show Messages Success',
  ResetOrganizationFilterAndSearch = '[Organization] Reset Filter And Search',

  ShowIconUploadPopup = '[Organization] Show Icon Upload Popup',
  CloseIconUploadPopup = '[Organization] Close Icon Upload Popup',
  PutIconToSelectedOrganization = '[Organization] Put uploded Icon To the Selected Organization store',
  DeleteOrganizationIcon = '[Organization] Delete OrganizationIcon',
  ResetOrganizationState = '[Organization] Reset Organization State',

  GetDeliveryMethodsFileTransferTypes = '[Organization] Get Delivery Methods File Transfer Types',
  GetDeliveryMethodsFileTransferTypesSuccess = '[Organization] Get Delivery Methods File Transfer Types Success',
  GetDeliveryMethods = '[Organization] Get Delivery Methods',
  GetDeliveryMethodsSuccess = '[Organization] Get Delivery Methods Success',
  SetSelectedDeliveryMethod = '[Organization] Set Selected Delivery Method',
  AddDeliveryMethod = '[Organization] Add Delivery Method',
  AddDeliveryMethodSuccess = '[Organization] Add Delivery Method Success',
  UpdateDeliveryMethod = '[Organization] Update Delivery Method',
  UpdateDeliveryMethodSuccess = '[Organization] Update Delivery Method Success',
  DeleteDeliveryMethod = '[Organization] Delete Delivery Method',
  DeleteDeliveryMethodSuccess = '[Organization] Delete Delivery Method Success',
}

export const setDepartmentUID = createAction(
  OrganizationsActionType.SetDepartmentUID,
  props<{ departmentUID: string }>(),
);
export const setSelectedOrganizationUID = createAction(
  OrganizationsActionType.SetSelectedOrganizationUID,
  props<{ organizationUID: string }>(),
);
export const paginationChange = createAction(
  OrganizationsActionType.paginationChange,
  props<{
    pagingRequest: IPagingRequest;
    filter: any;
  }>(),
);
export const filterChange = createAction(
  OrganizationsActionType.filterChange,
  props<{ filter: any }>(),
);
export const searchChange = createAction(
  OrganizationsActionType.searchChange,
  props<{ search: string }>(),
);
export const getOrganization = createAction(
  OrganizationsActionType.GetOrganization,
  props<{ organizationUID: string }>(),
);
export const GetOrganizationsFilters = createAction(
  OrganizationsActionType.GetOrganizationsFilters,
);

export const GetLastProcessing = createAction(
  OrganizationsActionType.GetLastProcessing,
);
export const GetLastProcessingSuccess = createAction(
  OrganizationsActionType.GetLastProcessingSuccess,
  props<{ lastProcessing: Date }>(),
);

export const GetOrganizationsFiltersSuccess = createAction(
  OrganizationsActionType.GetOrganizationsFiltersSuccess,
  props<{ filterOptions: IFilterOptions[] }>(),
);

export const getOrganizationCountries = createAction(
  OrganizationsActionType.GetOrganizationCountries,
);
export const getOrganizationCountriesSuccess = createAction(
  OrganizationsActionType.GetOrganizationCountriesSuccess,
  props<{ countries: any[] }>(),
);

export const getOrganizationListSuccess = createAction(
  OrganizationsActionType.GetOrganizationListSuccess,
  props<{ organizations: IOrganizationResult; organizationTypes: any[] }>(),
);

export const OrganizationError = createAction(
  OrganizationsActionType.OrganizationError,
  props<{ error: any }>(),
);

export const getOrganizationTypes = createAction(
  OrganizationsActionType.GetOrganizationTypes,
);

export const getOrganizationTypesSuccess = createAction(
  OrganizationsActionType.GetOrganizationTypesSuccess,
  props<{ organizationTypes: any[] }>(),
);

export const updateOrganization = createAction(
  OrganizationsActionType.UpdateOrganization,
  props<{ organization: IOrganization }>(),
);

export const UpdateOrganizationSuccess = createAction(
  OrganizationsActionType.UpdateOrganizationSuccess,
  props<{ updatedOrganization: IOrganization }>(),
);

export const createNewOrganization = createAction(
  OrganizationsActionType.CreateNewOrganization,
  props<{ organization: IOrganization }>(),
);

export const createNewOrganizationSuccess = createAction(
  OrganizationsActionType.CreateNewOrganizationSuccess,
  props<{ createdOrganization: IOrganization }>(),
);

export const setNewOrganization = createAction(
  OrganizationsActionType.SetNewOrganization,
);

export const deleteOrganization = createAction(
  OrganizationsActionType.DeleteOrganization,
  props<{ organizationUID: string }>(),
);
export const deleteOrganizationSuccess = createAction(
  OrganizationsActionType.DeleteOrganizationSuccess,
  props<{ organizationUID: string }>(),
);
export const activateOrganization = createAction(
  OrganizationsActionType.ActivateOrganization,
  props<{ updatedOrganization: IOrganization }>(),
);

export const deactivateOrganization = createAction(
  OrganizationsActionType.DeActivateOrganization,
  props<{ updatedOrganization: IOrganization }>(),
);

export const getOrganizationSuccess = createAction(
  OrganizationsActionType.GetOrganizationSuccess,
  props<{ organization: IOrganization }>(),
);

export const getOrganizationList = createAction(
  OrganizationsActionType.GetOrganizationList,
  props<{
    pagingRequest: IPagingRequest;
  }>(),
);

export const validationError = createAction(
  OrganizationsActionType.validationErrors,
  props<{ ValidationErrors: any }>(),
);

export const showErrors = createAction(
  OrganizationsActionType.ShowErrorMessage,
  props<{ message: string }>(),
);

export const showSuccessMessage = createAction(
  OrganizationsActionType.ShowSuccessMessage,
  props<{ message: string }>(),
);

export const showIconUploadPopup = createAction(
  OrganizationsActionType.ShowIconUploadPopup,
);
export const closeIconUploadPopup = createAction(
  OrganizationsActionType.CloseIconUploadPopup,
);

export const deleteOrganizationIcon = createAction(
  OrganizationsActionType.DeleteOrganizationIcon,
  props<{ organizationUID: string }>(),
);

export const putIconToSelectedOrganization = createAction(
  OrganizationsActionType.PutIconToSelectedOrganization,
  props<{ iconImage: string }>(),
);

// Actions for API manager(Data collection) :
//    Organization request actions:
export const getOrganizationRequests = createAction(
  OrganizationsActionType.GetRequests,
  props<{ organizationUID: string }>(),
);

export const getOrganizationRequestsSuccess = createAction(
  OrganizationsActionType.GetRequestsSuccess,
  props<{ organizationUID: string; orgRequestList: IOrganizationRequest[] }>(),
);

export const updateOrganizationRequests = createAction(
  OrganizationsActionType.UpdateRequests,
  props<{
    organizationUID: string;
    organizationRequests: IOrganizationRequest[];
  }>(),
);

export const updateOrganizationRequestsSuccess = createAction(
  OrganizationsActionType.UpdateRequestsSuccess,
);

// End Organization request actions
// Organization api credential actions:
export const getOrganizationApiCredentials = createAction(
  OrganizationsActionType.GetApiCredentials,
  props<{ organizationUID: string }>(),
);

export const getOrganizationApiCredentialsSuccess = createAction(
  OrganizationsActionType.GetApiCredentialsSuccess,
  props<{ organizationUID: string; orgApiCredentials: IApiCredentials }>(),
);

export const updateOrganizationApiCredentials = createAction(
  OrganizationsActionType.UpdateApiCredentials,
  props<{
    organizationUID: string;
    apiCredentials: IApiCredentialsUpdate;
  }>(),
);

export const updateOrganizationApiCredentialsSuccess = createAction(
  OrganizationsActionType.UpdateApiCredentialsSuccess,
);

export const renewSecretOrganizationApiCredentials = createAction(
  OrganizationsActionType.RenewApiCredentials,
  props<{
    organizationUID: string;
  }>(),
);

export const renewSecretOrganizationApiCredentialsSuccess = createAction(
  OrganizationsActionType.RenewApiCredentialsSuccess,
);

// End Organization api credential actions
// Data source settings actions :

export const getDataSourceSettingsList = createAction(
  OrganizationsActionType.GetDataSourceSettingsList,
  props<{ organizationUID: string }>(),
);
export const getDataSourceSettingsListSuccess = createAction(
  OrganizationsActionType.GetDataSourceSettingsListSuccess,
  props<{
    organizationUID: string;
    dataSourceSettingsList: IUtilityServiceSettings[];
  }>(),
);

export const getDataSourceTypes = createAction(
  OrganizationsActionType.GetDataSourceTypes,
  props<{ organizationUID: string }>(),
);

export const GetDataSourceTypesSuccess = createAction(
  OrganizationsActionType.GetDataSourceTypesSuccess,
  props<{ dataSourceTypes: IDataSourceTypes[] }>(),
);

export const EditDataSourceSettings = createAction(
  OrganizationsActionType.EditDataSourceSettings,
  props<{
    dataSourceSetting: IDataSourceSettings;
  }>(),
);

export const EditDataSourceSettingsSuccess = createAction(
  OrganizationsActionType.EditDataSourceSettingsSuccess,
);

export const SetEditDataSourceSettings = createAction(
  OrganizationsActionType.SetEditDataSourceSettings,
  props<{
    utilityServiceID: UtilityService;
    dataSourceSettingUID: string;
  }>(),
);

export const getDataSourceSettingsSuccess = createAction(
  OrganizationsActionType.GetDataSourceSettingsListSuccess,
  props<{
    dataSourceSetting: IDataSourceSettings;
  }>(),
);
export const SetNewDataSourceSettings = createAction(
  OrganizationsActionType.SetNewDataSourceSettings,
  props<{
    utilityServiceID: UtilityService;
  }>(),
);

export const setDataSourceSettingsParams = createAction(
  OrganizationsActionType.SetDataSourceParams,
  props<{
    sourceTypeID: DataSourceType;
  }>(),
);

export const addDataSourceSettings = createAction(
  OrganizationsActionType.AddDataSourceSettings,
  props<{
    dataSourceSetting: IDataSourceSettings;
  }>(),
);

export const addDataSourceSettingsSuccess = createAction(
  OrganizationsActionType.AddDataSourceSettingsSuccess,
);

export const deleteDataSourceSettings = createAction(
  OrganizationsActionType.DeleteDataSourceSettings,
  props<{
    dataSourceSettingUID: string;
  }>(),
);

export const deleteDataSourceSettingsSuccess = createAction(
  OrganizationsActionType.DeleteDataSourceSettingsSuccess,
);

export const resetOrganizationFilterAndSearch = createAction(
  OrganizationsActionType.ResetOrganizationFilterAndSearch,
);

export const resetOrganizationState = createAction(
  OrganizationsActionType.ResetOrganizationState,
);
// End Data source settings

// Channel congiguration Actions start
export const getChannelConfigurations = createAction(
  OrganizationsActionType.GetChannelConfigurations,
  props<{ organizationUID: string | null }>(),
);
export const getCurrentChannelConfigurations = createAction(
  OrganizationsActionType.GetCurrentChannelConfigurations,
  props<{ organizationUID: string | null }>(),
);

export const getChannelConfigurationsSuccess = createAction(
  OrganizationsActionType.GetChannelConfigurationsSuccess,
  props<{
    organizationUID: string;
    channelConfigurationList: IChannelConfigurations[];
  }>(),
);

export const setSelectedChannelConfigurations = createAction(
  OrganizationsActionType.SetSelectedChannelConfigurations,
  props<{
    channelConfiguration: IChannelConfigurations;
  }>(),
);

export const addChannelConfigurations = createAction(
  OrganizationsActionType.AddChannelConfigurations,
  props<{
    channelConfiguration: IChannelConfigurations;
  }>(),
);

export const addChannelConfigurationsSuccess = createAction(
  OrganizationsActionType.AddChannelConfigurationsSuccess,
  props<{
    channelConfiguration: IChannelConfigurations;
  }>(),
);

export const editChannelConfigurations = createAction(
  OrganizationsActionType.EditChannelConfigurations,
  props<{
    channelConfiguration: IChannelConfigurations;
  }>(),
);

export const editChannelConfigurationsSuccess = createAction(
  OrganizationsActionType.EditChannelConfigurationsSuccess,
  props<{
    channelConfiguration: IChannelConfigurations;
  }>(),
);

export const deleteChannelConfigurations = createAction(
  OrganizationsActionType.DeleteChannelConfigurations,
  props<{
    channelConfigurationUID: string;
  }>(),
);

export const deleteChannelConfigurationsSuccess = createAction(
  OrganizationsActionType.DeleteChannelConfigurationsSuccess,
  props<{
    channelConfigurationUID: string;
  }>(),
);

//End Channel configuration Actions

// Delivery methods actions:
export const getDeliveryMethods = createAction(
  OrganizationsActionType.GetDeliveryMethods,
  props<{ organizationUID: string }>(),
);

export const getDeliveryMethodsSuccess = createAction(
  OrganizationsActionType.GetDeliveryMethodsSuccess,
  props<{ deliveryMethods: IDeliveryMethod[] }>(),
);

export const setSelectedDeliveryMethod = createAction(
  OrganizationsActionType.SetSelectedDeliveryMethod,
  props<{ deliveryMethod: IDeliveryMethod }>(),
);

export const addDeliveryMethod = createAction(
  OrganizationsActionType.AddDeliveryMethod,
  props<{ deliveryMethod: IDeliveryMethod }>(),
);

export const addDeliveryMethodSuccess = createAction(
  OrganizationsActionType.AddDeliveryMethodSuccess,
  props<{ deliveryMethod: IDeliveryMethod }>(),
);

export const updateDeliveryMethod = createAction(
  OrganizationsActionType.UpdateDeliveryMethod,
  props<{ deliveryMethod: IDeliveryMethod; deliveryMethodUID: string }>(),
);

export const updateDeliveryMethodSuccess = createAction(
  OrganizationsActionType.UpdateDeliveryMethodSuccess,
  props<{ deliveryMethod: IDeliveryMethod }>(),
);

export const getDeliveryMethodsFileTransferTypes = createAction(
  OrganizationsActionType.GetDeliveryMethodsFileTransferTypes,
);

export const getDeliveryMethodsFileTransferTypesSuccess = createAction(
  OrganizationsActionType.GetDeliveryMethodsFileTransferTypesSuccess,
  props<{
    deliveryMethodsFileTransferTypes: IDeliveryMethodFileTransferType[];
  }>(),
);

export const deleteDeliveryMethod = createAction(
  OrganizationsActionType.DeleteDeliveryMethod,
  props<{ deliveryMethodUID: string }>(),
);

export const deleteDeliveryMethodSuccess = createAction(
  OrganizationsActionType.DeleteDeliveryMethodSuccess,
  props<{ deliveryMethodUID: string }>(),
);

// End Delivery methods actions
