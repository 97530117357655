import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  Type,
} from '@angular/core';
import { ErrorKeyLabels } from '@shared/utility/errors-keys-msg';
// import { uuid } from '../utils/generators';

@Component({ template: '' })
export class AbstractNgModelComponent<T = any> implements ControlValueAccessor {
  //   @Input()
  //   cid: string = uuid();

  @Input()
  disabled: boolean;

  @Input('isOptional') isOptional: boolean = false;

  @Input()
  displayErrors: boolean = true;

  @Input()
  set value(value: T) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): T {
    return this._value;
  }

  onChange: (value: T) => {};
  onTouched: () => {};

  protected _value: T;
  protected cdRef: ChangeDetectorRef;

  ngControl: NgControl;

  constructor(public injector: Injector) {
    this.cdRef = injector.get<ChangeDetectorRef>(
      ChangeDetectorRef as Type<ChangeDetectorRef>,
    );
    setTimeout(() => (this.ngControl = this.injector.get(NgControl)));
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: T): void {
    this._value = value;
    setTimeout(() => this.cdRef.detectChanges(), 0);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // ng zorro form control functions

  errorStatus(): ErrorStatus {
    if (this.ngControl?.control) {
      const errors = this.ngControl.control.errors;
      if (errors) {
        return ErrorStatus.error;
      }
    }
    return ErrorStatus.success;
  }
  getErrorLabel(): string {
    if (this.displayErrors && this.ngControl?.control) {
      const errors = this.ngControl.control.errors;
      if (errors) {
        // console.log('getErrorLabel errors: ', errors);
        // console.log('errorKey', errorKey.split('$'));

        const errorKey = Object.keys(errors)[0];
        if (errorKey.includes('$')) {
          const key = errorKey.split('$')[0];
          const value = Number(errorKey.split('$')[1]);
          const label = ErrorKeyLabels[errorKey](value);
          return label;
        }
        return ErrorKeyLabels[errorKey];
      }
    }
    return '';
  }
}

enum ErrorStatus {
  error = 'error',
  success = 'success',
  warning = 'warning',
  validating = 'validating',
}
