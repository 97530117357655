import { NgModule } from '@angular/core';

import { DeviceSelectionViewComponent } from './selection-view/device-selection-view.component';
import { DeviceTreeComponent } from './device-tree/device-tree.component';
import { IconsModule } from '@shared/xpw-ui-kit/xpw-icons/xpw-icons.module';

@NgModule({
  imports: [IconsModule],
  exports: [DeviceSelectionViewComponent, DeviceTreeComponent],
  declarations: [DeviceSelectionViewComponent, DeviceTreeComponent],
  providers: [],
})
export class DeviceSelectionViewModule {}
