import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ICurrentUser } from './current-user.interface';

// Define a feature selector to select the ICurrentUser state
const selectCurrentUserFeature =
  createFeatureSelector<ICurrentUser>('currentUser');

// Create a selector to get the login property from the IUser state
export const selectLoggedIn = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.loginData,
);

// Create a selector to get the list of roles from the IUser state
export const selectCurrentUserRoles = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.roles,
);
export const selectCurrentUserProperties = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state,
);
export const selectCurrentUserOrganizationUID = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.currentOrganization.uid,
);
export const selectCurrentUserOrganizationLocation = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.currentOrganization.location,
);

export const selectCurrentUserDepartmentUID = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.currentDepartmentUID,
);

export const selectCurrentUserMeterGroups = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.accessObjects.meterGroups,
);

export const selectCurrentAccessObjects = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.accessObjects,
);

export const selectCurrentOrganizations = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.loginData,
);

export const selectCurrentUserOrganizationUIDLocation = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.currentOrganization.location,
);

export const selectCurrentUserUID = createSelector(
  selectCurrentUserFeature,
  (state: ICurrentUser) => state.userUID,
);
/* export const selectDefaultPage = createSelector(
  selectCurrentUserFeature,
  (state: IUser) => state.defaultPage
); */
