import { IDepartment } from './departments.interface';

export interface IDepartmentState {
  departments: IDepartment[];
  filteredDepartments: IDepartment[];
  selectedDepartment: IDepartment;
  successResult: boolean;
  storageList: string[];
  ValidationErrors: any;
  newDepartment: boolean;
  searchText: string;
}

export const initialDepartmentState: IDepartmentState = {
  departments: [],
  filteredDepartments: [],
  selectedDepartment: {
    departmentUID: '',
    departmentName: '',
    storageName: '',
    countryIds: [],
    defaultCountryId: 0,
    createDate: new Date(),
  },
  successResult: false,
  storageList: [],
  ValidationErrors: null,
  newDepartment: false,
  searchText: '',
};
