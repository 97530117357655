import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ImportFileStatus } from '@features/import-history/import-history-store/import-history.interface';

@Component({
  selector: 'xpw-import-status-tag',
  template: `
    <nz-tag [nzColor]="color">
      <xpw-icon [icon]="icon" />
      <span>{{ stringTitle }}</span>
    </nz-tag>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpwImportFileStatusTagComponent implements OnChanges {
  @Input() statusId: ImportFileStatus = null;
  stringTitle: string = '';
  icon: string | null = null;
  color: string = 'default';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['statusId']) {
      this.getData();
    }
  }
  getData() {
    switch (this.statusId) {
      case ImportFileStatus.Uploaded:
        this.stringTitle = $localize`Uploaded`;
        this.icon = 'upload';
        break;
      case ImportFileStatus.Pending:
        this.stringTitle = $localize`Pending...`;
        this.icon = 'hourglass';
        break;
      case ImportFileStatus.Processing:
        this.stringTitle = $localize`Processing`;
        this.icon = 'clock-circle';
        break;
      case ImportFileStatus.PartiallyCompleted:
        this.stringTitle = $localize`Partial`;
        this.color = 'yellow';
        this.icon = 'warning';
        break;
      case ImportFileStatus.Completed:
        this.stringTitle = $localize`Completed`;
        this.icon = 'check';
        this.color = 'green';
        break;
      case ImportFileStatus.Failed:
        this.stringTitle = $localize`Failed`;
        this.icon = 'failed';
        this.color = 'red';
        break;
      case ImportFileStatus.Canceled:
        this.stringTitle = $localize`Canceled`;
        this.icon = 'cancel';
        this.color = 'red';
        break;
      default:
        this.stringTitle = $localize`Unknown`;
        break;
    }
  }
}
