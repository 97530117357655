import { createAction, props } from '@ngrx/store';
import { IActUser, IGetStatusResponse } from './act-user.interface';

export enum ActUserActionType {
  GetStatus = '[ActUser component] Get status',
  GetStatusSuccess = '[ActUser component] Get status success',
  GetStatusFailure = '[ActUser component] Get status failure',
  SetErrorStatus = '[ActUser component] Set Error status',
  UpdateActUserForm = '[ActUser component] Update ActUser Form',
  completeRegistration = '[ActUser component] Complete registration',
  completeRegistrationSuccess = '[ActUser component] Complete registration success',
  completeRegistrationFailure = '[ActUser component] Complete registration failure',
}

export const setErrorStatus = createAction(ActUserActionType.SetErrorStatus);

export const getStatus = createAction(
  ActUserActionType.GetStatus,
  props<{ param: string }>(),
);

export const getStatusSuccess = createAction(
  ActUserActionType.GetStatusSuccess,
  props<{ getStatusResp: IGetStatusResponse }>(),
);

export const getStatusFailure = createAction(
  ActUserActionType.GetStatusFailure,
  props<{ errors: [] }>(),
);

export const updateActUserForm = createAction(
  ActUserActionType.UpdateActUserForm,
  props<{ actUser: IActUser }>(),
);

export const completeRegistration = createAction(
  ActUserActionType.completeRegistration,
  props<{ actUser: IActUser; param: string }>(),
);

export const completeRegistrationSuccess = createAction(
  ActUserActionType.completeRegistrationSuccess,
);

export const completeRegistrationFailure = createAction(
  ActUserActionType.completeRegistrationFailure,
  props<{ ValidationErrors: any }>(),
);
