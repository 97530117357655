import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { CommonModule } from '@angular/common';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { IconsProviderModule } from './icons-provider.module';
// import { IconsModule } from '@shared/xpw-ui-kit/xpw-icons/xpw-icons.module';
import { BreadCrumbsModule } from '../components/breadcrumbs/breadcrumbs.module';
import { MyCustomDirective } from './device-slider.directive';
import { DeviceSelectionViewModule } from '../components/device-selection/device-selection.module';
import { MainNavComponent } from '../components/main-nav/main-nav.component';
import { LanguageSwitcherComponent } from '@core/language/language.component';
import { NotificationsComponent } from '../components/notifications/notifications.component';
import { UserProfileComponent } from '../components/user-profile/user-profile.component';
import { OrganizationBadgeComponent } from '../components/organization-badge/organization-badge.component';

import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { EnsureModuleLoadedOnceGuard } from '@core/guards/ensure-module-loaded-once.guard';
import { RouterModule } from '@angular/router';

import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  imports: [
    NzDropDownModule,
    NzBadgeModule,
    NzAvatarModule,
    NzLayoutModule,
    NzMenuModule,
    NzTagModule,
    NzCollapseModule,
    NzButtonModule,
    CommonModule,
    IconsProviderModule,
    BreadCrumbsModule,
    DeviceSelectionViewModule,
    RouterModule,
  ],
  exports: [LayoutComponent, MyCustomDirective],
  declarations: [
    LayoutComponent,
    MyCustomDirective,
    MainNavComponent,
    NotificationsComponent,
    UserProfileComponent,
    OrganizationBadgeComponent,
    LanguageSwitcherComponent,
  ],
  providers: [],
})
export class LayoutModule extends EnsureModuleLoadedOnceGuard {}
