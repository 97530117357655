import { FileImportType } from '@features/import-history/import-history-store/import-history.interface';
import { IFileImportState, ImportType } from './file-import.interface';

export const fileImportInitialState: IFileImportState = {
  fileimportTypes: [],
  selectedFileImportType: ImportType.InsertMeters,

  currentFileData: null,
  popupVisible: false,
  response: null,
};
