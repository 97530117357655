import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as currentUserActions from './current-user.actions';
import * as organizationActions from '@features/organizations/store/organizations.actions';
import * as locationActions from '../location/location.actions';
import { ICurrentUser } from './current-user.interface';
import { ReplaySubject, of } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { DepartmentsService } from '@core/api/departments.service';
import { getDepartmentCountries } from '../location/location.actions';
import { AuthenticationService } from '@core/api/authentication.service';
import { getFileImportTypes } from '../file-import/file-import.actions';
import { AdminRolesList } from '@features/user/user-store/user.interface';

@Injectable()
export class CurrentUserEffects {
  constructor(
    public authServiceMsal: MsalService,
    private actions$: Actions,
    private apiService: AuthenticationService,
    private departmentsService: DepartmentsService,
    private store: Store,
  ) {}

  private _isAuthorizedUser: ReplaySubject<boolean> = new ReplaySubject(1);

  get isAuthorized() {
    return this._isAuthorizedUser.asObservable();
  }

  // getCurrentUserAuthenticationData$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(currentUserActions.getCurrentUserData),
  //     mergeMap(() =>
  //       this.apiService.getLoggedInUser().pipe(
  //         switchMap((user: ICurrentUser) =>
  //           this.apiService.getAccessObjects().pipe(
  //             map((accessObjects: any) => {
  //               this.store.dispatch(
  //                 currentUserActions.setAccessObjects({ accessObjects }),
  //               );

  //               const currentOrganization = user.organizations.find(
  //                 (org) => org.uid === user.currentOrganizationUID,
  //               );
  //               user.organizationName = currentOrganization.name;

  //               // You can now use 'accessObjects' in your action
  //               this.store.dispatch(
  //                 organizationActions.setDepartmentUID({
  //                   departmentUID: user.currentDepartmentUID,
  //                 }),
  //               );

  //               this.store.dispatch(getDepartmentCountries());

  //               return currentUserActions.getCurrentUserDataSuccess({
  //                 currentUser: user,
  //               });
  //             }),
  //           ),
  //         ),
  //         catchError((error) => {
  //           this.authServiceMsal.logout();
  //           return of(currentUserActions.loginFailure({ error }));
  //         }),
  //       ),
  //     ),
  //   ),
  // );

  getCurrentUserAuthenticationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.getCurrentUserData),
      mergeMap(() => this.getUserDataAndDispatchActions()),
    ),
  );
  private getUserDataAndDispatchActions() {
    return this.apiService.getLoggedInUser().pipe(
      switchMap((user: ICurrentUser) =>
        this.apiService.getAccessObjects().pipe(
          tap((accessObjects: any) => {
            this.store.dispatch(
              currentUserActions.setAccessObjects({ accessObjects }),
            );

            const currentOrganization = user.organizations.find(
              (org) => org.uid === user.currentOrganization.uid,
            );
            user.organizationName = currentOrganization.name;

            this.store.dispatch(
              organizationActions.setDepartmentUID({
                departmentUID: user.currentDepartmentUID,
              }),
            );
            this.store.dispatch(getDepartmentCountries());
            if (user?.roles.some((role) => AdminRolesList.includes(role)))
              this.store.dispatch(getFileImportTypes());
          }),
          map(() =>
            currentUserActions.getCurrentUserDataSuccess({
              currentUser: user,
            }),
          ),
        ),
      ),
      catchError((error) => {
        this.authServiceMsal.logout();
        return of(currentUserActions.loginFailure({ error }));
      }),
    );
  }
  getCurrentUserAuthenticationDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.getCurrentUserDataSuccess),
      tap(() => this._isAuthorizedUser.next(true)), // This will emit a value to the observable for is-loaded Guard
      switchMap(() => of(locationActions.getDepartmentCountries())),
    ),
  );

  getCurrentUserOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.getCurrentOrganizations),
      switchMap((action) =>
        this.departmentsService.getOrganizationList(action.departmentUID).pipe(
          map((organizations) =>
            currentUserActions.getCurrentOrganizationsSuccess({
              organizations,
            }),
          ),
        ),
      ),
    ),
  );

  setCurrentUserOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.setCurrentOrganization),
      switchMap((action) =>
        this.apiService
          .setCurrentOrganization(action.organizationUID, action.departmentUID)
          .pipe(
            map(() => {
              return currentUserActions.getCurrentUserData();
            }),
          ),
      ),
    ),
  );
}
