import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';

import {
  MenuFoldOutline,
  MenuUnfoldOutline,
  FormOutline,
  DashboardOutline,
  CaretRightFill,
  CaretLeftFill,
  CaretRightOutline,
  NotificationFill,
  NotificationOutline,
  BellFill,
  EditFill,
  EditOutline,
  FileExcelOutline
} from '@ant-design/icons-angular/icons';

const icons = [
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  CaretRightFill,
  CaretLeftFill,
  CaretRightOutline,
  NotificationFill,
  NotificationOutline,
  BellFill,
  FileExcelOutline
];

@NgModule({
  imports: [NzIconModule.forRoot(icons)],
  exports: [NzIconModule],
})
export class IconsProviderModule {}
