<div class="ascii-art">
  <pre>
                                                                                                                                                                                                        
`/(`.                                                                                                                                                                                                   
.,`//,.                                                                                                                                                                                             
     .,`/`,.                                                                                                                                                                                        
           ,//`.                                                                                                                                                                                    
               ,//`.                                                                                                                                                                                
                   .`(`.                                                                                                                                                                            
                       .((`.                              .....                                                                                                                                     
                           ,//,                       .//```````//`                                                                                                                                 
                              ,/(`                 .`//`````````````/`.                                                                                                                             
                                 .`/,.           ,/```````````````````//,                                                                                                                           
                                    .`/`        ,/```````````////````````///(`                                                                                                                      
                                       .//,     `/,````````//`````/(/``````,`/.                                                                                                                     
                                          ,(/.  `/`````/////````````,`////``.                                                                                                                       
                                            .`(,,/````//```/```````````/,                                                                                                                           
                                               ,(/````(/``````````````/,                                                                                                                            
                                            ,/////```//````````````````                                                                                                                             
                                           `/````/(((/```````````````/`                                                                                                                             
                                          ,/````````///```````````//,..                                                                                                                             
                                     .`//``/(`````````,/(//`````//,                                                                                                                                 
                               . ./(``````````//`,```````````///`.                                                                                                                                  
                                ,/``````````````/(/````````````//                                                                                                                                   
                               ./``````````````````////```````//                                                                                                                                    
                               //````````````````````//```////#,                                                                                                                                    
                              `/`````````````````````//```````((,                                                                                                                                   
                             ./``````````````````````//```````/((,                                                                                                                                  
                             `/``````````````````````//````````(((,                                                                                                                                 
                            ,/`````````,`/```````````//````````////.                                                                                                                                
                           .```````````/(/```````````//````````/(``/                                                                                                                                
                           ,/`````````/(/```````````//``````````(,./,                                                                                                                               
                          ./`````````/(/````````````//`````````//. `/                                                                                                                               
                          `/````````/(/`````````````/```````````/. .(,                                                                                                                              
                         ,/````````/(``````````````//```````````(,  //                                                                                                                              
                         //````````(```````````````/`````````,``/.  ,(.                                                                                                                             
                        `/````````(/``````````````//```````/(/`//.   (`                                                                                                                             
                       ,/`````````///`````````````/```///```/`/```   `/                                                                                                                             
                       ,/``````````/`/```````````((/````````//,``/,  .(.                                                                                                                            
                        `/``````````/,`/```````````````````//`````/,  /`                                                                                                                            
                         ,/``````````/`////////`````````//(/````````  ,/.                                                                                                                           
                          .//`````````//``````````````//`//```````/`  .(,                                                                                                                           
                            `/`````````/(`````````````//`//`(((((/    .``                                                                                                                           
                             ,/`````````(,`````````````/``//``````/`   ,/.                                                                                                                          
                              .///````////`````````````(/``//```````/(../,                                                                                                                          
                               ,/``````,`/`````````````//````(/````(///```.                                                                                                                         
                                `(```````//````````````//`````//````/   ,/.                                                                                                                         
                               ////``````//`````````````/``````((```/.  .``                                                                                                                         
                               ,/```````///`````````````/``````(,/``(    `/                                                                                                                         
                                 ,,`///`,,``````````````/`````,/,.//,    ./,                                                                                                                        
                                    ,/``````````````````/``````/`         `/                                                                                                                        
                                     ,/`````````````````//``````/         ./,                                                                                                                       
                                      /`````````````````/```````/.         `/                                                                                                                       
......................................`/````````````````(```````/,.........,(` .....                                                                                                                
.......................................(````````````````/```````/`..........`(,................................                                                                                     
                                       `/```````````````/````````/          ./`         .......................................                                                                     
                                       .(```````````````/`````````/           ,(.                                ..............................                                                      
                                       `/```````````````/`````````/            `/.                                                 ........................                                          
                                      `/```````````````//`````````//           ./`                                                             ......................                                
                                     `/````````````````/``````````/            ./,                                                                         ..................                       
                                    `/````````````````/```````````/             ,/.                                                                                 .................               
                                  .``````````````````/```````````/               ,/.                                                                                        ................        
                                  (````````````````//```````````/.                ,(,                                             ,(,                                               ..............  
.................................(````````````````//````````````/.......           .(`                                           ,(,                                                       .........
................................`/````````````````/````````````//...................,//,......                                  `/.                                                              ...
                               ./`````````````````/````````````/,           ..........,/`,................                   .`(.                                                                   
                               ,/````````````````//````````````/.                       ,/`.  ........................     ,//.                                                                     
                               `/````````````````//````````````/                          .`/,.            ............,///,.                                                                       
                               `/````````````````//```````````/`                             .,//`..            .,`/(/`,.............                                                               
                               ,/````````````````/````````````(,                                   .,``/////``,..             ..............                                                        
                             .. .`/`````````````//``````````,`(.                                                                     ............                                                   
...................................`(/``````````//````````````/.............                                                               ..........                                               
............................      .`````////////(//````````///````````````,,.............,,,,,,,..         .....,,,,,,,,...           ........,,,,...........,,,,,,,,,...      ....,,,,,,,,,...     
                                  ./``````````,`////`///```````//,.      .```````,,,,,,......  ..,``,```,,,,....        ..,```````,,,,........ .```````,,,,,.         ,``````,,....          .,``.  
                                  ,/`````````````````/(/```````````//,  .,,  .``.    ........... .`,,`,.                  ,`,,`,               ,`.   ,``,.....       .`,  .``.                  .``,
                                  /```````````````````,`///``````,```````,`````````````````,,,,..,````````````,`````,,.. .,,,,``,,````````,,,..,`````````````````,,,..````,`````````````,,...     ,`
                                  /`/////```,````,,```````/```````````,..,`````````````````,,,,```````````````````,,,,,,````````````````,,,..,,,,,,`````````````,.   .,,`````,``````,,.    ..,``````
                                           .,//``````/(/, ,````,.               .`,   ,`,         .....  ,`,.`,.                  .`,,`,.     ....     .`,.   ,``,,,,,....    ,`.. .``,..,,,,..     
                                                 ...      .,```,,,,..           .,`````,,...       ......,`````,,,..             .,````,...             ,`,,,``,,...          ,`,,``,.              
                                                                 ....,,,,,,,,,,,,,,.    .....,,,,,`````,,..     .....,,,,,,,,,`,,,.     ...,,,,```````````,,..,,,```````````````,,...,,,,```````````
                                                                                                  ....                                                          ....                                
                                                                                                                                                                                                                                                                                                                                                                                                                           
    </pre
  >
</div>
<div class="text-bottom">
  <h1 class="@{text-primary}" i18n>404 - Page Not Found</h1>
  <p class="@{text-primary}">
    <span i18n> Feel free to</span> <a href="/"><span i18n>return to the homepage</span></a> <span i18n>and try again.</span>
  </p>
</div>
