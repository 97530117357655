import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthError,
  AuthenticationResult,
  IdTokenClaims,
  InteractionRequiredAuthError,
  SilentRequest,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import {
  loginFailure,
  getCurrentUserData,
  logout,
  removeLoading,
} from 'src/app/store/current-user/current-user.actions';
import { catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Subject } from '@microsoft/signalr';

@Injectable({ providedIn: 'root' })
export class CurrentUserFlowService {
  constructor(
    private authService: MsalService,
    private store: Store,
  ) {}

  public accessToken$ = new Subject<string>();

  fetchCurrentUserData(): void {
    /*  if (this.shouldBypassUrl()) {
      this.store.dispatch(removeLoading());
      return;
    }
 */

    this.authService.handleRedirectObservable().subscribe({
      next: this.handleNext.bind(this),
      error: this.handleError.bind(this),
    });
  }

  private shouldBypassUrl(): boolean {
    const bypassUrls = ['/register-user']; // add here manually the urls that don't need to be logged in
    const currentUrl = window.location.pathname;

    for (let index = 0; index < bypassUrls.length; index++) {
      const url = bypassUrls[index];

      if (currentUrl.includes(url)) return true;
    }

    return false;
  }

  private handleActiveAccount(accounts: AccountInfo[]): AccountInfo {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount) {
      activeAccount = accounts[0];
      this.authService.instance.setActiveAccount(activeAccount);
    }
    return activeAccount;
  }

  checkExpirationDate(expiration: number | undefined): boolean {
    return expiration ? expiration * 1000 < Date.now() : true;
  }

  isExpiredToken(): boolean {
    const activeAccount = this.authService.instance.getActiveAccount();

    return activeAccount &&
      this.checkExpirationDate(activeAccount.idTokenClaims?.exp)
      ? true
      : false;
  }

  private handleIdTokenClaims(idTokenClaims: IdTokenClaims): void {
    /* const expiry = idTokenClaims ? idTokenClaims.exp : 0;
    const tokenIsExpiry = expiry * 1000 < Date.now(); */
    if (this.isExpiredToken()) {
      this.logout();
    } else {
      if (idTokenClaims && 'dbUserUID' in idTokenClaims) {
        this.store.dispatch(getCurrentUserData());
      } else {
        console.error('Token does not have a dbUserUID property');

        this.logout();
      }
    }
  }

  private handleNext(result: AuthenticationResult): void {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const activeAccount = this.handleActiveAccount(accounts);
      this.handleIdTokenClaims(activeAccount.idTokenClaims);
      this.getSignalAccessToken();
    } else {
      console.error('No accounts detected');
      this.store.dispatch(loginFailure({ error: 'No accounts detected' }));

      if (this.shouldBypassUrl()) {
        this.store.dispatch(removeLoading());
      } else {
        this.authService.loginRedirect({
          redirectUri: environment.redirectUri,
          scopes: environment.ApiScopes,
        });
      }
    }
  }

  // Define the accessToken$ subject

  public getSignalAccessToken(): void {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const activeAccount = this.handleActiveAccount(accounts);

      const accessTokenRequest: SilentRequest = {
        scopes: environment.ApiScopes,
        account: activeAccount,
      };

      this.authService
        .acquireTokenSilent(accessTokenRequest)
        .pipe(
          map((accessTokenResponse: AuthenticationResult) => {
            return accessTokenResponse.accessToken;
          }),
          catchError((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return this.authService.acquireTokenRedirect(accessTokenRequest);
            }
            console.log('error :', error);
            return error;
          }),
        )
        .subscribe((accessToken: string) => {
          // Emit the accessToken to all subscribers
          this.accessToken$.next(accessToken);
        });
    }
  }

  private handleError(error: AuthError): void {
    switch (error.errorCode) {
      case 'user_cancelled':
        console.error('User cancelled the authentication flow.');
        break;
      case 'login_required':
        console.error('Login is required.');
        break;
      case 'consent_required':
        console.error('User consent is required.');
        break;
      case 'interaction_required':
        console.error('Interaction is required.');
        break;
      case 'token_renewal_error':
        console.error('Token renewal error.');
        break;
      case 'invalid_client':
        console.error('Invalid client.');
        break;
      case 'invalid_grant':
        console.error('Invalid grant.');
        break;
      case 'unauthorized_client':
        console.error('Unauthorized client.');
        break;
      case 'unsupported_grant_type':
        console.error('Unsupported grant type.');
        break;
      case 'server_error':
        console.error('Server error.');
        break;
      case 'temporarily_unavailable':
        console.error('Server temporarily unavailable.');
        break;
      default:
        console.error('An unknown error occurred: ', error);
      /*  const errorMessage = error.errorMessage;

        if (errorMessage && errorMessage.indexOf('AADB2C90091') > -1) {
          this.authService.loginRedirect({
            redirectUri: environment.redirectUri,
            scopes: environment.ApiScopes,
          });
        } */
    }
    this.loginRedirect();
  }
  logout(): void {
    //account: null,
    //const msalConfig = { auth: { clientId: 'your_client_id', authority: 'https://login.microsoftonline.com/your_tenant_id', redirectUri: 'http://localhost:3000', },
    // Perform logout operation msalInstance.logoutRedirect({ postLogoutRedirectUri: window.location.href, account: null, }).then(() => { // Logout successful }).catch((error) => { // Logout failed });

    this.store.dispatch(logout());
    this.authService.logoutRedirect();
  }

  loginRedirect(): void {
    this.authService.loginRedirect({
      redirectUri: environment.redirectUri,
      scopes: environment.ApiScopes,
    });
  }
}
