import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ReportConfigStatus } from '@features/reports/store/reports.interface';

@Component({
  selector: 'xpw-report-config-status',
  template: `
    @if (statusId === ReportConfigStatus.Active) {
      <xpw-icon icon="active-report-config" />
    } @else if (statusId === ReportConfigStatus.Suspended) {
      <xpw-icon icon="suspended" />
    } @else if (statusId === ReportConfigStatus.Deleted) {
      <xpw-icon icon="deleted" />
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpwReportConfigStatusComponent implements OnChanges {
  @Input() statusId: ReportConfigStatus = null;
  ReportConfigStatus = ReportConfigStatus;
  stringTitle: string = '';
  icon: string | null = null;
  color: string = 'default';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['statusId']) {
      this.getData();
    }
  }
  getData() {
    // switch (this.statusId) {
    //   case ReportConfigStatus.Active:
    //     this.stringTitle = $localize`Active`;
    //     this.color = 'green';
    //     this.icon = 'upload';
    //     break;
    //   case ReportConfigStatus.Suspended:
    //     this.stringTitle = $localize`Suspended`;
    //     this.color = 'red';
    //     this.icon = 'pause';
    //     break;
    //   case ReportConfigStatus.Deleted:
    //     this.stringTitle = $localize`Deleted`;
    //     this.color = 'red';
    //     this.icon = 'trash';
    //     break;
    //   default:
    //     this.stringTitle = $localize`Unknown`;
    //     break;
    // }
  }
}
