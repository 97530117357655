import { NgModule } from '@angular/core';

import { BreadcrumbsComponent } from './breadcrumbs.component';
import { IconsModule } from '@shared/xpw-ui-kit/xpw-icons/xpw-icons.module';

@NgModule({
    imports: [IconsModule],
    exports: [BreadcrumbsComponent],
    declarations: [BreadcrumbsComponent],

})
export class BreadCrumbsModule { }
